import React, { useState } from 'react';
import { Map as GoogleMapReact, InfoWindow, Marker } from 'google-maps-react';

import { GOOGLE_MAP_API_KEY } from '../../../constants';
import CarIcon from '../../../assets/icons/Leg.svg';
import moment from 'moment';

const VehicleLocation = ({ vehicle, vehicleLocation }) => {
  const [place, setPlace] = useState({});
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const onMarkerClick = (props, marker) => {
    setPlace(marker);
    setShowInfoWindow(true);
  };

  const onCloseInfoWindow = () => {
    setShowInfoWindow(false);
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        google={window.google}
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
        defaultCenter={{
          lat: vehicle?.VehicleLocations?.[0]?.latitude,
          lng: vehicle?.VehicleLocations?.[0]?.longitude
        }}
        center={{
          lat: vehicle?.VehicleLocations?.[0]?.latitude,
          lng: vehicle?.VehicleLocations?.[0]?.longitude
        }}
        defaultZoom={14}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        scrollwheel={false}
        mapId={'f821a93ab9a91eb9'}
      >
        <Marker
          position={{
            lat: vehicle?.VehicleLocations?.[0]?.latitude,
            lng: vehicle?.VehicleLocations?.[0]?.longitude
          }}
          icon={{
            url: CarIcon
          }}
          onClick={onMarkerClick}
        />
        <InfoWindow
          marker={place}
          visible={showInfoWindow}
          onClose={onCloseInfoWindow}
          google={window.google}
        >
          <div className="info-window">
            <div className="info-header">{vehicle?.registrationNumber}</div>
            <p className="info-item">
              <span className="info-title">Address:</span>
              <span className="info-text">{vehicleLocation}</span>
            </p>
            <p className="info-item">
              <span className="info-title">Time:</span>
              <span className="info-text">
                {moment(vehicle?.VehicleLocations?.[0]?.updatedAt).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}
              </span>
            </p>
            <p className="info-item">
              <span className="info-title">Position:</span>
              <span className="info-latlng">
                <span className="info-text">
                  {vehicle?.VehicleLocations?.[0]?.longitude}
                </span>
                <span className="info-text">
                  {vehicle?.VehicleLocations?.[0]?.latitude}
                </span>
              </span>
            </p>
            <p className="info-item">
              <span className="info-title">Speed:</span>
              <span className="info-text">
                {vehicle?.VehicleLocations?.[0]?.speed}
              </span>
            </p>
            <p className="info-item">
              <span className="info-title">Status:</span>
              <span className="info-text">
                {vehicle?.VehicleLocations?.[0]?.speed > 0 ? 'Moving' : 'Stop'}
              </span>
            </p>
            <p className="info-item">
              <span className="info-title">IGNITION:</span>
              <span className="info-text">
                {vehicle?.VehicleLocations?.[0]?.ignition ?? '-'}
              </span>
            </p>
            {/* <p className="info-item">
              <span className="info-title">GSM SIGNAL:</span>
              <span className="info-text">
                {vehicle?.VehicleLocations?.[0]?.gsm ?? '-'}
              </span>
            </p>
            <p className="info-item">
              <span className="info-title">MAIN POWER:</span>
              <span className="info-text">
                {vehicle?.VehicleLocations?.[0]?.power ?? '-'}
              </span>
            </p> */}
          </div>
        </InfoWindow>
      </GoogleMapReact>
    </div>
  );
};

export default VehicleLocation;
