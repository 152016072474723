import * as Yup from 'yup';

export const organizationDetailsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Organization name is required'),
  subdomain: Yup.string().required('Organization subdomain is required'),
  address: Yup.string().required('Organization address is required'),
  industry: Yup.string().required('Organization industry is required'),
  email: Yup.string().required('Organization email is required'),
  primaryColor: Yup.string().nullable(),
  secondaryColor: Yup.string().nullable(),
  type: Yup.string().required('Organization Type is required'),
  defaultCurrency: Yup.string().required('Default curreny is required')
});

const passwordRE =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

export const ownerDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().when('edit', {
    is: false,
    then: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        passwordRE,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@, !, ?, $, *, %, &)'
      ),
    otherwise: Yup.string()
      .nullable()
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        passwordRE,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@, !, ?, $, *, %, &)'
      )
  })
});

export const organizationBankDetailsValidationSchema = Yup.object().shape({
  ntn: Yup.string().nullable(),
  bankName: Yup.string().nullable(),
  bankBranch: Yup.string().nullable(),
  accountTitle: Yup.string().nullable(),
  accountNumber: Yup.string().nullable(),
  iban: Yup.string().nullable(),
  swiftCode: Yup.string().nullable()
});

export const organizationIntegrationsValidationSchema = Yup.object().shape({
  injazIntegration: Yup.boolean(),
  injazEmail: Yup.string().when('injazIntegration', {
    is: true,
    then: Yup.string()
      .required('Injaz email is required')
      .email('Invalid email format'),
    otherwise: Yup.string().nullable()
  }),
  injazPassword: Yup.string().when('injazIntegration', {
    is: true,
    then: Yup.string()
      .required('Injaz password is required')
      .min(6, 'Password must be at least 6 characters'),
    otherwise: Yup.string().nullable()
  })
});
