/*eslint-disable*/

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Label } from '../../../core-components/atoms/Text';
import { ShipmentTableRow } from './ShipmentTableRow';

export const ShipmentsTable = ({
  shipments,
  activeShipment,
  setActiveShipment,
  setActiveShipmentOrder,
  shipmentIdFilter,
  setCancelPopUp,
  setActiveShipmentPopup,
  refetch,
  setView,
  setAddShipmentPopup,
  setShipmentDetailView,
  extraColumns,
  filters
}) => {
  return (
    <TableContainer
      elevation={0}
      sx={{
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #E7E6E6 !important',
        '& .MuiTable-root': {
          borderCollapse: 'unset'
        }
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '15%' }}>
              <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                Shipment ID
              </Label>
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
              <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                Origin & Destination
              </Label>
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
              <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                Shipment Type
              </Label>
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
              <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                Shipment Status
              </Label>
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
              <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                Shipment Carrier
              </Label>
            </TableCell>
            {extraColumns?.indexOf('Shipment Mode') !== -1 && (
              <TableCell sx={{ width: '10%' }}>
                <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                  Shipment Mode
                </Label>
              </TableCell>
            )}
            {extraColumns?.indexOf('Vehicle & Driver') !== -1 && (
              <TableCell sx={{ width: '15%' }}>
                <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                  Vehicle & Driver
                </Label>
              </TableCell>
            )}
            {extraColumns?.indexOf('PoD & Billing Status') !== -1 && (
              <TableCell sx={{ width: '20%' }}>
                <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                  PoD & Billing Status
                </Label>
              </TableCell>
            )}
            {extraColumns?.indexOf('Creation Date & Time') !== -1 && (
              <TableCell sx={{ width: '20%' }}>
                <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
                  Creation Date & Time
                </Label>
              </TableCell>
            )}
            <TableCell sx={{ width: '10%' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipments.map((shipment) => (
            <ShipmentTableRow
              shipment={shipment}
              order={shipment.Order}
              setCancelPopUp={setCancelPopUp}
              activeShipment={activeShipment}
              setActiveShipment={setActiveShipment}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setOptionsClick={() => {}}
              setView={setView}
              setAddShipmentPopup={setAddShipmentPopup}
              setShipmentDetailView={setShipmentDetailView}
              refetch={refetch}
              extraColumns={extraColumns}
              filters={filters}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
