/*eslint-disable*/
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import NewSearchBar from '../../../atomicComponents/NewSearchBar';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import moment from 'moment';
import { SharedContext } from '../../../utils/common';
import { toaster } from '../../../utils/toaster';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import { checkPermission, isOwareSuperAdmin } from '../../../utils/auth';

const OrderFiles = ({ order, files, setFiles, firstLoad, setFirstLoad }) => {
  const tableCellClass = 'py-2 h-12 pl-0 pr-2 text-xs';
  const tableContainerRef = useRef(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [fileSearch, setFileSearch] = useState([]);
  const [, setContentHover] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const [allFilesSelected, setAllFilesSelected] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesMetaDataLoading] = useState();
  const [, setHoveredRow] = useState(-1);
  const [isConfirm, setIsConfirm] = useState(false);

  const { setAPILoader, currentUser } = useContext(SharedContext);

  useEffect(() => {
    if (order?.id && files?.length <= 0 && firstLoad) {
      setPage(1);
      setFilesLoading(true);
      getDelayedFiles(1, [], order, fileSearch);
      setFirstLoad(false);
    }
    if (files.length > 0) {
      setFilesLoading(false);
    }
  }, [files, order, fileSearch, firstLoad]);

  const getDelayedFiles = useCallback(
    debounce(
      async (page, oldData, orderDetail, search) => {
        getUserFiles(page, oldData, orderDetail, search);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserFiles = async (page, oldData = [], orderDetail, search) => {
    try {
      const res = await API.get(`files`, {
        params: {
          page: page,
          limit: 20,
          search: search,
          columns: ['originalName'],
          shipmentIds: order?.Shipments?.map((s) => s.id),
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {})
        }
      });
      const newArr = res?.data;
      setFiles([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setFilesLoading(false);
    } catch (error) {
      onError(error);
      setFilesLoading(false);
    }
  };

  const [columnNames] = useState([
    'DOCUMENT NAME',
    'DOCUMENT TYPE',
    'SHIPMENT NUMBER',
    'REF ID',
    'DATE UPLOADED',
    'UPLOADED BY'
  ]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !filesLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
      getDelayedFiles(page + 1, files, order, fileSearch);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedFiles?.indexOf(id) !== -1) {
      setSelectedFiles(selectedFiles?.filter((f) => f !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  function bytesToSize(bytes) {
    const mb = bytes / (1024 * 1024);
    const gb = bytes / (1024 * 1024 * 1024);

    if (gb >= 1) {
      return gb.toFixed(2) + ' GB';
    } else {
      return mb.toFixed(2) + ' MB';
    }
  }

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  const deleteFiles = async () => {
    try {
      setAPILoader(true);
      await API.post(`files/bulk_delete`, {
        file_ids: selectedFiles
      });
      setSelectedFiles([]);
      await getDelayedFiles(1, [], order, fileSearch);
      toaster('success', 'Documents deleted successfully');
      setAllFilesSelected(false);
    } catch (e) {
      console.log(e);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (files?.length > 0) {
      if (selectedFiles?.length > 0 && fileSearch) {
        const validSelectedFiles = selectedFiles.filter((selectedFile) =>
          files.some((file) => file.id === selectedFile)
        );
        setSelectedFiles(validSelectedFiles);
      }
    } else {
      setSelectedFiles([]);
    }
  }, [files, fileSearch]);

  return (
    <div>
      <Popup
        open={isConfirm}
        setOpen={setIsConfirm}
        title={'Are you sure?'}
        content={
          <div>
            <Paragraph variant={'lg'}>
              By doing this you will be deleting{' '}
              {allFilesSelected ? files?.length : selectedFiles?.length}{' '}
              {allFilesSelected
                ? files?.length > 1
                  ? 'documents '
                  : 'document '
                : selectedFiles?.length > 1
                ? 'documents '
                : 'document '}
              from the attached documents permanently. Are you sure you want to
              continue ?
            </Paragraph>
          </div>
        }
        onClose={() => {
          setIsConfirm(false);
        }}
        dialogContentClasses="w-[40vw]"
        actions={
          <div className="flex gap-2">
            <CustomButton
              label="Cancel"
              className="py-2 px-3 ml-2 h-8 border-solid border "
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                setIsConfirm(false);
              }}
              variant="tertiary"
            />
            <CustomButton
              className="py-2 px-3 ml-1 h-8 border-solid border border-persianRed text-persianRed hover:bg-persianRed"
              overrideSize={true}
              labelClass="font-medium text-xs text-persianRed hover:text-white"
              label={'Yes, Proceed'}
              variant={'transparent'}
              onClick={async () => {
                await deleteFiles();
                setIsConfirm(false);
              }}
            />
          </div>
        }
      />
      <div className={'flex justify-between items-center'}>
        <Title variant={'md'}>Attached Documents</Title>
        <NewSearchBar
          showFilter={false}
          filter={false}
          setFilterView={() => {}}
          setSearchValue={(e) => {
            setFileSearch(e);
            getDelayedFiles(1, [], order, e);
          }}
        />
      </div>
      <div className="mb-5">
        <Paper className="mt-6 no-scrollbar rounded-lg border border-solid border-dark300">
          <TableContainer
            onMouseEnter={() => {
              setContentHover(true);
            }}
            onMouseLeave={() => {
              setContentHover(false);
            }}
            style={{ overflow: 'auto' }}
            ref={tableContainerRef}
            onScroll={handleTableScroll}
            className="no-scrollbar inventory-table rounded-lg"
          >
            <Table stickyHeader className="z-0">
              <TableHead className="relative z-0">
                <TableRow>
                  <TableCell
                    key={'checkbox'}
                    className="bg-white text-dark300 text-base py-2 px-6"
                  >
                    {(isOwareSuperAdmin(currentUser) ||
                      checkPermission(currentUser, 'OPS_UPLOAD_DELETE')) &&
                      (filesMetaDataLoading ? (
                        <CircularProgress size={16} />
                      ) : (
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 16
                            },
                            '& .Mui-checked.MuiSvgIcon-root': {
                              color: '#0C6BD7 !important'
                            }
                          }}
                          checked={allFilesSelected}
                          onChange={() => {
                            setAllFilesSelected(!allFilesSelected);
                          }}
                        />
                      ))}
                  </TableCell>
                  {columnNames.map((columnName, index) => (
                    <TableCell
                      key={index}
                      className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-medium h-[40px]"
                    >
                      {columnName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((row, index) => {
                  const fileType = getFileExtension(row?.originalName);
                  return (
                    <TableRow
                      key={index}
                      onMouseEnter={() => {
                        setHoveredRow(index);
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(-1);
                      }}
                      className={clsx(
                        'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                      )}
                      onClick={() => {
                        if (
                          !allFilesSelected &&
                          (isOwareSuperAdmin(currentUser) ||
                            checkPermission(currentUser, 'OPS_UPLOAD_DELETE'))
                        ) {
                          handleCheckboxChange(row?.id, row);
                        }
                      }}
                    >
                      <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                        {(isOwareSuperAdmin(currentUser) ||
                          checkPermission(
                            currentUser,
                            'OPS_UPLOAD_DELETE'
                          )) && (
                          <Checkbox
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 16
                              },
                              '& .Mui-checked.MuiSvgIcon-root': {
                                color: '#0C6BD7 !important'
                              }
                            }}
                            checked={
                              selectedFiles?.includes(row?.id) ||
                              allFilesSelected
                            }
                            onChange={(e, checked) => {
                              if (!allFilesSelected) {
                                handleCheckboxChange(row?.id, row, checked);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell className={`${tableCellClass}`}>
                        <div className={'flex'}>
                          <div
                            onClick={() => {
                              openFileInNewTab(row?.location);
                            }}
                            className={clsx(
                              'flex items-center justify-center rounded h-[30px] w-[30px]',
                              fileType === 'pdf'
                                ? 'bg-[#CE375C]'
                                : fileType === 'csv' ||
                                  fileType === 'xlsx' ||
                                  fileType === 'xls'
                                ? 'bg-[#4DA954]'
                                : fileType === 'doc' ||
                                  fileType === 'docx' ||
                                  fileType === 'txt'
                                ? 'bg-[#5187ED]'
                                : 'bg-dusk500'
                            )}
                          >
                            <Label variant={'xs'} className={'text-white'}>
                              {fileType}
                            </Label>
                          </div>
                          <div className={'ml-2 overflow-hidden w-48'}>
                            <Paragraph variant={'sm'} className={'truncate'}>
                              {row?.originalName}
                            </Paragraph>
                            <Label
                              variant={'xs'}
                              className={'truncate text-dark500'}
                            >
                              {bytesToSize(row?.size)}
                            </Label>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} text-dark600 capitalize`}
                      >
                        {row?.sourceType === 'grn'
                          ? `${row?.sourceType?.toUpperCase()} - ${
                              row?.sourceId
                            }`
                          : row?.sourceType === 'gdn'
                          ? `${row?.sourceType?.toUpperCase()} - ${
                              row?.sourceId
                            }`
                          : row?.sourceType === 'shipment'
                          ? row?.type || 'PoD'
                          : row?.sourceType}
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} text-dark600 capitalize`}
                      >
                        {row?.Shipment?.customId || '-'}
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} text-dark600 capitalize`}
                      >
                        {row?.type == "Waseeqa" ? row?.Shipment?.waseeqaNumber : row?.Shipment?.referenceId || '-'}
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} text-dark600 capitalize`}
                      >
                        {moment(row?.updatedAt).format('DD/MM/YY')}
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} text-dark600 capitalize`}
                      >
                        {(row?.createdBy?.firstName || '-') +
                          ' ' +
                          (row?.createdBy?.lastName || '-')}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {page < pageCount && (
                  <TableRow>
                    <TableCell className="py-2 h-12" colSpan={12}>
                      <div className="flex w-full justify-center">
                        <CircularProgress size="14px" />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {files?.length === 0 && !filesLoading && (
                  <TableRow>
                    <TableCell className="py-2 h-12" colSpan={12}>
                      <div className="flex w-full justify-center">
                        <p>No data found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={'flex border p-4 h-14 items-center justify-between'}>
            <div>
              {files.length > 0 &&
                (selectedFiles?.length > 0 || allFilesSelected) && (
                  <CustomButton
                    className="py-1 px-1 ml-1 h-8 border-solid border border-persianRed text-persianRed hover:bg-persianRed"
                    overrideSize={true}
                    labelClass="font-medium text-xs text-persianRed hover:text-white"
                    label={`Delete ${
                      allFilesSelected ? 'All' : selectedFiles?.length
                    }`}
                    variant={'transparent'}
                    onClick={() => {
                      setIsConfirm(true);
                    }}
                  />
                )}
            </div>
            <div className="flex gap-2 items-center">
              <Label variant={'xs'} className={'text-dark500 mr-1'}>
                Total Documents:
              </Label>
              <Title variant={'sm'}>{files.length}</Title>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default OrderFiles;
