/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Modal } from '@mui/material';
import { ChevronLeft, CloseOutlined } from '@material-ui/icons';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import API from '../../libs/axios';
import LegMap from './LegMap';
import RightArrowIcon from '../../assets/icons/rightArrow.svg';
import { InBoundShipmentTracking } from './components/shipmentDetailTracking/InboundShipmentTracking';
import { OutboundShipmentTracking } from './components/shipmentDetailTracking/OutboundShipmentTracking';
import { TransferShipmentTracking } from './components/shipmentDetailTracking/TransferTMSTracking';
import { LogisticShipmentTracking } from './components/shipmentDetailTracking/LogisticShipmentTracking';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import { Tab, Tabs } from '@material-ui/core';
import { Title } from '../../core-components/atoms/Text';
import Conversation from '../multiModalShipment/comments/Conversation';
import ShipmentOrderDetail from './components/shipmentOrderDetail';
import ShipmentTimeline from './components/shipmentTimeline';
import {
  calculateETA,
  calculateEuclideanDistance,
  SharedContext
} from '../../utils/common';
import moment from 'moment';
import Geocode from 'react-geocode';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import { getSchedules } from './services';
import PrintDocument from './PrintDocument';
import { decimalDisplay } from '../../utils/common';

const ShipmentDetail = ({
  open,
  selectedShipmentMode,
  order,
  setOpen,
  shipmentId,
  refetch
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [map, setMap] = useState();
  const [value, setValue] = useState(0);
  const [vehicleLocation, setVehicleLocation] = useState('');
  const [waseeqaPopup, setWaseeqaPopup] = useState(false);
  const [distance, setDistance] = useState(0);
  const [podOpen, setPodOpen] = useState(false);
  const [pickupLatLng, setPickupLatLng] = useState({
    lat: 0,
    lng: 0
  });
  const [logs, setLogs] = useState([]);
  const tabSelectedClass =
    'border-b-[4px] border-solid border-t-0 border-r-0 border-l-0 border-primary text-primary';
  const tabClass = 'font-semibold text-sm normal-case';
  const [shipment, setShipment] = useState();
  const { setAPILoader, settings } = useContext(SharedContext);
  const [openExpense, setOpenExpense] = useState(false);
  const [refetchExpense, setRefetchExpense] = useState(false);

  const getLogs = async (shipmentId) => {
    const { logs } = await API.get(
      `order-shipment-logs/${order.customId}?shipmentId=${shipmentId}`
    );
    setLogs(logs);
  };

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <Box className="w-100 flex justify-center items-center">
            <CircularProgress />
          </Box>
        );
      case Status.FAILURE:
        return <div>Error!</div>;
      case Status.SUCCESS:
        return (
          <LegMap
            originAddress={vehicleLocation}
            destinationAddress={order?.dropOffNode?.address}
            origin={
              shipment?.ShipmentLegs?.[0]?.Vehicle?.VehicleLocations?.length > 0
                ? {
                    lat: shipment?.ShipmentLegs?.[0]?.Vehicle
                      ?.VehicleLocations?.[0]?.latitude,
                    lng: shipment?.ShipmentLegs?.[0]?.Vehicle
                      ?.VehicleLocations?.[0]?.longitude
                  }
                : shipment.shipmentTracking?.shipmentId
                ? {
                    lat: Number(shipment.shipmentTracking?.latitude),
                    lng: Number(shipment.shipmentTracking?.longitude)
                  }
                : order?.pickupNode?.locationLatLng
            }
            destination={order?.dropOffNode?.locationLatLng}
            open={open}
            map={map}
            setMap={setMap}
            selectedShipmentMode={selectedShipmentMode}
            isTrackerPickup={
              shipment?.ShipmentLegs?.[0]?.Vehicle?.VehicleLocations?.length > 0
            }
            infoWindow={
              vehicleLocation
                ? `<div class="info-window">
                <div class="info-header">
                  ${shipment?.ShipmentLegs?.[0]?.Vehicle?.registrationNumber}
                </div>
                <p class="info-item">
                  <span class="info-title">Address:</span>
                  <span class="info-text">${vehicleLocation}</span>
                </p>
                <p class="info-item">
                  <span class="info-title">Time:</span>
                  <span class="info-text">${moment(
                    shipment?.ShipmentLegs?.[0]?.Vehicle?.VehicleLocations?.[0]
                      ?.updatedAt
                  ).format('YYYY-MM-DD HH:mm:ss')}</span>
                </p>
                <p class="info-item">
                  <span class="info-title">Position:</span>
                  <span class="info-latlng">
                    <span class="info-text">${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.longitude
                    }</span>
                    <span class="info-text">${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.latitude
                    }</span>
                  </span>
                </p>
                <p class="info-item">
                  <span class="info-title">Speed:</span>
                  <span class="info-text">
                    ${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.speed
                    }
                  </span>
                </p>
                <p class="info-item">
                  <span class="info-title">Status:</span>
                  <span class="info-text">
                    ${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.speed > 0
                        ? 'Moving'
                        : 'Stop'
                    }
                  </span>
                </p>
                <p class="info-item">
                  <span class="info-title">IGNITION:</span>
                  <span class="info-text">
                    ${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.ignition || '-'
                    }
                  </span>
                </p>
                <p class="info-item">
                  <span class="info-title">GSM SIGNAL:</span>
                  <span class="info-text">
                    ${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.gsm || '-'
                    }
                  </span>
                </p>
                <p class="info-item">
                  <span class="info-title">MAIN POWER:</span>
                  <span class="info-text">
                    ${
                      shipment?.ShipmentLegs?.[0]?.Vehicle
                        ?.VehicleLocations?.[0]?.power || '-'
                    }
                  </span>
                </p>
              </div>`
                : ''
            }
          />
        );
    }
    return <></>;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getShipment = async () => {
    try {
      setAPILoader(true);
      const shipment = await API.get(
        `orders/${order.customId}/shipments/${shipmentId}`
      );
      setShipment(shipment.data);
    } catch (e) {
      console.log(e);
    } finally {
      setAPILoader(false);
    }
  };

  const expectedDeliveryDate = moment(shipment?.dropoffDateTime);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference = moment.duration(timeDifference).asDays().toFixed(0);

  useEffect(() => {
    if (shipmentId) {
      getShipment(shipmentId);
      getLogs(shipmentId);
    }
  }, [shipmentId]);

  const calculateDistance = async () => {
    if (pickupLatLng && order?.dropOffNode?.locationLatLng) {
      const distance = await calculateEuclideanDistance(
        pickupLatLng,
        order?.dropOffNode?.locationLatLng
      );
      setDistance(distance);
    } else {
      setDistance(0);
    }
  };

  useEffect(() => {
    calculateDistance();
  }, [order, pickupLatLng]);

  useEffect(() => {
    if (shipment) {
      let vehicleLocation =
        shipment?.ShipmentLegs?.[0]?.Vehicle?.VehicleLocations?.[0];
      if (vehicleLocation) {
        Geocode.fromLatLng(vehicleLocation.latitude, vehicleLocation.longitude)
          .then((addresses) => addresses.results[0].formatted_address)
          .then((result) => {
            setVehicleLocation(result);
            setPickupLatLng({
              lat: vehicleLocation.latitude,
              lng: vehicleLocation.longitude
            });
          });
      } else {
        setVehicleLocation(order?.pickupNode?.address);
        setPickupLatLng(order?.pickupNode?.locationLatLng);
      }

      if (shipment.shipmentTracking?.shipmentId) {
        const tracking = shipment.shipmentTracking;
        console.log(tracking);
        Geocode.fromLatLng(tracking.latitude, tracking.longitude)
          .then((addresses) => addresses.results[0].formatted_address)
          .then((result) => {
            setVehicleLocation(result);
            setPickupLatLng({
              lat: Number(tracking.latitude),
              lng: Number(tracking.longitude)
            });
          });
      }
    }
  }, [shipment]);

  return shipment ? (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open={open}
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <FixedLayout
          showFooter={false}
          header={
            <div className="flex justify-between">
              <div className="flex gap-3 items-center ml-4">
                <p className="flex items-center font-bold">
                  <ChevronLeft
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="cursor-pointer mr-3"
                  />
                  {shipment?.customId}
                </p>
                <div className="text-sm font-normal flex gap-2 items-center border-[#AEC1D6] border-0 border-l-2 border-solid px-2"></div>
              </div>
              <div>
                <CloseOutlined
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="cursor-pointer text-[20px] text-dark400"
                />
              </div>
            </div>
          }
          content={
            <div className="flex">
              <div className="w-[75%]">
                <div className="flex justify-between mt-2">
                  <div className="flex flex-row gap-1.5 items-center p-2 ml-4">
                    <p className="text-sm font-medium">
                      {order.pickupNode?.name
                        ? order.pickupNode?.name?.length > 20
                          ? order.pickupNode?.name?.substring(0, 20) + '...'
                          : order.pickupNode?.name
                        : '-'}
                    </p>
                    {order.dropOffNode?.name && order.dropOffNode?.name && (
                      <img
                        src={RightArrowIcon}
                        alt="arrow"
                        className="flex-shrink-0 w-[20px]"
                      />
                    )}
                    <p className="text-sm font-medium">
                      {order.dropOffNode?.name
                        ? order.dropOffNode?.name?.length > 20
                          ? order.dropOffNode?.name?.substring(0, 20) + '...'
                          : order.dropOffNode?.name
                        : '-'}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex justify-center items-center p-2 border-r gap-2">
                      <span className="text-xs font-medium">
                        {isFeatureEnabled(FLAGS.TMS) ||
                        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                          ? `${calculateETA(
                              distance,
                              shipment?.ShipmentLegs?.[0]?.Vehicle?.avgSpeed,
                              order?.expectedDelivery,
                              shipment?.status?.key
                            )}`
                          : `${
                              calculateTimeDifference(
                                shipment?.dropoffDateTime,
                                shipment?.status?.key
                              ).hours
                            }h ${
                              calculateTimeDifference(
                                shipment?.dropoffDateTime,
                                shipment?.status?.key
                              ).minutes
                            }min`}
                      </span>
                      <span className="text-xs text-dark500">ETA</span>
                    </div>
                    <div className="flex justify-center items-center p-2 gap-2">
                      <span className="text-xs font-medium">
                        {decimalDisplay(distance)}{' '}
                        {settings?.distance?.symbol || 'Km'}
                      </span>
                      <span className="text-xs text-dark500">DISTANCE</span>
                    </div>

                    <div className="flex justify-center items-center p-2 gap-2">
                      <PrintDocument
                        className="ml-10"
                        orderId={order.customId}
                        shipment={shipment}
                        leg={shipment?.ShipmentLegs?.[0]}
                        isIcon={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex h-[400px] mt-[6px] rounded overflow-hidden">
                  <Wrapper
                    apiKey={GOOGLE_MAP_API_KEY}
                    version="beta"
                    libraries={['marker', 'geometry', 'routes']}
                    render={render}
                  />
                </div>
                {!openExpense && !waseeqaPopup && !podOpen && (
                  <div className="justify-center flex w-full z-100">
                    <div className="p-4 mt-[-40px] z-[10000] bg-white">
                      <div className="flex gap-1 items-center z-[10000]">
                        <p className="text-xs">SHIPMENT DETAILS</p>
                        <div className="border-b border-dashed h-[4px] min-w-[700px]"></div>
                      </div>
                      <div>
                        {order.moveType == 'INWARD' ? (
                          <InBoundShipmentTracking
                            order={order}
                            refetch={() => {
                              getShipment(shipmentId);
                              setRefetchExpense(true);
                            }}
                            shipment={shipment}
                          />
                        ) : order.moveType == 'OUTBOUND' ? (
                          <OutboundShipmentTracking
                            order={order}
                            refetch={() => {
                              getShipment(shipmentId);
                              setRefetchExpense(true);
                            }}
                            shipment={shipment}
                          />
                        ) : order.moveType == 'TRANSFER' ? (
                          <TransferShipmentTracking
                            order={order}
                            refetch={() => {
                              getShipment(shipmentId);
                              setRefetchExpense(true);
                            }}
                            shipment={shipment}
                          />
                        ) : (
                          <LogisticShipmentTracking
                            shipment={shipment}
                            order={order}
                            refetch={() => {
                              getShipment(shipmentId);
                              setRefetchExpense(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={'w-[25%] h-100 border-l'}>
                <div className="flex items-center justify-between border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#C1D6EC]">
                  <div>
                    <Tabs value={value} onChange={handleChange}>
                      {isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && (
                        <Tab
                          label={<Title variant={'sm'}>Conversation</Title>}
                          className={`${tabClass} && ${
                            value == 0 && tabSelectedClass
                          } min-w-fit p-4`}
                          active={value == 0}
                          value={0}
                        />
                      )}
                      <Tab
                        label={<Title variant={'sm'}>Details</Title>}
                        className={`${tabClass} && ${
                          value == 1 && tabSelectedClass
                        } min-w-fit p-4`}
                        active={value == 1}
                        value={1}
                      />
                      {/* <Tab
                        label={<Title variant={'sm'}>Timeline</Title>}
                        className={`${tabClass} && ${
                          value == 2 && tabSelectedClass
                        } min-w-fit p-4`}
                        active={value == 2}
                        value={2}
                      /> */}
                    </Tabs>
                  </div>
                </div>
                <div>
                  {value === 0 &&
                    isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && (
                      <Conversation
                        order={order}
                        shipment={shipment}
                        className={'h-[750px] max-h-[750px]'}
                        inputClassName={'mb-3 mx-3'}
                        customClassText={'w-[342px]'}
                        customClassEmpty={'w-[342px] h-[600px]'}
                        resetOrderFileListing={() => {}}
                        openExpense={openExpense}
                        setOpenExpense={setOpenExpense}
                      />
                    )}
                  {value === 1 && (
                    <ShipmentOrderDetail
                      order={order}
                      shipment={shipment}
                      openExpense={openExpense}
                      setOpenExpense={setOpenExpense}
                      refetch={() => {
                        getShipment(shipmentId);
                      }}
                      refetchExpense={refetchExpense}
                      setRefetchExpense={setRefetchExpense}
                      waseeqaPopup={waseeqaPopup}
                      setWaseeqaPopup={setWaseeqaPopup}
                      podOpen={podOpen}
                      setPodOpen={setPodOpen}
                    />
                  )}
                  {value === 2 && (
                    <ShipmentTimeline order={order} shipment={shipment} />
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default ShipmentDetail;
