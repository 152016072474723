/*eslint-disable*/
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment';
import clsx from 'clsx';
import InboundIcon from '../../../assets/icons/inboundNew.svg';
import OutboundIcon from '../../../assets/icons/outboundNew.svg';
import TransferIcon from '../../../assets/icons/transferNew.svg';
import LogisticsIcon from '../../../assets/icons/shipment.svg';
import ReturnIcon from '../../../assets/icons/returnOrder.svg';
import RightArrowIcon from '../../../assets/icons/rightArrow.svg';
import OptionsMenu from '../../revampedOrder/Options';
import { Label, Paragraph } from '../../../core-components/atoms/Text';
import {
  capitalizeWords,
  convertToTitleCase
} from '../../revampedOrder/AddOrder';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import { icons } from '../../revampedOrder/statusIcons';
import { useEffect, useState } from 'react';
import { O360TableRow } from '../../revampedOrder/OrderTable';
import { InBoundShipmentRow } from '../../revampedOrder/components/tracking/InboundTMSShipmentTracking';
import { OutBoundShipmentRow } from '../../revampedOrder/components/tracking/OutboundTMSShipmentTracking';
import { TransferShipmentRow } from '../../revampedOrder/components/tracking/TransferTMSShipmentTracking';
import { LogisticShipmentRow } from '../../revampedOrder/components/tracking/LogisticTMSShipmentTracking';
import { calculateETA, calculateEuclideanDistance } from '../../../utils/common';

export const ShipmentTableRow = ({
  shipment,
  order,
  setOptionsClick,
  setActiveShipment,
  setActiveShipmentPopup,
  setCancelPopUp,
  setActiveShipmentOrder,
  setView,
  setShipmentDetailView,
  refetch,
  extraColumns,
  filters
}) => {
  const [expanded, setExpanded] = useState(false);
  const [distance, setDistance] = useState(0);
  let orderOptions = [];

  const { isFeatureEnabled } = useFeatureFlags();

  const calculateDistance = async () => {
    if (order?.pickupNode?.locationLatLng && order?.dropOffNode?.locationLatLng) {
      const distance = await calculateEuclideanDistance(
        order?.pickupNode?.locationLatLng,
        order?.dropOffNode?.locationLatLng
      )
      setDistance(distance)
    }
    else {
      setDistance(0)
    }
  }

  useEffect(() => {
    calculateDistance()
  }, [order])

  let statusFilters = (filters?.Status || []).map(status => status.value)

  let currentDate = moment();
  let isDepartureDelayed = (shipment.pickupDateTime
    ? moment(shipment.pickupDateTime).diff(currentDate) < 0 && !shipment.isLoaded
    : false) && (statusFilters.length > 0 ? statusFilters.indexOf('DEPARTURE_DELAYED') !== -1 : true);
  let eta = calculateETA(
    distance,
    shipment.ShipmentLegs?.[0]?.Vehicle?.avgSpeed,
    order?.expectedDeliveryDate,
    shipment.status?.key
  );
  let isArrivalDelayed = (shipment.dropoffDateTime ? eta === '0h 0min' && !shipment.isArrived : false) && (statusFilters.length > 0 ? statusFilters.indexOf('ARRIVAL_DELAYED') !== -1 : true);

  let isInvoiced =
    shipment?.InvoiceLineItems?.length > 0 &&
    shipment?.subStatus?.key == 'POD_UPLOADED';
  
  let isHalted = shipment.haltActions && (statusFilters.length > 0 ? statusFilters.indexOf('HALTED') !== -1 : true);

  return (
    <>
      <TableRow
        className="cursor-pointer group"
        sx={expanded && { '& > *': { borderBottom: 'unset' } }}
        onClick={(e) => {
          e.stopPropagation();
          expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            paddingLeft: '10px'
          }}
        >
          <div className="flex gap-2">
            <div className="flex items-center">
              {order.moveType == 'INWARD' &&
                order.moveSubType === 'SALES_RETURN' ? (
                <img src={ReturnIcon} className="w-4 h-4" />
              ) : order.moveType == 'INWARD' ? (
                <img src={InboundIcon} className="w-4 h-4" />
              ) : order.moveType == 'OUTBOUND' ? (
                <img src={OutboundIcon} className="w-4 h-4" />
              ) : order.moveType == 'TRANSFER' ? (
                <img src={TransferIcon} className="w-4 h-4" />
              ) : (
                <img src={LogisticsIcon} className="w-4 h-4" />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div>
                <Paragraph variant={'xs'} className={`text-primary`}>
                  {`${shipment.customId}`}
                </Paragraph>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex flex-row gap-1.5 items-center">
            <Paragraph variant={'sm'}>
              {order.pickupNode?.name
                ? order.pickupNode?.name?.length > 20
                  ? order.pickupNode?.name?.substring(0, 20) + '...'
                  : order.pickupNode?.name
                : '-'}
            </Paragraph>
            {order.dropOffNode?.name && order.dropOffNode?.name && (
              <img
                src={RightArrowIcon}
                alt="arrow"
                className="flex-shrink-0 w-[20px]"
              />
            )}
            <Paragraph variant={'sm'}>
              {order.dropOffNode?.name
                ? order.dropOffNode?.name?.length > 20
                  ? order.dropOffNode?.name?.substring(0, 20) + '...'
                  : order.dropOffNode?.name
                : '-'}
            </Paragraph>
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex flex-col gap-1">
            <Paragraph variant={'sm'}>
              {capitalizeWords(
                order.moveSubType
                  ? order.moveSubType
                  : order.moveType == 'INWARD'
                    ? 'INBOUND'
                    : order?.moveType
              )}
            </Paragraph>
            <Paragraph variant={'xs'} className="text-dark600">
              {order?.Company?.name}
            </Paragraph>
          </div>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex gap-2 items-center">
            <div
              className={clsx(
                icons[
                shipment.status.key == 'COMPLETED'
                  ? 'COMPLETED'
                  : shipment.status.key == 'CANCELLED'
                    ? 'CANCELLED'
                    : isHalted ? 'HALTED' : shipment.status.key == 'CREATED' ? (isDepartureDelayed ? 'DEPARTURE_DELAYED' : 'CREATED') : isArrivalDelayed ? 'ARRIVAL_DELAYED' : 'IN_PROGRESS'
                ],
                'w-1.5 h-1.5'
              )}
            />
            <Paragraph variant={'sm'}>
              {shipment.status.key == 'COMPLETED'
                ? 'Completed'
                : shipment.status.key == 'CANCELLED'
                  ? 'Cancelled'
                  : isHalted ? 'Halted' : shipment.status.key == 'ARRIVED_AT_DESTINATION'
                    ? 'Arrived'
                    : shipment.status.key == 'CREATED'
                      ? isDepartureDelayed ? 'Departure Delayed' : 'Created'
                      : isArrivalDelayed ? 'Arrival Delayed' : 'In-Transit'}
            </Paragraph>
          </div>
          <div className="flex gap-2 mt-1">
            <div className="w-2.5 h-2.5" />
            <p className="flex gap-1">
              <Label variant={'xs'}>
                {moment(shipment.updatedAt).format('DD/MM/YYYY')}
              </Label>
              <Label variant={'xs'}>
                {moment(shipment.updatedAt).format('hh:mm A')}
              </Label>
            </p>
          </div>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <Paragraph variant={'sm'}>
            {shipment?.carrierName ||
              shipment?.Company?.name ||
              shipment?.ShipmentLegs?.[0]?.Company?.name ||
              '-'}
          </Paragraph>
        </TableCell>

        {extraColumns?.indexOf('Shipment Mode') !== -1 && (
          <TableCell>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {convertToTitleCase(shipment?.ShipmentLegs?.[0]?.mode) || 'Land'}
            </Label>
          </TableCell>
        )}

        {extraColumns?.indexOf('Vehicle & Driver') !== -1 && (
          <TableCell>
            <Paragraph variant={'sm'}>
              {shipment?.vehicleNumber ||
                shipment?.Vehicle?.registrationNumber ||
                shipment?.ShipmentLegs?.[0]?.Vehicle?.registrationNumber ||
                '-'}
            </Paragraph>
            {shipment?.driverName ||
              shipment?.Driver?.name ||
              (shipment?.ShipmentLegs?.[0]?.Driver?.name && (
                <Paragraph variant={'sm'}>
                  {shipment?.driverName ||
                    shipment?.Driver?.name ||
                    shipment?.ShipmentLegs?.[0]?.Driver?.name ||
                    '-'}
                </Paragraph>
              ))}
          </TableCell>
        )}

        {extraColumns?.indexOf('PoD & Billing Status') !== -1 && (
          <TableCell>
            <div className="flex gap-3 items-center">
              {shipment?.status?.key == 'COMPLETED' && (
                <span
                  className={`min-w-[10px] min-h-[10px] rounded-[50%] ${shipment.subStatus?.key == 'POD_PENDING'
                    ? 'bg-[#FFCC00]'
                    : 'bg-success'
                    }`}
                />
              )}
              <Paragraph variant={'sm'}>
                {shipment?.status?.key == 'COMPLETED'
                  ? isInvoiced ? 'Invoice Generated' : shipment?.subStatus?.name?.replace('Pod', 'PoD')
                  : '-'}
              </Paragraph>
            </div>
          </TableCell>
        )}
        {extraColumns?.indexOf('Creation Date & Time') !== -1 && (
          <TableCell style={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              <div>
                <div style={{ fontWeight: 'bold' }}>
                  {moment(shipment.createdAt).local().format('DD/MM/YYYY')}
                </div>
                <div style={{ marginTop: '2px' }}>
                  {moment(shipment.createdAt).local().format('hh:mm A')}
                </div>
              </div>
            </Label>
          </TableCell>
        )}

        <TableCell component="th" scope="row" style={{ paddingTop: '16px' }}>
          {(isFeatureEnabled(FLAGS.TMS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
            ? true
            : order.moveType !== 'LOGISTIC') &&
            (expanded ? (
              <ExpandLessIcon
                className={'h-[20px] w-[20px] invisible group-hover:visible'}
                onClick={() => setExpanded(false)}
              />
            ) : (
              <ExpandMoreIcon
                className={'h-[20px] w-[20px] invisible group-hover:visible'}
                onClick={() => setExpanded(true)}
              />
            ))}
          {orderOptions.length > 0 && (
            <div onClick={(e) => e.stopPropagation()}>
              <OptionsMenu
                options={[]}
                closeCallback={() => setOptionsClick(false)}
                enterCallBack={() => setOptionsClick(true)}
              />
            </div>
          )}
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell
            colSpan={12}
            sx={{ paddingLeft: '16px', paddingRight: '32px' }}
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <div>
                <Table class="border-separate border-spacing-y-4 border-spacing-x-0 w-full">
                  <TableBody className="w-full">
                    {isFeatureEnabled(FLAGS.TMS) ||
                      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
                      order.moveType == 'OUTBOUND' ? (
                        <OutBoundShipmentRow
                          setActiveShipment={setActiveShipment}
                          setActiveShipmentOrder={setActiveShipmentOrder}
                          shipment={shipment}
                          order={order}
                          setOpen={setActiveShipmentPopup}
                          setCancelPopUp={setCancelPopUp}
                          expanded={expanded}
                          shipmentIdFilter={''}
                          setAction={() => { }}
                          refetch={refetch}
                          id={shipment.id}
                          setShipmentDetailView={setShipmentDetailView}
                        />
                      ) : order.moveType == 'INWARD' ? (
                        <InBoundShipmentRow
                          setActiveShipment={setActiveShipment}
                          setActiveShipmentOrder={setActiveShipmentOrder}
                          shipment={shipment}
                          order={order}
                          setOpen={setActiveShipmentPopup}
                          setCancelPopUp={setCancelPopUp}
                          expanded={expanded}
                          shipmentIdFilter={''}
                          setAction={() => { }}
                          refetch={refetch}
                          id={shipment.id}
                          setShipmentDetailView={setShipmentDetailView}
                        />
                      ) : order.moveType == 'TRANSFER' ? (
                        <TransferShipmentRow
                          setActiveShipment={setActiveShipment}
                          setActiveShipmentOrder={setActiveShipmentOrder}
                          shipment={shipment}
                          order={order}
                          setOpen={setActiveShipmentPopup}
                          setCancelPopUp={setCancelPopUp}
                          expanded={expanded}
                          shipmentIdFilter={''}
                          setAction={() => { }}
                          refetch={refetch}
                          id={shipment.id}
                          setShipmentDetailView={setShipmentDetailView}
                        />
                      ) : (
                        <LogisticShipmentRow
                          setActiveShipment={setActiveShipment}
                          setActiveShipmentOrder={setActiveShipmentOrder}
                          shipment={shipment}
                          order={order}
                          setOpen={setActiveShipmentPopup}
                          setCancelPopUp={setCancelPopUp}
                          expanded={expanded}
                          shipmentIdFilter={''}
                          setAction={() => { }}
                          refetch={refetch}
                          id={shipment.id}
                          setShipmentDetailView={setShipmentDetailView}
                        />
                      )
                    ) : (
                      <O360TableRow
                        setActiveShipment={setActiveShipment}
                        setActiveShipmentOrder={setActiveShipmentOrder}
                        shipment={shipment}
                        order={order}
                        setCancelPopUp={setCancelPopUp}
                        setShipmentDetailView={() => { }}
                        expanded={expanded}
                        shipmentIdFilter={''}
                        setAction={() => { }}
                        refetch={refetch}
                        id={shipment.id}
                        setActiveShipmentPopup={setActiveShipmentPopup}
                        setView={setView}
                      />
                    )}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
