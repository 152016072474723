/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import API from '../../libs/axios';
import { ShipmentMode } from './constants';
import {
  SharedContext,
  calculateETA,
  calculateTimeDifference,
  calculateEuclideanDistance,
  decimalDisplay,
  getCurrencySymbol
} from '../../utils/common';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';
const ShipmentStatsSecondary = ({
  id = '',
  weight,
  selectedShipmentMode,
  selectedOrder,
  shipment,
  mileage,
  fuelPrice,
  selectedShipmentId,
  speed
}) => {
  const [shipmentId, setShipmentId] = useState();
  const { organization, settings } = useContext(SharedContext);
  const [distance, setDistance] = useState(0)
  const { isFeatureEnabled } = useFeatureFlags();

  const getNextShipmentId = async () => {
    const response = await API.get(`orders/shipments/next`);
    setShipmentId(response?.data || '-');
  };

  const calculateDistance = async () => {
    if (selectedOrder?.pickupNode?.locationLatLng && selectedOrder?.dropOffNode?.locationLatLng) {
      const distance = await calculateEuclideanDistance(
        selectedOrder?.pickupNode?.locationLatLng,
        selectedOrder?.dropOffNode?.locationLatLng
      )
      setDistance(distance)
    }
    else {
      setDistance(0)
    }
  }

  useEffect(() => { calculateDistance() }, [selectedOrder])

  useEffect(() => {
    if (selectedShipmentId) {
      setShipmentId(selectedShipmentId);
    } else getNextShipmentId();
  }, [selectedShipmentId]);

  return (
    <>
      <div className="w-full flex gap-12 h-[88px]">
        <div className="h-fit">
          <div>
            <p className="font-medium text-dark400 text-xs uppercase">
              Shipment ID
            </p>
            <p className="text-primaryBlue text-sm">{shipmentId}</p>
          </div>
          <div className="mt-4">
            <p className="font-medium text-dark400 text-xs uppercase">Trip Duration</p>
            <p className="text-primaryBlue text-sm">
              {isFeatureEnabled(FLAGS.TMS) ||
                isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                ? `${calculateETA(
                  distance,
                  speed,
                  selectedOrder?.expectedDelivery,
                  shipment?.status?.key
                )}`
                : `${calculateTimeDifference(
                  shipment?.dropoffDateTime,
                  shipment?.status?.key
                ).hours
                }h ${calculateTimeDifference(
                  shipment?.dropoffDateTime,
                  shipment?.status?.key
                ).minutes
                }min`}
            </p>
          </div>
        </div>
        <div className="h-fit">
          <div>
            <p className="font-medium text-dark400 text-xs uppercase">
              Order ID
            </p>
            <p className="text-primaryBlue text-sm">{id}</p>
          </div>
          {selectedShipmentMode === ShipmentMode.LAND && (
            <div className="mt-4">
              <p className="font-medium text-dark400 text-xs uppercase">
                EST. fuel consumption
              </p>
              <p className="text-primaryBlue text-sm">
                {distance && mileage && selectedShipmentMode == 'LAND'
                  ? `${decimalDisplay(
                    parseFloat(distance / mileage).toFixed(3)
                  )} litres`
                  : '-'}
              </p>
            </div>
          )}
        </div>
        <div className="h-fit">
          <div>
            <p className="font-medium text-dark400 text-xs uppercase">
              EST. Shipment weight
            </p>
            <p className="text-primaryBlue text-sm">
              {weight
                ? `${decimalDisplay(weight)} ${settings?.weight?.symbol || 'Kg'
                }`
                : '-'}
            </p>
          </div>
          {selectedShipmentMode === ShipmentMode.LAND && (
            <div className="my-4">
              <p className="font-medium text-dark400 text-xs uppercase">
                EST. Fuel Cost
              </p>
              <p className="text-primaryBlue text-sm">
                {distance &&
                  mileage &&
                  fuelPrice &&
                  selectedShipmentMode == 'LAND'
                  ? ` ${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(
                    parseFloat((distance / mileage) * fuelPrice).toFixed(3)
                  )}`
                  : '-'}
              </p>
            </div>
          )}
        </div>
        <div className="h-fit">
          <p className="font-medium text-dark400 text-xs uppercase">
            est. distance
          </p>
          <p className="text-primaryBlue text-sm">
            {distance ? decimalDisplay(distance) : 0} Km
          </p>
        </div>
      </div>
    </>
  );
};

export default ShipmentStatsSecondary;
