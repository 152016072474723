import React, { useCallback, useContext, useEffect, useState } from 'react';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import ExpenseForm from './ExpenseForm';
import { Label } from '../../../core-components/atoms/Text';
import { Grid } from '@material-ui/core';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { debounce } from 'lodash';

const ExpenseAddListing = ({
  expenses,
  setExpenses,
  open,
  setOpen,
  order,
  postExpense,
  shipment
}) => {
  const { organization } = useContext(SharedContext);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const fetchSearchResults = async (searchQuery, organization) => {
    setOptions([]);
    try {
      const [response1, response2] = await Promise.all([
        await API.get(`/companies`, {
          params: { search: searchQuery, type: 'VENDOR' }
        }),
        await API.get(`/vendors`, {
          params: { search: searchQuery }
        })
      ]);
      // Normalize data to have a consistent label and type for each source
      const combinedResults = [
        ...[
          {
            label: organization?.name,
            category: 'Organization',
            id: organization?.id
          }
        ],
        ...response1.data.rows.map((item) => ({
          label: item?.name,
          category: 'Carrier',
          id: item.id,
          ...item
        })),
        ...response2.data.map((item) => ({
          label: item?.name,
          category: 'Vendors',
          id: item?.id,
          ...item
        }))
      ];
      setOptions(combinedResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Debounce the search function
  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, 500),
    []
  );

  // Update search results based on query
  useEffect(() => {
    if (open) {
      debouncedFetchSearchResults(query, organization);
    }
  }, [query, open, organization]);

  // Handle search input change
  const handleSearchInputChange = (event, newInputValue) => {
    if (event) {
      setQuery(newInputValue);
    }
  };

  const removeExpense = (e) => {
    let array = expenses?.filter((_, index) => index !== e);
    setExpenses(array);
  };

  const isAllValid = expenses?.every((item) => {
    return (
      item &&
      typeof item.name === 'string' &&
      item.name.trim() !== '' &&
      (typeof item.cost === 'string' || typeof item.cost === 'number') &&
      String(item.cost).trim() !== '' &&
      Number(item.cost) !== 0
    );
  });

  return (
    <div style={{ display: 'inline' }}>
      <Popup
        open={open}
        setOpen={setOpen}
        title={'Add Expense'}
        content={
          <div id={'scrollableDiv'}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Expense Name
                </Label>
              </Grid>
              {!shipment && (
                <Grid item xs={2}>
                  <Label variant={'xs'} className={'text-dark500'}>
                    type
                  </Label>
                </Grid>
              )}
              <Grid item xs={2}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Cost
                </Label>
              </Grid>
              <Grid item xs={3}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Supplier Name
                </Label>
              </Grid>
              <Grid item xs={1}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Billable
                </Label>
              </Grid>
              <Grid item xs={1}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Documents
                </Label>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {expenses?.map((value, index) => {
              return (
                <div key={index}>
                  <ExpenseForm
                    expense={value}
                    setExpenses={setExpenses}
                    expenses={expenses}
                    removeExpense={removeExpense}
                    index={index}
                    order={order}
                    shipment={shipment}
                    handleSearchInputChange={handleSearchInputChange}
                    options={options}
                    deletePermission={true}
                  />
                </div>
              );
            })}
            <CustomButton
              label="+ Add More"
              onClick={() =>
                setExpenses([
                  ...expenses,
                  ...[
                    {
                      name: '',
                      cost: 0,
                      shipmentId: null,
                      fileIds: null
                    }
                  ]
                ])
              }
              className={'mt-2'}
              variant="text"
              size="large"
              overrideSize={true}
              labelClass="font-medium text-xs"
            />
          </div>
        }
        onClose={() => {
          setOpen(false);
        }}
        dialogContentClasses={'w-[68vw]'}
        actions={
          <div className="flex gap-2">
            <CustomButton
              label="Cancel"
              className="py-2 px-3 ml-2 h-8 border-solid border "
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                setOpen(false);
              }}
              variant="tertiary"
            />
            <CustomButton
              disabled={!isAllValid}
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              label={'Add Expense'}
              onClick={async () => {
                await postExpense();
              }}
              variant="primary"
            />
          </div>
        }
      />
    </div>
  );
};

export default ExpenseAddListing;
