/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import API from '../../../libs/axios';
import Popup from '../../../core-components/atoms/Popup';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import { ArrowDropDown, Close, Search } from '@material-ui/icons';
import CustomTextField from '../../../core-components/atoms/TextField';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import {
  dateFormatSlashDivider,
  decimalDisplay,
  inventoryLocationFormat,
  productLabelFormat,
  SharedContext
} from '../../../utils/common';
import { toaster } from '../../../utils/toaster';
import filterIcon from '../../../assets/icons/filterDarkIcon.svg';
import { NumericFormat } from 'react-number-format';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import BatchesFilter from '../components/BatchesFilter';
import LocationsFilter from '../components/LocationsFilter';
import { onError } from '../../../libs/errorLib';
import CustomPagination from '../../invoiceandbilling/invoiceManagement/CustomPagination';

const SectionRelocation = ({
  inventoryIds,
  batchIds,
  refetch,
  stock360Enabled,
  batchExpiryEnabled,
  open,
  setOpen,
  warehouseIds,
  callBack
}) => {
  const [batches, setBatches] = useState([]);
  const [halls, setHalls] = useState([]);
  const [search, setSearch] = useState('');
  const [persistedData, setPersistedData] = useState({});
  const [locationFilterOpen, setLocationFilterOpen] = useState(false);
  const [batchesFilterOpen, setBatchesFilterOpen] = useState(false);
  const [batchFilterIds, setBatchFilterIds] = useState({});
  const [locationFilterIds, setLocationFilterIds] = useState({});
  const [warehouseHallSearch, setWarehouseHallSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const { setAPILoader } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const fetchBatches = async () => {
    const res = await API.get('inventory-details', {
      params: {
        inventoryIds,
        batchIds,
        search,
        batchNumbers: [
          ...Object.keys(batchFilterIds).map((b) => batchFilterIds[b])
        ]
          .filter((b) => b.length > 0)
          .join(','),
        locations: [...Object.keys(locationFilterIds)]
          .filter((b) => b.length > 0)
          .join(','),
        limit: 10,
        page,
        inventoryOperation: 'SECTION_RELOCATION'
      }
    });
    let parsedBatches = [];
    setPages(res.pages);
    if (res?.data?.length > 0) {
      for (let i = 0; i < res.data.length; i++) {
        const row = res.data[i];
        if (row.availableQuantity > 0) {
          parsedBatches.push({
            availableQuantity: row.availableQuantity,
            quantity: 0,
            inventoryId: row.inventoryId,
            product: row?.Inventory?.Product,
            id: row?.id,
            batchNumber: row?.batchNumber || row?.batchName,
            batchName: row?.batchName,
            expiryDate: row?.expiryDate,
            mfgDate: row?.manufacturingDate,
            binLocation: row?.WarehouseHall,
            uom: {
              ...row?.Inventory?.Product?.UOM,
              primary: true,
              conversionFactor: 1
            },
            warehouse: row?.Inventory?.Warehouse,
            destination: {}
          });
        }
      }
    }

    setBatches(parsedBatches);
    setOpen(true);
  };

  const createStockRelocation = async () => {
    setAPILoader(true);
    const payload = [];
    let index = 0;
    try {
      for (let key of Object.keys(persistedData)) {
        if (
          persistedData[key]?.destinationSectionId &&
          persistedData[key]?.relocateQuantity
        ) {
          payload.push({
            ...persistedData[key],
            relocateQuantity: Number(
              persistedData[key].uom?.primary
                ? persistedData[key].relocateQuantity
                : Number(
                    (
                      persistedData[key].relocateQuantity *
                      persistedData[key].uom?.conversionFactor
                    ).toFixed(3)
                  )
            )
          });

          delete payload[index]['uom'];
          index++;
        }
      }

      await API.post(`warehouses/relocations`, payload);
      await refetch();
      setOpen(false);
      setBatches([]);
      callBack && callBack();
      toaster('success', 'Section Relocation Performed Successfully');
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const getHalls = async (warehouseIds) => {
    const res = await API.get('warehouses/halls', {
      params: { warehouseIds, limit: 50, search: warehouseHallSearch }
    });
    setHalls(res.data);
  };

  const fetchData = async () => {
    setAPILoader(true);
    await fetchBatches();
    setAPILoader(false);
  };

  useEffect(() => {
    if ((open && inventoryIds?.length > 0) || (open && batchIds?.length > 0)) {
      fetchData();
    }
  }, [open, inventoryIds, search, batchFilterIds, locationFilterIds, page]);

  let validateSubmit =
    Object.keys(persistedData).length == 0
      ? false
      : Object.keys(persistedData)?.reduce((acc, batch) => {
          return (
            acc &&
            ((!persistedData[batch].destinationSectionId &&
              !persistedData[batch].relocateQuantity) ||
              (persistedData[batch].destinationSectionId &&
                persistedData[batch].relocateQuantity))
          );
        }, true);

  const handleInputChange = (id, field, value, metaValues) => {
    setPersistedData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...metaValues,
        [field]: value
      }
    }));
  };

  useEffect(() => {
    getHalls(warehouseIds);
  }, [warehouseHallSearch]);

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        title={<>Stock Relocation</>}
        onClose={() => {
          setPersistedData({});
        }}
        actions={
          <div className="flex gap-4 mr-4">
            <Button
              label={`Cancel`}
              variant="tranparent"
              labelClass="font-medium text-xs"
              onClick={async (e) => {
                setBatches([]);
                setOpen(false);
              }}
            />
            <Button
              label={`Confirm`}
              variant="primary"
              labelClass="font-medium text-xs"
              onClick={async (e) => {
                await createStockRelocation();
              }}
              disabled={!validateSubmit}
            />
          </div>
        }
        content={
          <div className="p-4 min-w-[1200px]">
            <div className="flex gap-4 items-center justify-between">
              <p className="text-[18px] font-bold">
                {inventoryIds?.length}{' '}
                {inventoryIds?.length > 1 ? 'Products' : 'Product'} selected
              </p>
              <div className="flex gap-4">
                <CustomTextField
                  placeholder="Search Table"
                  variant="outlined"
                  color={'primary'}
                  size="small"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  inputProps={{
                    onFocus: () => search
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <Search
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px'
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {search && (
                          <Close
                            style={{
                              width: '20px',
                              height: '20px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setSearch('');
                            }}
                          />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
                {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
                  <LocationsFilter
                    open={locationFilterOpen}
                    setOpen={setLocationFilterOpen}
                    warehouseIds={warehouseIds}
                    values={locationFilterIds}
                    setValues={setLocationFilterIds}
                    callBack={() => setPage(1)}
                  />
                )}
                {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
                  <BatchesFilter
                    open={batchesFilterOpen}
                    setOpen={setBatchesFilterOpen}
                    batchIds={batchIds}
                    values={batchFilterIds}
                    setValues={setBatchFilterIds}
                    inventoryIds={inventoryIds}
                    callBack={() => setPage(1)}
                  />
                )}
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="mt-10 w-full"
              sx={{
                borderRadius: '16px',
                border: '1px solid #E7E6E6 !important',
                outline: 'none !important'
              }}
              elevation={0}
            >
              <Table sx={{ border: 'none' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: '' }}
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                    >
                      SKU Name & Node
                    </TableCell>
                    {stock360Enabled && (
                      <TableCell
                        style={{ width: '' }}
                        className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      >
                        Bin Location
                      </TableCell>
                    )}
                    {batchExpiryEnabled && (
                      <TableCell
                        style={{ width: '' }}
                        className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      >
                        Batch No, MFG & EXP
                      </TableCell>
                    )}
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      Available Qty
                    </TableCell>
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      UoM
                    </TableCell>
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      Destination
                    </TableCell>
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      Relocation Qty
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches?.map((batch, id) => {
                    const uoms = [
                      {
                        ...batch.product?.UOM,
                        primary: true,
                        conversionFactor: 1
                      },
                      ...(batch.product?.ProductUOMs?.map((uom) => ({
                        conversionFactor: uom.conversionFactor,
                        name: uom?.UOM?.name,
                        primary: false
                      })) || [])
                    ];

                    let metaPayload = {
                      sourceSectionId: batch.binLocation?.id,
                      batchName: batch.batchName,
                      inventoryId: batch.inventoryId,
                      warehouseId: batch?.warehouse?.id,
                      productId: batch?.product?.id,
                      inventoryDetailId: batch?.id,
                      uom: batch?.uom
                    };

                    return (
                      <TableRow key={id}>
                        <TableCell className="w-[15%]">
                          <p className="text-sm whitespace-nowrap">
                            {productLabelFormat(batch?.product).length > 20
                              ? `${productLabelFormat(batch?.product).substring(
                                  0,
                                  20
                                )}...`
                              : `${productLabelFormat(batch?.product)}`}
                          </p>
                          <p className="text-xs whitespace-nowrap overflow-ellipsis mt-2">
                            {inventoryLocationFormat(batch?.warehouse).length >
                            20
                              ? `${inventoryLocationFormat(
                                  batch?.warehouse
                                ).substring(0, 20)}...`
                              : `${inventoryLocationFormat(batch?.warehouse)}`}
                          </p>
                        </TableCell>
                        {stock360Enabled && (
                          <TableCell className="w-[10%] text-xs whitespace-nowrap">
                            <p className="text-sm">{batch.binLocation?.name}</p>
                            <div className="flex gap-2 mt-2 whitespace-nowrap">
                              <span>
                                Aisle: {batch.binLocation?.Aisle?.name}
                              </span>
                              <span>Bay: {batch.binLocation?.Bay?.name}</span>
                            </div>
                          </TableCell>
                        )}
                        {batchExpiryEnabled && (
                          <TableCell className="w-[15%] text-xs whitespace-nowrap">
                            {batch.product.batchEnabled ? (
                              <>
                                <p className="text-sm">
                                  {batch.batchNumber?.length > 15
                                    ? `${batch.batchNumber?.substring(
                                        0,
                                        15
                                      )}...`
                                    : batch.batchNumber}
                                </p>
                                <p className="mt-2">
                                  {dateFormatSlashDivider(batch.mfgDate)}-{' '}
                                  {dateFormatSlashDivider(batch.expiryDate)}
                                </p>
                              </>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )}

                        <TableCell className="w-[5%] text-sm whitespace-nowrap">
                          {decimalDisplay(
                            batch?.availableQuantity /
                              batch?.uom?.conversionFactor
                          )}
                        </TableCell>
                        <TableCell className="w-[20%]">
                          <Autocomplete
                            value={batch.uom}
                            onChange={(e, value) => {
                              const copiedArr = [...batches];
                              if (value.primary) {
                                copiedArr[id].quantity = Number(
                                  (
                                    copiedArr[id].quantity *
                                    copiedArr[id].uom.conversionFactor
                                  ).toFixed(3)
                                );
                              } else {
                                copiedArr[id].quantity = Number(
                                  (
                                    copiedArr[id].quantity /
                                    value?.conversionFactor
                                  ).toFixed(3)
                                );
                              }
                              copiedArr[id].uom = value;
                              setBatches([...copiedArr]);

                              handleInputChange(
                                batch.id,
                                'uom',
                                value,
                                metaPayload
                              );
                              handleInputChange(
                                batch.id,
                                'relocateQuantity',
                                copiedArr[id].quantity,
                                metaPayload
                              );
                            }}
                            options={uoms}
                            getOptionLabel={(uom) => uom?.name || ''}
                            renderOption={(props, value) => (
                              <li className="auto-complete-option" {...props}>
                                {value.name} {value.primary ? ' (primary)' : ''}
                              </li>
                            )}
                          />
                        </TableCell>
                        <TableCell className="w-[25%] text-xs whitespace-nowrap">
                          <Autocomplete
                            value={batch.destination}
                            onChange={(e, value) => {
                              const copiedArr = [...batches];
                              copiedArr[id].destination = value;

                              handleInputChange(
                                batch.id,
                                'destinationSectionId',
                                value?.id,
                                metaPayload
                              );
                              setBatches([...copiedArr]);
                              setWarehouseHallSearch('');
                            }}
                            options={halls?.filter(
                              (h) =>
                                h.warehouseId === batch.Warehouse?.id &&
                                h.id !== batch.binLocation?.id
                            )}
                            getOptionLabel={(d) => d.name || ''}
                            onInputChange={(e, value) => {
                              setWarehouseHallSearch(value);
                            }}
                            clearOnBlur
                          />
                        </TableCell>
                        <TableCell className="w-[5%] text-xs whitespace-nowrap">
                          <NumericFormat
                            customInput={CustomTextField}
                            placeholder="qty"
                            variant="outlined"
                            size="small"
                            decimalScale={3}
                            fullWidth
                            value={batch.quantity}
                            onValueChange={(e) => {
                              const copiedArr = [...batches];
                              copiedArr[id].quantity = e.value;
                              handleInputChange(
                                batch.id,
                                'relocateQuantity',
                                e.value,
                                metaPayload
                              );
                              setBatches([...copiedArr]);
                            }}
                            InputProps={{
                              style: { fontSize: '12px', width: '100%' }
                            }}
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              const uomConvertedValue = batch.uom?.primary
                                ? batch.availableQuantity
                                : Number(
                                    (
                                      batch.availableQuantity /
                                      batch.uom?.conversionFactor
                                    ).toFixed(3)
                                  );
                              if (uomConvertedValue - floatValue < 0) {
                                return false;
                              }
                              return true;
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination pages={pages} page={page} setPage={setPage} />
          </div>
        }
      />
      <Button
        variant="transparent"
        label={`Section Relocation`}
        className="py-2 px-3 ml-2 h-8 border-solid border "
        overrideSize={true}
        labelClass="font-medium text-xs"
        onClick={(e) => {
          setOpen(true);
        }}
      />
    </>
  );
};

export default SectionRelocation;
