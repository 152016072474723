/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { SharedContext, exportToCSV } from '../../../utils/common';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';
import Button from '../../../core-components/atoms/Button';
import { toaster } from '../../../utils/toaster';
import PopupButton from '../../../core-components/molecules/PopupButton';
import RefuelingForm from './RefuelingForm';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { upload } from '../../../utils/upload';
import ExportIcon from '../../../assets/icons/downloadOutlineIcon.svg';
import CustomPagination from '../../invoiceandbilling/invoiceManagement/CustomPagination';

const RefuelingView = ({ vehicle }) => {
  const [maintainences, setMaintainences] = useState([]);
  const [maintenanceDetails, setMaintenanceDetails] = useState([]);
  const [distance, setDistance] = useState(null);
  const [refuel, setRefuel] = useState(null);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [addNewRow, setAddNewRow] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const [detailsPage, setDetailsPage] = useState();
  const [detailsPages, setDetailsPages] = useState();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginBottom: '20px'
    },
    container: {
      // maxHeight: 450,
      padding: 20
    },
    searchInputVehicle: {
      opacity: 0.6,
      padding: '0px 8px',
      marginRight: 7,
      transition: '0.5s',
      width: '45px',
      height: '36px',
      borderRadius: '20px',
      border: '1px solid lightgray'
    },
    addVehicleButton: {
      marginLeft: '12px',
      backgroundColor: '#2b3a53',
      float: 'right',
      color: 'white',
      fontWeight: 'bold'
    },
    btnGroup: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: '10px',
      padding: '10px'
    },
    cancelBtn: {
      padding: '8px 12px',
      // gap: '6px',
      borderRadius: 4,
      cursor: 'pointer',
      color: '#0B1940'
    },

    submitBtn: {
      padding: '8px 12px',
      background: '#0C6BD7',
      borderRadius: 4,
      cursor: 'pointer',
      color: '#FFFFFF',
      '&:disabled': {
        background: 'rgba(12,107,215,0.5)'
      }
    }
  }));

  const formik = useFormik({
    initialValues: { distance: null, vendor: null }
  });

  const { setAPILoader, settings, organization } = useContext(SharedContext);

  const getMaintenanceDetails = async () => {
    setAPILoader(true);
    try {
      const res = await API.get(`vehicles/${vehicle.id}/maintenance-details`, {
        params: { page: detailsPage }
      });
      setMaintenanceDetails(res.data);
      setDetailsPages(res.pages);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const getVehicleMaintenances = async () => {
    setAPILoader(true);
    try {
      const res = await API.get(`vehicles/${vehicle.id}/maintenance`, {
        params: { page }
      });
      setMaintainences(res.data);
      setPages(res.pages);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getMaintenanceDetails();
    getVehicleMaintenances();
  }, [page]);

  const createVehicleMaintenance = async () => {
    const payload = {
      mileage: formik.values.distance,
      refuel: formik.values.refuel,
      fuelPrice: formik.values.fuelPrice,
      vehicleId: vehicle.id,
      location: formik.values.location,
      createdAt: formik.values.date,
      vendorId: formik.values.vendor?.id
    };

    if (formik.values.images) {
      const fileIds = await upload(formik.values.images, 'vehicle');
      payload.supportingDocuments = fileIds;
    }

    try {
      setAPILoader(true);
      await API.post(`vehicles/${vehicle.id}/maintenance`, payload);
      await getVehicleMaintenances();
      formik.resetForm();
      setAddNewRow(false);
      setDistance(null);
      setRefuel(null);
      setFuelPrice(null);
      toaster('success', 'Refueling detail added');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const exportToExcel = () => {
    const payload = maintainences?.map((data) => {
      const { createdAt, User, location, refuel, fuelPrice, mileage } = data;

      return {
        createdAt: createdAt ? moment(createdAt).format('DD/MM/yy HH:mm') : '-',
        location: location || '-',
        mileage: mileage || '-',
        refuel: refuel || '-',
        fuelPrice: refuel || '-',
        createdBy: User ? User.firstName + ' ' + User.lastName : '-'
      };
    });
    let title = `Refueling details ${moment().format('DD-MM-yyyy')}`;
    const header = [
      'Date',
      'Location',
      'Speedometer Reading',
      'Refuel (Ltrs)',
      'Fuel Price',
      'Created By'
    ];
    exportToCSV(header, payload, title);
  };

  const classes = useStyles();

  return (
    <div className="min-h-[500px]">
      <div className="flex gap-4">
        {vehicle.photoPreview && (
          <div className="w-[20%]">
            <img src={vehicle.photoPreview} className="w-[50%]" />
          </div>
        )}
        <div className={vehicle.photoPreview ? 'w-[80%]' : 'w-[100%]'}>
          <div>
            <div className="flex gap-2">
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">REGISTRATION NO</p>
                <p className="text-xs">{vehicle?.registrationNumber}</p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">CARRIER NAME</p>
                <p className="text-xs">{vehicle?.Vendor?.name}</p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">VEHICLE TYPE</p>
                <p className="text-xs">
                  {vehicle?.Car?.name
                    ? vehicle?.Car?.name
                    : `${vehicle?.Car?.CarMake?.name || ''} ${vehicle?.Car?.CarModel?.name || ''
                    } ${vehicle?.Car?.Category?.name || ''}`}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-2 mt-6">
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">
                  VEHICLE {`${settings?.distance?.symbol || 'KM'}s`}
                </p>
                <p className="text-xs">
                  {vehicle?.distance} {settings?.distance?.symbol || 'Km'}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">MAINTENANCE AFTER</p>
                <p className="text-xs">
                  {vehicle?.maintainenceMileage}{' '}
                  {settings?.distance?.symbol || 'Km'}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">
                  LAST MAINTENANCE MILEAGE
                </p>
                <p className="text-xs">
                  {vehicle?.lastMaintainenceMileage}{' '}
                  {settings?.distance?.symbol || 'Km'}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-2 mt-6">
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">MAINTENANCE CATEGORY</p>
                <p className="text-xs">
                  {vehicle?.VehicleMaintenanceCategory?.name}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">
                  MAINTENANCE ADDITIONAL INFO
                </p>
                <p className="text-xs">{vehicle?.additionalInfo}</p>
              </div>
              <div className="w-[30%]">
                <p className="text-dark500 text-[10px]">
                  EXPENSE
                </p>
                <p className="text-xs">{organization.defaultCurrency || 'PKR'} {maintenanceDetails?.reduce((acc, curr) => acc + curr.cost, 0)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12">
        <p className="font-bold">Vehicle Maintenance Details</p>
        <Grid item xs={12} className="mt-12">
          <div>
            <TableContainer
              sx={{
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                overflow: 'hidden',
                border: '1px solid #E7E6E6 !important',
                '& .MuiTable-root': {
                  borderCollapse: 'unset'
                }
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        DATE
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        MILEAGE
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        CATEGORY
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        VENDOR
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        COST
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        ADDITIONAL INFO
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        CREATED BY
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenanceDetails?.map((res, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <div className="text-xs p-1">
                            {moment(res.createdAt).format('DD/MM/YYYY')}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-xs p-1">{res.mileage}</div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.VehicleMaintenanceCategory?.name || '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.Vendor?.name || '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.cost ? `${organization.defaultCurrency || 'PKR'} ${res.cost}` : '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.additionalInfo}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.User?.name || '-'}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination
              page={detailsPages}
              setPage={setPage}
              pages={detailsPages}
            />
          </div>
        </Grid>
      </div>
      <div className="mt-12">
        <p className="font-bold">Vehicle Refueling Details</p>
        <Grid item xs={12} className="mt-12">
          <div>
            <TableContainer
              sx={{
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                overflow: 'hidden',
                border: '1px solid #E7E6E6 !important',
                '& .MuiTable-root': {
                  borderCollapse: 'unset'
                }
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        DATE
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        LOCATION
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        SPEEDOMETER READING (
                        {settings?.distance?.symbol || 'Km'})
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        VENDOR
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        REFUEL (LTR)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        FUEL PRICE (Per Ltr)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-dark500 text-[10px] font-normal">
                        CREATED BY
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintainences?.map((res, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <div className="text-xs p-1">
                            {moment(res.createdAt).format('DD/MM/YYYY')}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-xs p-1">
                            {res.location || '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">{res.mileage}</div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">{res.Vendor?.name || '-'}</div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">{res.refuel}</div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">{res.fuelPrice}</div>
                        </TableCell>
                        <TableCell>
                          <div className="p-1 text-xs">
                            {res.User
                              ? `${res.User.firstName + ' ' + res.User.lastName
                              }`
                              : '-'}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination page={page} setPage={setPage} pages={pages} />
          </div>
        </Grid>
        <div className="mt-28 flex gap-3">
          <PopupButton
            open={addNewRow}
            setOpen={setAddNewRow}
            placement={'top-start'}
            btn={
              <Button
                label={'Record Fuel & Mileage'}
                onClick={() =>
                  !addNewRow ? setAddNewRow(true) : setAddNewRow(false)
                }
              />
            }
            content={
              <div>
                <RefuelingForm formik={formik} />
                <div className={classes.btnGroup}>
                  <button
                    className={classes.cancelBtn}
                    type="button"
                    onClick={() => {
                      setAddNewRow(false);
                      formik.resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={classes.submitBtn}
                    disabled={
                      !formik.values.distance ||
                      !formik.values.date ||
                      !formik.values.refuel ||
                      !formik.values.fuelPrice ||
                      !formik.values.location
                    }
                    onClick={async () => await createVehicleMaintenance()}
                  >
                    Add
                  </button>
                </div>
              </div>
            }
          />
          {maintainences?.length > 0 && (
            <Button
              label={'Export'}
              onClick={exportToExcel}
              icon={<img src={ExportIcon} className="mr-2 " />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RefuelingView;
