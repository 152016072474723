/*eslint-disable*/
import { useState, useContext, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { SharedContext, addDaysToCurrentDate } from '../../../utils/common';
import CustomTabs from '../../../components/CustomTabs';
import useStyles from '../invoiceStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import BasicInfo from './BasicInfo';
import InvoiceSummary, { calculateTotal } from './InvoiceSummary';
import InvoiceForm from './InvoiceForm';
import { upload } from '../../../utils/upload';
import Preview from './Preview';
import { invoiceFormSchema } from './formikFields';
import { toaster } from '../../../utils/toaster';
import { formikInitVals } from '../expenseManagement/AddExpense';
import { checkPermission } from '../../../utils/auth';
import mixpanel from 'mixpanel-browser';
import ShipmentListing from './ShipmentListing';

export default function AddInvoice({ type }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState('Info');
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [draftItemLength, setDraftItemLength] = useState(null);
  const [organization, setOrganization] = useState({});
  const [html, setHtml] = useState();
  const [emailPreview, setEmailPreview] = useState();
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [res, setRes] = useState({});
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [disableType, setDisableType] = useState(false);
  const [invoiceTitles, setInvoiceTitles] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [invoiceStartError, setInvoiceStartError] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [editableInvoiceType, setEditableInvoiceType] = useState(false);
  const [selected, setSelected] = useState([]);
  const [pendingShipments, setPendingShipments] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('trips');
  const [shipmentsearch, setShipmentSearch] = useState('');
  const [sourceType, setSourceType] = useState();
  const { currentUser, setAPILoader, subdomain, setCurrentPageTitle, sidebar } =
    useContext(SharedContext);

  const invoiceBillingFlag = true;

  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: invoiceFormSchema,
    validateOnChange: firstSubmit,
    validateOnBlur: firstSubmit,
    validate: async (values) => {
      if (!values.invoiceStartPeriod) {
        setInvoiceStartError('Invoice date is required');
      } else {
        setInvoiceStartError('');
      }
    },
    onSubmit: async (values) => {
      changeTab(await nextTab());
    }
  });

  const generatePreviewData = () => {
    let lineItems = {};

    for (let i = 0; i < selected.length; i++) {
      const shipmentId = selected[i].shipmentId;
      if (lineItems[shipmentId]) {
        if (selected[i].type == 'Expense') {
          lineItems[shipmentId].expenses.push(selected[i].expenseId);
        } else {
          lineItems[shipmentId].isSellingPriceInvoiced = true;
        }
      } else {
        lineItems[shipmentId] = {
          expenses: [],
          shipmentId,
          isSellingPriceInvoiced: false
        };
        if (selected[i].type == 'Expense') {
          lineItems[shipmentId].expenses.push(selected[i].expenseId);
        } else {
          lineItems[shipmentId].isSellingPriceInvoiced = true;
        }
      }
    }
    let previewData = {
      company: formik?.values?.company,
      invoice: {
        number: formik?.values?.invoiceID,
        type: formik?.values?.invoiceType,
        date: moment(formik?.values?.invoiceStartPeriod).format('DD/MM/YYYY'),
        dueDate: moment(formik?.values?.due).format('DD/MM/YYYY'),
        subTotal: calculateTotal(false, invoiceItems) || 0,
        currency: formik.values.currency,
        tax: calculateTotalTax().toFixed(2),
        terms: formik.values.company?.paymentTerms,
        balanceDue: (
          calculateTotal(true, invoiceItems) -
          Number(formik.values.discount) +
          Number(formik.values.adjustment)
        ).toFixed(2),
        notes: formik.values.notes,
        billing: false,
        adjustment: formik.values.adjustment
          ? parseFloat(formik.values.adjustment)?.toFixed(2)
          : 0.0
      },
      organization,
      invoiceItems,
      lineItems: Object.keys(lineItems).map((key) => lineItems[key])
    };

    return previewData;
  };

  const calculateTotalTax = () =>
    invoiceItems.length > 0
      ? invoiceItems.reduce(
          (a, b) =>
            b.rate && b.unit && b.tax?.rate
              ? a + Number(((b.tax.rate / 100) * (b.rate * b.unit)).toFixed(2))
              : a + 0,
          0
        )
      : 0;
  const getInvoiceData = () => {
    let invoice = {
      previewData: generatePreviewData(),
      invoicePayload: {
        warehouseId: formik?.values?.warehouseID?.id,
        companyId: formik?.values?.company?.id,
        number: formik?.values?.invoiceID,
        type: formik?.values?.invoiceType || null,
        startDate: formik?.values?.invoiceStartPeriod || null,
        contractType: formik?.values?.contractType || null,
        endDate: formik?.values?.invoiceEndPeriod || null,
        dueDate: formik?.values?.due || null,
        referenceId: formik?.values?.referenceID,
        notes: formik?.values?.notes,
        adjustment: formik.values.adjustment,
        discount: formik.values.discount,
        total:
          calculateTotal(true, invoiceItems) -
            Number(formik.values.discount) +
            Number(formik.values.adjustment) || 0,
        subTotal: calculateTotal(false, invoiceItems) || 0,
        paidAmount: 0,
        currency: formik.values.currency,
        status: moment(formik?.values?.due).isAfter(moment(new Date()))
          ? 'PENDING'
          : 'OVERDUE',
        userId: currentUser.id,
        billing: false,
        supportingDocument: formik.values.supportingDocument
      },
      invoiceItems,
      shipmentTableData: selected.map((s) => ({
        shipmentId: s.shipmentCustomId,
        origin: s.origin,
        destination: s.destination,
        cost: s.expenseCost,
        name: s.expenseName || 'Selling Price',
        vehicle: s.vehicle,
        referenceId: s.referenceId,
        orderId: s.orderId
      }))
    };
    return invoice;
  };

  const addInvoice = async (isDraft) => {
    let invoice = getInvoiceData();
    let endpoint = isDraft ? 'invoice-drafts' : 'invoices';
    let response;
    if (formik?.values?.images?.length) {
      let uploadIds = formik?.values?.images.filter((img) => !img.id) || [];
      if (uploadIds.length > 0) {
        uploadIds = await upload(uploadIds, endpoint);
      }
      invoice.invoicePayload.supportingDocument = [
        ...(formik?.values?.supportingDocument || []),
        ...uploadIds
      ];
    }
    try {
      setAPILoader(true);
      if (isDraft) {
        if (activeTab !== 'Preview') {
          invoice.invoicePayload.status = 'INCOMPLETE';
        } else {
          invoice.invoicePayload.status = 'READY_TO_SEND';
        }

        invoice.invoicePayload.selectedShipments = selected;
      } else {
        if (invoice.invoicePayload.total <= 0) {
          invoice.invoicePayload.status = 'PAID';
        }
      }

      response = await API.post(endpoint, invoice);

      if (id) {
        await API.delete(`invoice-drafts/${id}`);
      }
      if (isDraft) {
        toaster('success', 'Draft created successfully');
        mixpanel.track('Invoice Draft', {});
        navigate('/billing-invoice/receivables');
      }
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
    return response;
  };

  const verify = async () => {
    let success = true;
    try {
      setAPILoader(true);
      await API.get(`invoices/verify/${formik.values.invoiceID}`);
    } catch (err) {
      onError(err);
      success = false;
    } finally {
      setAPILoader(false);
    }
    return success;
  };

  const computeTotalDistance = () => {
    let distance = 0;
    if (selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        const pickup = selected[i].pickupNode?.locationLatLng;
        const dropOff = selected[i].dropOffNode?.locationLatLng;
        if (pickup && dropOff)
          distance += Number(calculateEuclideanDistance(pickup, dropOff));
      }
    }
    return distance;
  };

  const processShipmentInvoice = async () => {
    let success = true;
    let expenses = [];
    for (let shipment of selected.filter((s) => s.type == 'Expense')) {
      expenses.push({
        cost: shipment.expenseCost,
        name: shipment.expenseName,
        id: shipment.expenseId
      });
    }
    try {
      setAPILoader(true);
      const response = await API.post(`invoices/process-shipments`, {
        shipments: selected?.filter((s) => s.type == 'Selling Price'),
        expenses,
        unit: selectedUnit,
        units:
          selectedUnit == 'trips' ? selected.length : computeTotalDistance()
      });
      setInvoiceItems(response.data);
    } catch (err) {
      onError(err);
      success = false;
    } finally {
      setAPILoader(false);
    }
  };

  const verifyInvoiceItems = () => {
    let success = true;
    if (invoiceItems?.length > 0) {
      invoiceItems.forEach((item) => {
        if (!item.title.name || !item.unit || !item.rate) {
          success = false;
        }
      });
    } else {
      if (invoiceItems.length === 0) {
        success = false;
      }
    }
    return success;
  };

  const changeTab = async (tab) => {
    setActiveTab(tab);
  };

  const nextTab = async () => {
    if (activeTab === 'Info') {
      const res = await verify();
      if (formik.values.invoiceType == 'Logistics') {
        return res ? 'Shipment Selection' : 'Info';
      } else {
        return res ? 'Invoice Form' : 'Info';
      }
    } else if (activeTab === 'Shipment Selection') {
      return 'Invoice Form';
    } else {
      const res = verifyInvoiceItems();
      if (!res) {
        if (invoiceItems.length === 0) {
          toaster('warning', 'Invoice should not be empty');
        } else {
          toaster('warning', 'Please fill out all fields');
        }
        return 'Invoice Form';
      } else return 'Preview';
    }
  };

  const handleChange = (name, val) => {
    formik.setFieldValue(name, val);
  };

  const getInvoiceID = async () => {
    try {
      setAPILoader(true);
      const number = await API.get(`/invoices/invoice-number`, {
        params: { type }
      });
      if (!id) {
        handleChange('invoiceID', number?.data);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getOrganization = async () => {
    try {
      setAPILoader(true);
      const res = await API.get(`organizations/${subdomain}/check`);
      formik.setFieldValue(
        'currency',
        res.organization?.defaultCurrency?.toUpperCase() || 'PKR'
      );
      formik.setFieldValue('notes', res.organization?.notes);
      setOrganization(res.organization);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const preview = async (url) => {
    try {
      setAPILoader(true);
      const response = await API.post(`invoices/email-preview`, {
        pdfLink: '',
        companyName: formik?.values?.company?.name,
        invoiceNumber: formik.values.invoiceID,
        organization
      });
      setEmailPreview(response.html);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const deleteItem = async () => {
    try {
      setAPILoader(true);
      await API.post(`invoice-draft-items`, { items: deletedItems });
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const fetchDraft = async (url) => {
    try {
      setAPILoader(true);
      const { invoice, files } = await API.get(`invoice-drafts/${id}`);
      formik.setValues({
        invoiceType: 'Logistics' || '',
        contractType: invoice.contractType || '',
        company: invoice.Company || {},
        warehouseID: invoice.Warehouse || {},
        supportingDocument: invoice.supportingDocument || [],
        billingAddress: '',
        customersNTN: '',
        invoiceID: invoice.number,
        due: invoice.dueDate || null,
        invoiceStartPeriod: invoice.startDate,
        invoiceEndPeriod: invoice.endDate,
        referenceID: invoice.referenceId,
        discount: invoice.discount,
        status: invoice.status,
        currency: invoice.currency,
        images: files,
        notes: invoice.notes
      });

      if (invoice.type) {
        setDisableType(true);
      }

      if (!invoice.type) {
        setEditableInvoiceType(true);
      }

      if (invoice.selectedShipments) {
        let parsed = invoice.selectedShipments;
        setSelected(parsed);
      }

      const { InvoiceDraftItems } = invoice;
      let newItems =
        InvoiceDraftItems.length > 0
          ? InvoiceDraftItems.map((item) => ({
              title: item.InvoiceTitle,
              tax: item.Tax,
              unitType: item.UnitType,
              rate: item.rate,
              unit: item.unit,
              totalAmount: item.totalAmount,
              company: item.Company,
              id: item.id
            }))
          : [];
      setInvoiceItems(newItems);
      setDraftItemLength(newItems.length);
      setIsDraft(true);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const updateDraft = async () => {
    let invoice = getInvoiceData();
    let response;
    invoice.invoiceItems = invoiceItems.slice(draftItemLength);
    invoice.prevInvoiceItems = invoiceItems.slice(0, draftItemLength);
    if (formik?.values?.images?.length) {
      let uploadIds = formik?.values?.images.filter((img) => !img.id) || [];
      if (uploadIds.length > 0) {
        uploadIds = await upload(uploadIds, 'invoice-drafts');
      }
      invoice.invoicePayload.supportingDocument = [
        ...(formik?.values?.supportingDocument || []),
        ...uploadIds
      ];
    }
    try {
      setAPILoader(true);
      if (activeTab !== 'Preview' && formik.values.status !== 'READY_TO_SEND') {
        invoice.invoicePayload.status = 'INCOMPLETE';
      } else {
        invoice.invoicePayload.status = 'READY_TO_SEND';
      }

      invoice.invoicePayload.selectedShipments = selected;

      response = await API.put(`invoice-drafts/${id}`, invoice);
      await deleteItem();
      toaster('success', 'Draft updated successfully');
      navigate('/billing-invoice/receivables');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
    return response;
  };

  const getInvoiceTitles = async () => {
    try {
      setAPILoader(true);
      const invoiceTitles = await API.get(`invoice-titles/group-by-type`);
      setInvoiceTitles(invoiceTitles);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getGroupedTypes = async () => {
    try {
      setAPILoader(true);
      const units = await API.get(`unit-types`);
      setUnitTypes(units.unitTypes || []);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const setAdjustment = async () => {
    try {
      setAPILoader(true);
      const adjustment = await API.get(`invoices/adjustment`, {
        params: { companyId: formik.values.company.id, billing: false }
      });
      formik.setFieldValue(
        'adjustment',
        Number(adjustment?.adjustment?.toFixed(2))
      );
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getOrganization();
    getInvoiceID();
    getInvoiceTitles();
    setCurrentPageTitle('Create Invoice');
  }, []);

  useEffect(() => {
    if (id) {
      fetchDraft();
    }
  }, [id]);

  useEffect(() => {
    if (formik.values.company?.id) {
      setAdjustment();
      if (formik.values.company.paymentTerms) {
        formik.setFieldValue(
          'due',
          addDaysToCurrentDate(formik.values.company.paymentTerms)
        );
      }
    }
  }, [formik.values.company]);

  useEffect(() => {
    if (selected.length > 0) {
      processShipmentInvoice();
    } else {
      setInvoiceItems([]);
    }
  }, [selected]);

  const handleSubmit = async () => {
    await preview();
    setEmailPopUp(true);
  };

  const createInvoice = async (isEmailInvoice) => {
    try {
      setAPILoader(true);
      const response = await addInvoice();
      setRes(response.invoice);
      if (isEmailInvoice) {
        await API.post(`invoices/email`, {
          invoiceId: response.invoice.id,
          companyId: response.invoice.companyId
        });
        toaster('success', 'Invoice sent successfully');
      } else {
        toaster('success', 'Invoice generated successfully');
      }
      setEmailPopUp(false);
      mixpanel.track('Invoice Sent', {});
      navigate('/billing-invoice/receivables');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const getPendingShipments = async () => {
    try {
      setAPILoader(true);
      const response = await API.get(`orders/shipments/pending-invoices`, {
        params: {
          companyId: formik.values.company?.id,
          page,
          search: shipmentsearch
        }
      });
      setPendingShipments(response.shipments || []);
      setPages(response.pages);
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const generateInvoiceItems = (type) => {
    if (invoiceTitles) {
      if (type === 'Warehousing') {
        return invoiceTitles.warehousing?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      } else if (type === 'Logistics') {
        return invoiceTitles.logistics?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      } else if (type === 'Technology') {
        return invoiceTitles.technology?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      }
    }
    return [];
  };

  const onClickPdf = () => {
    const link = document.createElement('a');
    link.href = res.pdf;
    link.download = `${res.number}.pdf`;
    link.click();
  };

  useEffect(() => {
    if (!id && formik?.values?.invoiceType) {
      if (formik.values.invoiceType === 'Warehousing') {
        setInvoiceItems(generateInvoiceItems('Warehousing'));
      } else if (
        formik.values.invoiceType === 'Logistics' &&
        !invoiceBillingFlag
      ) {
        setInvoiceItems(generateInvoiceItems('Logistics'));
      } else if (formik.values.invoiceType === 'Technology') {
        setInvoiceItems(generateInvoiceItems('Technology'));
      } else {
        setInvoiceItems([]);
      }
    } else if (id && editableInvoiceType) {
      if (formik.values.invoiceType === 'Warehousing') {
        setInvoiceItems(generateInvoiceItems('Warehousing'));
      } else if (
        formik.values.invoiceType === 'Logistics' &&
        !invoiceBillingFlag
      ) {
        setInvoiceItems(generateInvoiceItems('Logistics'));
      } else if (formik.values.invoiceType === 'Technology') {
        setInvoiceItems(generateInvoiceItems('Technology'));
      } else {
        setInvoiceItems([]);
      }
    }
  }, [formik?.values?.invoiceType]);

  useEffect(() => {
    if (formik.values.invoiceType) {
      getGroupedTypes();
    }
  }, [formik.values.invoiceType]);

  useEffect(() => {
    if (formik.values.company?.id) getPendingShipments();
  }, [formik.values.company, page, shipmentsearch]);

  useEffect(() => {
    if (selected.length > 0) {
      let shipments = [];
      let shipmentIds = [];

      for (let i = 0; i < selected.length; i++) {
        if (shipmentIds.indexOf(selected[i].shipmentId) == -1) {
          shipments.push(selected[i]);
          shipmentIds.push(selected[i].shipmentId);
        }
      }
      let files = [];
      for (let i = 0; i < shipments.length; i++) {
        if (selected[i].files?.length > 0) {
          files.push(...selected[i].files);
        }
      }

      formik.setFieldValue('images', files);
    }
  }, [selected]);

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.parentContainer}>
        <Grid item container>
          <Grid item xs={activeTab !== 'Preview' ? 9 : 10}>
            <Grid item container>
              <Grid item xs={12}>
                <CustomTabs
                  changeTab={(tab) => {
                    const steps = [
                      'Info',
                      'Shipment Selection',
                      'Invoice Form',
                      'Preview'
                    ];
                    const currentStepIndex = steps.indexOf(activeTab);
                    const targetStepIndex = steps.indexOf(tab);
                    if (targetStepIndex <= currentStepIndex) {
                      setActiveTab(tab);
                    }
                  }}
                  activeStep={activeTab}
                  steps={
                    invoiceBillingFlag &&
                    formik.values.invoiceType === 'Logistics'
                      ? [
                          'Info',
                          'Shipment Selection',
                          'Invoice Form',
                          'Preview'
                        ]
                      : ['Info', 'Invoice Form', 'Preview']
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {activeTab === 'Info' && (
                  <BasicInfo
                    formik={formik}
                    handleChange={(name, val) => handleChange(name, val)}
                    billing={false}
                    invoiceStartError={invoiceStartError}
                    disableType={disableType}
                    setSourceType={setSourceType}
                  />
                )}
                {activeTab === 'Shipment Selection' && (
                  <ShipmentListing
                    rows={pendingShipments?.flatMap((s) => {
                      const expenseRows =
                        s.Expenses?.map((expense) => ({
                          files: s.files,
                          id: `${s.id}-${expense.id}`,
                          shipmentId: s.id,
                          shipmentCustomId: s.customId,
                          orderId: s.Order.customId,
                          origin: s.Order.pickupNode?.name,
                          destination: s.Order.dropOffNode?.name,
                          expenseName: expense.name,
                          expenseCost: expense.cost || 0,
                          expenseId: expense.id,
                          referenceId: s.referenceId || '-',
                          podStatus: s.subStatus?.name,
                          isDisabled:
                            !expense.cost ||
                            s.status?.key !== 'COMPLETED' ||
                            s.subStatus?.key == 'POD_PENDING',
                          pickupNode: s.Order.pickupNode,
                          dropOffNode: s.Order.dropOffNode,
                          driver:
                            s.Driver?.name ||
                            s.ShipmentLegs?.[0]?.Driver?.name ||
                            s.driverName,
                          vehicle:
                            s.Vehicle?.registrationNumber ||
                            s.ShipmentLegs?.[0]?.Vehicle?.registrationNumber ||
                            s.vehicleNumber,
                          type: 'Expense'
                        })) || [];

                      // Add an additional row for the selling price
                      const sellingPriceRow =
                        s?.sellingPrice && !s?.isSellingPriceInvoiced
                          ? [
                              {
                                files: s.files,
                                shipmentId: s.customId,
                                id: `${s.id}-selling-price`,
                                shipmentId: s.id,
                                shipmentCustomId: s.customId,
                                orderId: s.Order.customId,
                                origin: s.Order.pickupNode?.name,
                                destination: s.Order.dropOffNode?.name,
                                expenseName: 'Selling Price',
                                expenseCost: s.sellingPrice,
                                expenseId: null,
                                referenceId: s.referenceId || '-',
                                podStatus: s.subStatus?.name,
                                isDisabled:
                                  !s.sellingPrice ||
                                  s.status?.key !== 'COMPLETED' ||
                                  s.subStatus?.key == 'POD_PENDING',
                                pickupNode: s.Order.pickupNode,
                                dropOffNode: s.Order.dropOffNode,
                                driver:
                                  s.Driver?.name ||
                                  s.ShipmentLegs?.[0]?.Driver?.name ||
                                  s.driverName,
                                vehicle:
                                  s.Vehicle?.registrationNumber ||
                                  s.ShipmentLegs?.[0]?.Vehicle
                                    ?.registrationNumber ||
                                  s.vehicleNumber,
                                type: 'Selling Price'
                              }
                            ]
                          : [];

                      return [...sellingPriceRow, ...expenseRows];
                    })}
                    search={shipmentsearch}
                    setSearch={setShipmentSearch}
                    setSelected={setSelected}
                    selected={selected}
                    page={page}
                    pages={pages}
                    setPage={setPage}
                    selectedUnit={selectedUnit}
                    setSelectedUnit={setSelectedUnit}
                    formik={formik}
                    isBilling={false}
                  />
                )}
                {activeTab === 'Invoice Form' && (
                  <InvoiceForm
                    handleChange={(name, val) => handleChange(name, val)}
                    formik={formik}
                    invoiceItems={invoiceItems}
                    setInvoiceItems={setInvoiceItems}
                    type={type}
                    isDraft={!!id}
                    setDeletedItems={(item) =>
                      setDeletedItems([...deletedItems, item])
                    }
                    unitTypes={unitTypes}
                  />
                )}
                {activeTab === 'Preview' && (
                  <Preview
                    data={generatePreviewData()}
                    setHtml={(data) => setHtml(data)}
                    type={type}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {activeTab !== 'Preview' && (
            <Grid item xs={3}>
              <InvoiceSummary formik={formik} invoiceItems={invoiceItems} />
            </Grid>
          )}
        </Grid>
      </div>

      <div className={classes.actionButtons}>
        <div>
          {checkPermission(currentUser, 'OPS_INVOICE_SAVE_AS_DRAFT') && (
            <Button
              onClick={async () => {
                if (!id) {
                  await addInvoice(true);
                } else {
                  await updateDraft();
                }
              }}
              variant="outlined"
              color="primary"
              className={classes.draftBtn}
              style={{ marginLeft: sidebar ? 360 : 180 }}
            >
              Save Draft
            </Button>
          )}
        </div>
        <div className={classes.nextCancelButtons}>
          {!res?.id && (
            <Button
              onClick={() => navigate('/billing-invoice/receivables')}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          )}
          {activeTab !== 'Preview' ? (
            <Button
              type="submit"
              onClick={() => {
                formik.handleSubmit();
                setFirstSubmit(true);
              }}
              variant="contained"
              className={classes.nextBtn}
              color="primary"
            >
              Next
            </Button>
          ) : (
            checkPermission(currentUser, 'OPS_INVOICE_SAVE_AND_SEND') && (
              <Button
                onClick={async () => {
                  if (!res?.id) handleSubmit();
                  else onClickPdf();
                }}
                variant="contained"
                className={classes.nextBtn}
                color="primary"
              >
                Create Invoice
              </Button>
            )
          )}
        </div>
      </div>

      <Dialog
        onClose={() => setEmailPopUp(false)}
        open={emailPopUp}
        className={classes.emailPopUp}
        BackdropProps={{ style: { backgroundColor: '#0B1940' } }}
      >
        <DialogTitle>
          <div style={{ width: '97%', margin: 'auto' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={9}>
                <Typography className={classes.emailPopUpTitle}>
                  Invoice will be sent to {formik.values.company.name}
                </Typography>
                {formik.values.company.email && (
                  <Typography>Email: {formik.values.company.email}</Typography>
                )}
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={1} justifyContent="flex-end">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CloseIcon
                    onClick={() => {
                      setEmailPopUp(false);
                    }}
                    style={{ cursor: 'pointer' }}
                    fontSize="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogTitle>
        <DialogContent style={{ margin: '1rem 0' }}>
          {emailPreview && (
            <div
              dangerouslySetInnerHTML={{ __html: emailPreview }}
              style={{
                width: '97%',
                padding: '1rem',
                margin: 'auto',
                border: '1px solid #67718C',
                boxSizing: 'border-box'
              }}
              id="email-view"
            ></div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            width: '97%',
            margin: 'auto',
            paddingRight: '1.2rem',
            boxSizing: 'border-box'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setEmailPopUp(false)}
            className={classes.emailPopUpCancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => createInvoice(false)}
            className={classes.emailPopUpAddButton}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => createInvoice(true)}
            className={classes.emailPopUpAddButton}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
