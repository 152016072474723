import React, { useContext } from 'react';
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import FormContext from './OrganizationFormContext';
import FormikInputField from '../../../atomicComponents/FormikInputField';
import CancelButton from '../../../components/CancelButton';
import { organizationIntegrationsValidationSchema } from './formikFields';

const OrganizationIntegrationsForm = () => {
  const {
    organizationIntegrations,
    setOrganizationIntegrations,
    next,
    prev,
    handleClose,
    reset
  } = useContext(FormContext);

  const formik = useFormik({
    initialValues: {
      injazIntegration: organizationIntegrations?.injaz?.enabled || false,
      injazEmail: organizationIntegrations?.injaz?.credentials?.email || '',
      injazPassword:
        organizationIntegrations?.injaz?.credentials?.password || ''
    },
    validationSchema: organizationIntegrationsValidationSchema,
    onSubmit: (values) => {
      const updatedIntegrations = {
        ...organizationIntegrations,
        injaz: {
          enabled: values.injazIntegration,
          credentials: {
            email: values.injazEmail,
            password: values.injazPassword
          }
        }
      };
      setOrganizationIntegrations(updatedIntegrations);
      next();
    }
  });

  return (
    <Grid container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Integrations</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.injazIntegration}
                  onChange={(e) => {
                    formik.setFieldValue('injazIntegration', e.target.checked);
                    if (!e.target.checked) {
                      formik.setFieldValue('injazEmail', '');
                      formik.setFieldValue('injazPassword', '');
                    }
                  }}
                  name="injazIntegration"
                  color="primary"
                />
              }
              label="Injaz Integration"
            />
          </Grid>

          {formik.values.injazIntegration && (
            <>
              <Grid item xs={12}>
                <FormikInputField
                  name="injazEmail"
                  type="email"
                  formik={formik}
                  title="Injaz User Email"
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInputField
                  name="injazPassword"
                  type="password"
                  formik={formik}
                  title="Injaz Password"
                  required={true}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
                marginBottom: '20px',
                gap: '10px'
              }}
            >
              <CancelButton
                handleClose={handleClose}
                setValidation={() => {}}
                resetStates={reset}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={prev}
                style={{ minWidth: '100px' }}
              >
                Back
              </Button>
              <Tooltip title="Next">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ minWidth: '100px' }}
                >
                  Next
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default OrganizationIntegrationsForm;
