/*eslint-disable*/
import Popup from '../../../core-components/atoms/Popup';
import Button from '../../../core-components/atoms/Button';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { SharedContext } from '../../../utils/common';
import VehicleForm from './VehicleForm';
import { upload } from '../../../utils/upload';

const VehiclePopup = ({
  open,
  setOpen,
  organizationType,
  selectedVehicle,
  selectedCarrier,
  selectedCar,
  refetch
}) => {
  const { setAPILoader } = useContext(SharedContext);
  const [submitting, setSubmitting] = useState(false);

  const addSubmitHandler = async (values) => {
    setAPILoader(true);
    setSubmitting(true);

    let runningPaperId;
    if (values?.RunningPaper && !values.RunningPaper.id) {
      runningPaperId = await upload([values?.RunningPaper], 'vehicle');
    } else {
      if (!values.RunningPaper) {
        runningPaperId = null;
      } else {
        runningPaperId = values.RunningPaper.id;
      }
    }

    let routePermitId;
    if (values?.RoutePermit && !values.RoutePermit.id) {
      routePermitId = await upload([values?.RoutePermit], 'vehicle');
    } else {
      if (!values.RoutePermit) {
        routePermitId = null;
      } else {
        routePermitId = values.RoutePermit.id;
      }
    }

    let photoId;
    if (values?.Photo && !values.Photo.id) {
      photoId = await upload([values?.Photo], 'vehicle');
    } else {
      if (!values.Photo) {
        photoId = null;
      } else {
        photoId = values.Photo.id;
      }
    }

    const payload = {
      companyId: formik.values?.carrierId,
      registrationNumber: formik.values?.registrationNumber,
      carId: formik.values?.vehicleType?.id,
      runningPaperId,
      routePermitId,
      trackingEnabled: formik.values?.trackingEnabled,
      photoId,
      ...(values?.driver && {
        driverId: values?.driver.id
      }),
      ...(values?.maintainenceMileage && {
        maintainenceMileage: values?.maintainenceMileage
      }),
      ...(values?.lastMaintainenceMileage && {
        lastMaintainenceMileage: values?.lastMaintainenceMileage
      }),
      ...(values?.mileage && { mileage: values?.mileage }),
      ...(values?.avgSpeed && { avgSpeed: values?.avgSpeed }),
      ...(values?.distance && { distance: values?.distance }),
      ...(values?.trackingLink && { trackingLink: values?.trackingLink }),
      ...(values?.trackerId && { trackerId: values?.trackerId }),
      ...(values?.owner && { owner: values?.owner }),
      ...(values?.supplier && { supplier: values?.supplier })
    };

    try {
      if (!selectedVehicle) await API.post(`vehicles`, payload);
      else await API.put(`vehicles/${selectedVehicle.id}`, payload);
      toaster(
        'success',
        !selectedVehicle
          ? 'Vehicle has been created.'
          : 'Vehicle updated successfully.'
      );
      await refetch();
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
      setSubmitting(false);
    }
  };

  const handleClose = async () => {
    await formik.resetForm();
    setOpen(false);
  };

  let valSchema;
  valSchema = Yup.object().shape({
    carrierId: Yup.string().required('Carrier is required'),
    registrationNumber: Yup.string().required(
      'Registration Number is required'
    ),
    carId: Yup.string().required('Car is required')
  });

  const initialValues = {};

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: valSchema,
    onSubmit: addSubmitHandler
  });

  const getCarrier = async (id) => {
    try {
      const vendor = await API.get(`companies/${id}`, {});
      return vendor;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getVehicleType = async (id) => {
    try {
      const vehicleType = await API.get(`vehicles/${id}`, {});
      return vehicleType;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(async () => {
    if (selectedVehicle) {
      formik.setValues({ ...selectedVehicle });
      if (selectedVehicle?.Vendor?.id) {
        const carrier = await getCarrier(selectedVehicle?.Vendor?.id);
        formik.setFieldValue('carrier', carrier?.company);
        formik.setFieldValue('carrierId', selectedVehicle?.Vendor?.id);
      }
      if (selectedVehicle?.Car?.VehicleType?.id) {
        const vehicleType = await getVehicleType(selectedVehicle?.id);
        formik.setFieldValue('vehicle', vehicleType?.vehicle);
        formik.setFieldValue('vehicleType', vehicleType?.vehicle?.Car);
      }
      if (selectedVehicle?.Driver) {
        formik.setFieldValue('driver', selectedVehicle?.Driver);
      }
    }
  }, [selectedVehicle]);

  useEffect(() => {
    if (selectedCarrier && selectedCarrier?.id) {
      formik.setFieldValue('carrier', selectedCarrier);
      formik.setFieldValue('carrierId', selectedCarrier?.id);
    }
  }, [selectedCarrier]);

  useEffect(() => {
    if (selectedCar && selectedCar?.id) {
      formik.setFieldValue('vehicleType', selectedCar);
    }
  }, [selectedCar]);

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={
        <VehicleForm
          open
          openPopup={open}
          organizationType={organizationType}
          formik={formik}
        />
      }
      title={selectedVehicle ? `Edit Vehicle` : `Add Vehicle`}
      headerClass="px-8 pt-8 pb-4"
      titleClass="text-primaryBlue text-lg font-semibold"
      noPadding={true}
      closeIconSize={24}
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '666px',
          maxWidth: '780px',
          width: '780px'
        }
      }}
      dialogContentClasses={'dark-scrollbar'}
      onClose={() => {
        formik.resetForm();
      }}
      actions={
        <div className="flex justify-end gap-2 pt-3 pb-6 px-6">
          <Button label="Cancel" onClick={handleClose} variant="text" />
          <Button
            label={selectedVehicle ? 'Update Vehicle' : 'Add Vehicle'}
            className="rounded"
            onClick={async () => {
              await addSubmitHandler(formik.values);
            }}
            variant="primary"
            size="large"
            disabled={
              !(
                formik.values?.carrierId &&
                formik.values?.registrationNumber &&
                formik.values?.vehicleType
              ) || submitting
            }
          />
        </div>
      }
    />
  );
};

export default VehiclePopup;
