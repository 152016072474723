import React, { useEffect, useState } from 'react';
import {
  Stepper,
  StepLabel,
  Step,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';

import { Provider } from './OrganizationFormContext';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import OwnerDetailsForm from './OwnerDetailsForm';
import OrganizationBankDetailsForm from './OrganizationBankDetailsForm';
import { upload } from '../../../utils/upload';
import OrganizationPermissionForm from './OrganizationPermissionForm';
import { constants } from './constants';
import OrganizationIntegrationsForm from './OrganizationIntegrationsForm';

const organizationDetailsInitialState = {
  name: '',
  subdomain: '',
  address: '',
  industry: '',
  primaryColor: '',
  secondaryColor: '',
  logo: null,
  email: '',
  active: false,
  type: '',
  lightLogo: null,
  notifications: false,
  TMS: false,
  defaultCurrency: '',
  country: '',
  landTrackingEnabled: false,
  customUOM: false,
  sms: false,
  landedCost: false,
  kpis: Object.keys(constants.INTELLIGENCE_KPIS_VALUES).map((key, id) => {
    return {
      key,
      enabled: false,
      name: constants.INTELLIGENCE_KPIS_VALUES[key],
      id
    };
  })
};

const organizationBankDetailsInitialState = {
  ntn: '',
  bankName: '',
  bankBranch: '',
  accountTitle: '',
  accountNumber: '',
  iban: '',
  swiftCode: ''
};

const ownerDetailsInitialState = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  phone: '',
  username: '',
  active: true
};

const organizationIntegrationsInitialState = {
  injaz: {
    enabled: false,
    credentials: {
      email: '',
      password: ''
    }
  }
};

const AddOrganizationForm = ({
  addOrganization,
  open,
  handleClose,
  getOrganizationByName,
  getOrganizationBySubdomain,
  clickedAlready,
  organization,
  edit
}) => {
  const [organizationDetails, setOrganizationDetails] = useState(
    organizationDetailsInitialState
  );
  const [organizationBankDetails, setOrganizationBankDetails] = useState(
    organizationBankDetailsInitialState
  );
  const [ownerDetails, setOwnerDetails] = useState(ownerDetailsInitialState);
  const [organizationIntegrations, setOrganizationIntegrations] = useState(
    organizationIntegrationsInitialState
  );

  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 4) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <OrganizationDetailsForm
            getOrganizationByName={getOrganizationByName}
            getOrganizationBySubdomain={getOrganizationBySubdomain}
            edit={edit}
          />
        );
      case 1:
        return (
          <OrganizationPermissionForm
            getOrganizationByName={getOrganizationByName}
            getOrganizationBySubdomain={getOrganizationBySubdomain}
            edit={edit}
            newOrg={!organization}
          />
        );
      case 2:
        return (
          <OrganizationBankDetailsForm
            clickedAlready={clickedAlready}
            edit={edit}
          />
        );
      case 3:
        return <OrganizationIntegrationsForm />;
      case 4:
        return (
          <OwnerDetailsForm
            onSubmit={handleSubmit}
            clickedAlready={clickedAlready}
            edit={edit}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmit = async (ownerValues) => {
    let organizationData = {};

    if (!organization) {
      organizationData = {
        ...organizationDetails,
        ...organizationBankDetails
      };
    } else {
      let { ...rest } = organizationDetails;
      organizationData = {
        ...rest,
        ...organizationBankDetails
      };
    }

    if (organizationData.logo && typeof organizationData.logo != 'number') {
      let file = await upload([organizationData.logo], 'organization');
      organizationData.logo = file[0];
    }
    if (
      organizationData.lightLogo &&
      typeof organizationData.lightLogo === 'object'
    ) {
      let file = await upload([organizationData.lightLogo], 'organization');
      organizationData.lightLogo = file[0];
    }

    const ownerPayload = organization
      ? {
          id: organization.User.id,
          ...ownerValues,
          isActive: ownerValues.active
        }
      : { ...ownerValues, isActive: ownerValues.active };

    const success = await addOrganization(
      organizationData,
      ownerPayload,
      organizationIntegrations
    );

    if (success) {
      reset();
    }
  };

  const steps = [
    'Organization details',
    'Organization permissions',
    'Organization Bank Details',
    'Integrations',
    'Owner details'
  ];

  const reset = () => {
    setOrganizationDetails(organizationDetailsInitialState);
    setOrganizationBankDetails(organizationBankDetailsInitialState);
    setOrganizationIntegrations(organizationIntegrationsInitialState);
    setOwnerDetails(ownerDetailsInitialState);
    setCurrentStep(0);
  };

  useEffect(() => {
    if (organization) {
      setOrganizationDetails({
        name: organization.name,
        subdomain: organization.subdomain,
        address: organization.address,
        industry: organization.industry,
        email: organization.email,
        primaryColor: organization.primaryColor,
        secondaryColor: organization.secondaryColor,
        logo: organization.logo,
        lightLogo: organization.lightLogo,
        active: organization.active,
        type: organization.type,
        notifications: organization.notifications,
        TMS: organization.TMS,
        defaultCurrency: organization.defaultCurrency,
        country: organization?.country,
        landTrackingEnabled: organization?.landTrackingEnabled,
        sms: organization.sms || false,
        landedCost: organization?.landedCost || false,
        customUOM: organization?.customUOM || false
      });

      setOrganizationIntegrations(
        organization.integrations || organizationIntegrationsInitialState
      );

      setOrganizationBankDetails({
        ntn: organization.ntn,
        bankName: organization.bankName,
        bankBranch: organization.bankBranch,
        accountTitle: organization.accountTitle,
        accountNumber: organization.accountNumber,
        iban: organization.iban,
        swiftCode: organization.swiftCode
      });
      setOwnerDetails({
        firstName: organization.User.firstName,
        lastName: organization.User.lastName,
        email: organization.User.email,
        phone: organization.User.phone,
        username: organization.User.userName,
        active: organization.User.isActive
      });
    }
  }, [organization]);

  return (
    <Provider
      value={{
        organizationDetails,
        setOrganizationDetails,
        organizationIntegrations,
        setOrganizationIntegrations,
        next,
        prev,
        currentStep,
        ownerDetails,
        setOwnerDetails,
        organizationBankDetails,
        setOrganizationBankDetails,
        handleClose,
        reset
      }}
    >
      <Dialog
        open={open}
        onClose={() => {
          if (organization) {
            handleClose();
            reset();
          } else {
            handleClose();
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle disableTypography className="title">
          {organization ? 'Update Organization' : 'Add Organization'}
        </DialogTitle>
        <Stepper activeStep={currentStep}>
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <DialogContent>{renderStep(currentStep)}</DialogContent>
      </Dialog>
    </Provider>
  );
};
export default AddOrganizationForm;
