/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  InputAdornment
} from '@mui/material';
import FilterIcon from '../../../assets/icons/filterDarkIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import PopupButton from '../../../core-components/molecules/PopupButton';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  listItem: {
    '&:hover': {
      backgroundColor: '#F1F8FF'
    },
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    fontSize: '10px'
  },
  popOver: {
    maxHeight: '350px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      borderRadius: '4px'
    }
  },
  search: {
    margin: '1rem auto',
    width: '90%',
    display: 'block'
  },
  paper: {
    pointerEvents: 'auto',
    width: '252px',
    border: '1px solid #E7E6E6',
    marginTop: '12px',
    marginRight: '16px'
  },
  subMenuPaper: {
    pointerEvents: 'auto',
    border: '1px solid #E7E6E6',
    height: '300px'
  }
}));

const BatchesFilter = ({
  open,
  setOpen,
  values,
  inventoryIds,
  batchIds,
  setValues,
  callBack
}) => {
  const [search, setSearch] = useState('');
  const [batches, setBatches] = useState([]);
  const classes = useStyles();
  const { setAPILoader } = useContext(SharedContext);

  const fetchBatches = async () => {
    setAPILoader(true);
    try {
      const res = await API.get('inventory-details', {
        params: { inventoryIds, batchIds, batchNumbers: search, limit: 10 }
      });
      const batchSet = new Set(
        res.data.map((item) => item.batchNumber).filter(Boolean)
      );
      setBatches(Array.from(batchSet));
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, [search]);

  const handleCheckboxChange = (option, checked) => {
    const newValues = { ...values };
    if (checked) {
      newValues[option] = option;
    } else {
      delete newValues[option];
    }
    setValues(newValues);
    callBack();
  };

  return (
    <div>
      <PopupButton
        setOpen={setOpen}
        open={open}
        btn={
          <Button
            onClick={() => setOpen(!open)}
            variant="transparent"
            icon={<img src={FilterIcon} alt="Filter" className="mr-[6px]" />}
            labelClass="font-medium text-xs"
            iconClass="h-[14px] w-[14px] mr-1"
            label="All Batches"
            className="py-3 px-4 ml-2 h-10 rounded border-solid"
          />
        }
        content={
          <List style={{ width: '300px' }}>
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
            {batches
              ?.filter((b) => !!b)
              .map((option) => (
                <ListItem key={option} className={classes.listItem}>
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange(option, e.target.checked)
                    }
                    checked={Boolean(values[option])}
                  />
                  <span className="text-sm">{option}</span>
                </ListItem>
              ))}
          </List>
        }
      />
    </div>
  );
};

export default BatchesFilter;
