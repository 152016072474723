/*eslint-disable*/
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import moment from 'moment';
import EditIcon from '@material-ui/icons/EditOutlined';
import DownloadIcon from '../../../assets/icons/downloadFileIcon.svg';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import FileDownload from 'js-file-download';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import ExpenseEditListing from '../../multiModalShipment/expense/ExpenseEditListing';
import AttachmentIcon from '../../../assets/icons/attachment_icon.svg';
import {
  calculateETA,
  calculateTimeDifference,
  calculateEuclideanDistance,
  getCurrencySymbol,
  isArabCountry,
  SharedContext
} from '../../../utils/common';
import RightArrowIcon from '../../../assets/icons/rightArrow.svg';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import { decimalDisplay } from '../../../utils/common';
import FLAGS from '../../../constants/featureFlags';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import WaseeqaPopup from './WaseeqaNumberPopup';
import PodPopup from './PodPopup';

const ShipmentOrderDetail = ({
  order,
  shipment,
  openExpense,
  setOpenExpense,
  refetch,
  refetchExpense,
  setRefetchExpense,
  waseeqaPopup,
  setWaseeqaPopup,
  podOpen,
  setPodOpen
}) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [expenses, setExpenses] = useState([]);
  const [firstLoadExpense, setFirstLoadExpense] = useState(true);
  const [loader, setLoader] = useState(false);

  const [pageCountFile, setPageCountFile] = useState(1);
  const [pageFile, setPageFile] = useState(1);
  const [filesLoading, setFilesLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const uploadRef = useRef(null);
  const { setAPILoader, organization } = useContext(SharedContext);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [distance, setDistance] =  useState(0)
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    if (firstLoadExpense) {
      if (expenses.length <= 0 && order?.id && shipment?.id) {
        setLoader(true);
        getDelayedOrderExpense(1, [], order, shipment);
      }

      if (expenses.length > 0) {
        setLoader(false);
      }
      setFirstLoadExpense(false);
    }
  }, [expenses, order, shipment]);

  useEffect(() => {
    if (refetchExpense) {
      getDelayedOrderExpense(1, [], order, shipment);
      setRefetchExpense(false);
    }
  }, [refetchExpense]);

  const getDelayedOrderExpense = useCallback(
    debounce(
      async (page, oldData, orderDetail, shipmentDetail) => {
        getOrderExpense(page, oldData, orderDetail, shipmentDetail);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getOrderExpense = async (
    page,
    oldData = [],
    orderDetail,
    shipmentDetail
  ) => {
    try {
      const res = await API.get(`expenses`, {
        params: {
          page: page,
          limit: 8,
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {}),
          ...(shipmentDetail?.id ? { shipmentId: shipmentDetail?.id } : {})
        }
      });
      const newArr = res?.data;
      setExpenses([...oldData, ...newArr]);
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    } finally {
      setLoader(false);
      setAPILoader(false);
    }
  };

  const exportExpense = async () => {
    const response = await API.get(`/expenses/export`, {
      responseType: 'blob',
      params: { orderId: order?.id, shipmentId: shipment?.id }
    });
    FileDownload(response, `Expenses ${moment().format('DD-MM-yyyy')}.xlsx`);
  };

  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  useEffect(() => {
    if (firstLoad) {
      if (files.length <= 0 && order?.id) {
        setFilesLoading(true);
        getDelayedFiles(1, [], order, shipment);
      } else if (files.length > 0) {
        setFilesLoading(false);
      }
      setFirstLoad(false);
    }
  }, [files, order, shipment]);

  const getDelayedFiles = useCallback(
    debounce(
      async (page, oldData, orderDetail, shipmentDetail) => {
        getUserFiles(page, oldData, orderDetail, shipmentDetail);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserFiles = async (
    page,
    oldData = [],
    orderDetail,
    shipmentDetail
  ) => {
    try {
      const res = await API.get(`files`, {
        params: {
          page: page,
          limit: 20,
          columns: ['originalName'],
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {}),
          ...(shipmentDetail?.id ? { shipmentId: shipmentDetail?.id } : {})
        }
      });
      const newArr = res?.data;
      setFiles([...oldData, ...newArr]);
      setPageCountFile(res?.pages);
      setFilesLoading(false);
    } catch (error) {
      onError(error);
      setFilesLoading(false);
      setAPILoader(false);
    }
  };

  const uploadExpenseFile = (val) => {
    setSelectedExpense(val);
    uploadRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) handleFileUpload(selectedFile, selectedExpense);
  }, [selectedFile, selectedExpense]);

  const handleFileUpload = async (e, expense) => {
    setAPILoader(true);
    const files = e.target.files;
    const filesArr = [];
    for (let file of files) {
      filesArr.push(file);
    }
    try {
      let uploadedData = await upload(
        filesArr.filter((file) => !file?.id),
        'orders'
      );
      if (uploadedData) {
        const payload = {
          name: expense?.name,
          cost: expense?.cost,
          shipmentId: expense?.shipmentId,
          fileIds: uploadedData,
          ...(order?.id ? { orderId: order?.id } : {})
        };
        const data = await API.put(`expenses/${expense?.id}`, payload);
        let index = expenses.findIndex((item) => item.id === data?.data?.id);
        if (index !== -1) {
          expenses[index] = data?.data;
        }
        setExpenses(expenses);
        setSelectedFile();
        setSelectedExpense(null);
        refetch && (await refetch());
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const calculateDistance = async () => {
    if (order?.pickupNode?.locationLatLng && order?.dropOffNode?.locationLatLng) {
      const distance = await calculateEuclideanDistance(
        order?.pickupNode?.locationLatLng,
        order?.dropOffNode?.locationLatLng
      )
      setDistance(distance)
    }
    else {
      setDistance(0)
    }
  }

  useEffect(() => { calculateDistance() }, [order])
  return (
    <div className={'py-8 px-4'}>
      <input
        ref={uploadRef}
        hidden
        type="file"
        onClick={(e) => {
          setSelectedFile(null);
          e.target.value = null;
        }}
        onChange={(e) => {
          setSelectedFile(e);
        }}
        accept=".jpg,.png,.jpeg,.pdf,.csv,.xlsx,.xls,.doc,.docx,.txt"
      />
      <ExpenseEditListing
        open={openExpense}
        setOpen={setOpenExpense}
        order={order}
        shipment={shipment}
        expenses={expenses}
        setExpenses={setExpenses}
        getDelayedOrderExpense={getDelayedOrderExpense}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        setResetExpense={() => {
          setAPILoader(true);
          getDelayedOrderExpense(1, [], order, shipment);
        }}
      />
      <div className={'border-b border-dark300 pb-8'}>
        <div className={'flex items-center'}>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Order Type
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {organization?.type !== 'MANUFACTURER'
                ? order?.moveType == 'INWARD'
                  ? 'INBOUND'
                  : order.moveType
                : order?.moveType == 'INWARD'
                  ? 'PURCHASE'
                  : order.moveType == 'OUTBOUND'
                    ? 'SALES'
                    : order.moveType}
            </Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              {order.moveSubType == 'PURCHASE' ? 'Supplier' : 'Customer'}
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {order.Company ? order.Company?.name : '-'}
            </Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              created by
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>{`${shipment?.createdBy?.firstName || '-'
              } ${shipment?.createdBy?.lastName || '-'}`}</Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              created on
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {moment(shipment?.createdAt).format('DD/MM/YY')}
            </Paragraph>
          </div>
        </div>
        <div className={'flex items-center mt-6'}>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              distance
            </Label>
            <Paragraph variant={'sm'}>
              {decimalDisplay(
                distance
              ) + ` Km` || '-'}
            </Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              ETA
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {isFeatureEnabled(FLAGS.TMS) ||
                isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                ? `${calculateETA(
                  distance,
                  shipment?.ShipmentLegs?.[0]?.Vehicle?.avgSpeed,
                  order?.expectedDelivery,
                  shipment?.status?.key
                )}`
                : `${calculateTimeDifference(
                  shipment?.dropoffDateTime,
                  shipment?.status?.key
                ).hours || ''
                }h ${calculateTimeDifference(
                  shipment?.dropoffDateTime,
                  shipment?.status?.key
                ).minutes
                }min`}{' '}
            </Paragraph>
          </div>
          <div className={'items-center w-2/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Origin & Destination
            </Label>
            <Paragraph variant={'sm'} className={'flex items-center truncate'}>
              {order.pickupNode?.name ? order.pickupNode?.name : '-'}
              {order.dropOffNode?.name && order.dropOffNode?.name && (
                <img src={RightArrowIcon} alt="arrow" className="w-[20px]" />
              )}
              {order.dropOffNode?.name ? order.dropOffNode?.name : '-'}
            </Paragraph>
          </div>
        </div>
        <div className={'flex items-center mt-6'}>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Carrier
            </Label>
            <Paragraph variant={'sm'}>
              {`${shipment?.ShipmentLegs?.[0]?.Company
                ? shipment?.ShipmentLegs?.[0]?.Company?.name
                : '-'
                }`}
            </Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Vehicle Type
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {`${shipment?.ShipmentLegs?.[0]?.Car?.Category
                ? shipment?.ShipmentLegs?.[0]?.Car?.Category?.name
                : '-'
                }`}
            </Paragraph>
          </div>
          <div className={'items-center w-2/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Vehicle Number
            </Label>
            <Paragraph variant={'sm'} className={'flex items-center truncate'}>
              {`${shipment?.ShipmentLegs?.[0]?.Vehicle
                ? shipment?.ShipmentLegs?.[0]?.Vehicle?.registrationNumber
                : '-'
                }`}
            </Paragraph>
          </div>
        </div>
        <div className={'flex items-center mt-6'}>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Driver Name
            </Label>
            <Paragraph variant={'sm'}>
              {`${shipment?.ShipmentLegs?.[0]?.Driver
                ? shipment?.ShipmentLegs?.[0]?.Driver?.name
                : '-'
                }`}
            </Paragraph>
          </div>
          <div className={'items-center flex-1 w-1/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              {isArabCountry(organization) ? 'Iqama Number' : 'TIN'}
            </Label>
            <Paragraph variant={'sm'} className={'flex items-center truncate'}>
              {`${shipment?.ShipmentLegs?.[0]?.Driver
                ? shipment?.ShipmentLegs?.[0]?.Driver?.cnicNumber
                : '-'
                }`}
            </Paragraph>
          </div>
          <div className={'items-center w-2/4'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Contact Number
            </Label>
            <Paragraph variant={'sm'} className={'truncate'}>
              {`${shipment?.ShipmentLegs?.[0]?.Driver
                ? shipment?.ShipmentLegs?.[0]?.Driver?.phone
                : '-'
                }`}
            </Paragraph>
          </div>
        </div>
        <div className='flex mt-6'>
          <div className={'items-center w-1/3'}>
            <Label variant={'xs'} className={'text-dark500'}>
              Waseeqa Number
            </Label>
            <Paragraph variant={'sm'} className={'truncate flex gap-4 items-center mt-2'}>
              {shipment?.waseeqaNumber || '-'}
              {shipment?.InvoiceLineItems?.length == 0 &&
                <EditIcon
                  className="w-4 h-4"
                  onClick={() => setWaseeqaPopup(true)}
                />
              }
            </Paragraph>
          </div>
          {shipment.status?.key == 'COMPLETED' &&
            shipment?.subStatus?.key !== 'POD_PENDING' && (
              <div className={'items-center w-1/3'}>
                <Label variant={'xs'} className={'text-dark500'}>
                  PoD referenceId
                </Label>
                <Paragraph
                  variant={'sm'}
                  className={'truncate flex gap-4 items-center mt-2'}
                >
                  {shipment?.referenceId || '-'}
                  {shipment?.InvoiceLineItems?.length == 0 && <EditIcon
                    className="w-4 h-4"
                    onClick={() => setPodOpen(true)}
                  />}
                </Paragraph>
              </div>
            )}
        </div>
      </div>
      <div className={'border-b border-dark300 py-8 max-h-[400px]'}>
        <div className={'flex items-center justify-between'}>
          <Title variant={'md'}>Shipment Expenses</Title>
          {expenses?.length > 0 ? (
            <div className={'flex items-center'}>
              <EditIcon
                key="edit"
                fontSize={'small'}
                onClick={() => setOpenExpense(true)}
              />
              <img
                src={DownloadIcon}
                className="h-[20px] w-[20px] ml-[6px]"
                onClick={exportExpense}
              />
            </div>
          ) : (
            <div className={'flex items-center'}>
              <EditIcon
                key="edit"
                fontSize={'small'}
                onClick={() => setOpenExpense(true)}
              />{' '}
            </div>
          )}
        </div>
        <div
          className={'overflow-auto max-h-[330px] mt-3 no-scrollbar'}
          id={'scrollableDiv'}
        >
          {loader && expenses?.length <= 0 ? (
            <div className={'flex flex-1 overflow-hidden pb-4 justify-center'}>
              <CircularProgress size={20} />
            </div>
          ) : expenses?.length <= 0 ? (
            <div className={'flex flex-1 overflow-hidden pb-4 justify-center'}>
              <Paragraph variant={'sm'}>{'No expense created yet.'}</Paragraph>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={expenses.length}
              next={() => {
                getDelayedOrderExpense(page + 1, expenses, order);
                setPage(page + 1);
              }}
              style={{
                overflow: 'hidden'
              }}
              scrollThreshold={0.1}
              hasMore={page < pageCount}
              pullDownToRefresh={false}
              loader={
                <div
                  className={'flex flex-1 overflow-hidden pb-4 justify-center'}
                >
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {expenses?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className={'flex justify-between py-2 items-center'}
                  >
                    <div className={'flex items-center'}>
                      {value?.Files?.length > 0 ? (
                        value?.Files?.map((val, i) => {
                          const fileType = getFileExtension(val?.originalName);
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                openFileInNewTab(val?.location);
                              }}
                              className={clsx(
                                'h-[35px] w-[35px] rounded items-center justify-center flex',
                                fileType === 'pdf'
                                  ? 'bg-[#CE375C]'
                                  : fileType === 'csv' ||
                                    fileType === 'xlsx' ||
                                    fileType === 'xls'
                                    ? 'bg-[#4DA954]'
                                    : fileType === 'doc' ||
                                      fileType === 'docx' ||
                                      fileType === 'txt'
                                      ? 'bg-[#5187ED]'
                                      : 'bg-dusk500'
                              )}
                            >
                              <Label variant={'xs'} className={'text-white'}>
                                {fileType}
                              </Label>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          onClick={() => {
                            uploadExpenseFile(value);
                          }}
                          className={clsx(
                            'h-[35px] w-[35px] rounded items-center justify-center flex border',
                            'bg-white'
                          )}
                        >
                          <img
                            src={AttachmentIcon}
                            className="h-[20px] w-[20px]"
                          />
                        </div>
                      )}
                      <div className={'ml-3 items-center'}>
                        <Paragraph variant={'sm'}>{value?.name}</Paragraph>
                        <Label variant={'xs'} className={'text-dark400'}>
                          {`By ${value?.createdBy?.firstName || '-'} ${value?.createdBy?.lastName || '-'
                            } • ${moment(value.createdAt).format('DD/MM/YY')}`}
                        </Label>
                      </div>
                    </div>
                    <Paragraph variant={'sm'}>
                      {getCurrencySymbol(organization?.defaultCurrency) +
                        ' ' +
                        value?.cost}
                    </Paragraph>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className={'border-b border-dark300 py-8'}>
        <div className={'flex items-center justify-between'}>
          <Title variant={'md'}>Documents</Title>
        </div>
        <div
          className={'overflow-auto max-h-[330px] mt-3 no-scrollbar'}
          id={'scrollableDiv2'}
        >
          {filesLoading && files?.length <= 0 ? (
            <div className={'flex flex-1 overflow-hidden pb-4 justify-center'}>
              <CircularProgress size={20} />
            </div>
          ) : files?.length <= 0 ? (
            <div className={'flex flex-1 overflow-hidden pb-4 justify-center'}>
              <Paragraph variant={'sm'}>
                {'No document uploaded yet.'}
              </Paragraph>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={files.length}
              next={() => {
                getDelayedFiles(pageFile + 1, files, order, shipment);
                setPageFile(pageFile + 1);
              }}
              style={{
                overflow: 'hidden'
              }}
              scrollThreshold={0.1}
              hasMore={pageFile < pageCountFile}
              pullDownToRefresh={false}
              loader={
                <div
                  className={'flex flex-1 overflow-hidden pb-4 justify-center'}
                >
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="scrollableDiv2"
            >
              {files?.map((value, index) => {
                const fileType = getFileExtension(value?.originalName);
                return (
                  <div
                    key={index}
                    className={'flex justify-between py-2 items-center'}
                  >
                    <div className={'flex items-center'}>
                      <div
                        onClick={() => {
                          openFileInNewTab(value?.location);
                        }}
                        className={clsx(
                          'h-[35px] w-[35px] rounded items-center justify-center flex',
                          value === 'pdf'
                            ? 'bg-[#CE375C]'
                            : fileType === 'csv' ||
                              fileType === 'xlsx' ||
                              fileType === 'xls'
                              ? 'bg-[#4DA954]'
                              : fileType === 'doc' ||
                                fileType === 'docx' ||
                                fileType === 'txt'
                                ? 'bg-[#5187ED]'
                                : 'bg-dusk500'
                        )}
                      >
                        <Label variant={'xs'} className={'text-white'}>
                          {fileType}
                        </Label>
                      </div>
                      <div className={'ml-3 items-center'}>
                        <Paragraph variant={'sm'}>
                          {value?.originalName}
                        </Paragraph>
                        <Label variant={'xs'} className={'text-dark400'}>
                          {`By ${value?.createdBy?.firstName || '-'} ${value?.createdBy?.lastName || '-'
                            } • ${moment(value.createdAt).format('DD/MM/YY')}`}
                        </Label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </div>

      <WaseeqaPopup
        open={waseeqaPopup}
        setOpen={setWaseeqaPopup}
        waseeqaNumber={shipment.waseeqaNumber}
        orderId={order.customId}
        shipmentId={shipment.customId}
        refetch={refetch}
        edit={shipment.waseeqaNumber}
      />

      <PodPopup
        open={podOpen}
        setOpen={setPodOpen}
        referenceId={shipment.referenceId}
        orderId={order.id}
        shipmentId={shipment.id}
        refetch={refetch}
        edit={shipment.referenceId}
      />
    </div>
  );
};

export default ShipmentOrderDetail;
