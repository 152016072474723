/*eslint-disable*/
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { Typography, makeStyles } from '@material-ui/core';
import Popup from '../../../core-components/atoms/Popup';
import Button from '../../../core-components/atoms/Button';
import { ImagesList } from '../../invoiceandbilling/invoiceManagement/Payment';
import CustomTextField from '../../../core-components/atoms/TextField';
import API from '../../../libs/axios';
import { upload } from '../../../utils/upload';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import { SharedContext } from '../../../utils/common';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '3rem',
    marginBottom: '1rem'
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    '& button': {
      textTransform: 'none'
    }
  }
}));

export default function PodPopup({
  open,
  setOpen,
  shipmentId,
  orderId,
  refetch,
  referenceId,
  edit
}) {
  const classes = useStyles();
  const { setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      referenceId: referenceId || '',
      date: new Date(),
      images: []
    },
    onSubmit: async (values) => {
      setAPILoader(true);
      try {
        const payload = {
          referenceId: values.referenceId,
          date: values.date
        };

        let fileIds = [];
        if (values.images.length) {
          fileIds = await upload(values.images, 'shipments');
          payload.fileIds = [...fileIds];
        }

        await API.post(`orders/${orderId}/shipments/${shipmentId}/upload_pod`, {
          fileIds,
          referenceId: payload.referenceId,
          edit
        });
        toaster('success', edit ? 'PoD documents uploaded successfully' : 'PoD updated');
        await refetch();
        setOpen(false);
      } catch (e) {
        onError(e);
      } finally {
        setAPILoader(false);
      }
    }
  });

  return (
    <Popup
      title={'Upload PoD Documents'}
      open={open}
      setOpen={setOpen}
      content={
        <div>
          <div className={classes.container}>
            <div>
              <CustomTextField
                label="Reference ID"
                value={formik.values.referenceId}
                onChange={(e) =>
                  formik.setFieldValue('referenceId', e.target.value)
                }
                variant="outlined"
                placeholder="Enter Reference ID"
                fullWidth
              />
            </div>
          </div>
          <div className={classes.imageContainer}>
            <Typography>Attach Supporting Documents</Typography>
            <ImagesList formik={formik} field="files" />
          </div>
        </div>
      }
      actions={
        <div className={classes.btns}>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => await formik.submitForm()}
          >
            {edit ? "Save" : "Upload Document"}
          </Button>
        </div>
      }
    />
  );
}
