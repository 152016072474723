import { useState, useContext, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { SharedContext } from '../../../utils/common';
import CustomTabs from '../../../components/CustomTabs';
import useStyles from '../invoiceStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import InvoiceSummary, { calculateTotal } from '../invoices/InvoiceSummary';
import { upload } from '../../../utils/upload';
import { invoiceFormSchema } from '../invoices/formikFields';
import InvoiceForm from '../invoices/InvoiceForm';
import BasicInfo from '../invoices/BasicInfo';
import Preview from '../invoices/Preview';
import { toaster } from '../../../utils/toaster';
import ShipmentListing from './ShipmentListing';

export const formikInitVals = {
  invoiceType: '',
  contractType: '',
  invoiceTitle: '',
  company: {},
  warehouseID: {},
  supportingDocument: [],
  billingAddress: '',
  customersNTN: '',
  invoiceID: '',
  due: null,
  invoiceStartPeriod: '',
  invoiceEndPeriod: '',
  referenceID: '',
  discount: 0,
  adjustment: 0,
  currency: 'PKR',
  currentStep: '',
  notes: ''
};

export default function AddExpense() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  let invoiceBillingFlag = true;

  const [activeTab, setActiveTab] = useState('Info');
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [draftItemLength, setDraftItemLength] = useState(null);
  const [organization, setOrganization] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [deletedItems, setDeletedItems] = useState([]);
  const [res, setRes] = useState({});
  const [disableType, setDisableType] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [invoiceStartError, setInvoiceStartError] = useState('');
  const [invoiceTitles, setInvoiceTitles] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pendingShipments, setPendingShipments] = useState([]);
  const [page, setPage] = useState(1);
  const [shipmentsearch, setShipmentSearch] = useState('');
  const [pages, setPages] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState('Number of Trips');
  const { currentUser, setAPILoader, subdomain, setCurrentPageTitle, sidebar } =
    useContext(SharedContext);

  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: invoiceFormSchema,
    validateOnChange: firstSubmit,
    validateOnBlur: firstSubmit,
    validate: (values) => {
      if (!values.invoiceStartPeriod) {
        setInvoiceStartError('Invoice date is required');
      } else {
        setInvoiceStartError('');
      }
    },
    onSubmit: async () => {
      changeTab(await nextTab());
    }
  });

  const processShipmentBill = async () => {
    let expenses = [];
    for (let shipment of selected) {
      if (shipment.expenses?.length > 0) {
        for (let expense of shipment.expenses) {
          expenses.push({ cost: expense.cost, name: expense.name });
        }
      }
    }
    try {
      setAPILoader(true);
      const response = await API.post(`invoices/process-bill-shipment`, {
        shipments: selected,
        sourceId: formik.values?.company?.id,
        sourceType:
          formik.values.invoiceType == 'Logistics' ? 'Company' : 'Vendor',
        sourceName: formik.values?.company?.name
      });

      setInvoiceItems(response.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const generatePreviewData = (useInvoiceNumber) => {
    let lineItems = {};
    for (let i = 0; i < selected.length; i++) {
      const shipmentId = selected[i].shipmentId;
      const orderId = selected[i].orderId;
      const referenceId = selected[i].referenceId;
      if (lineItems[shipmentId]) {
        lineItems[shipmentId].expenses.push(selected[i].expenseId);
      } else {
        lineItems[shipmentId] = {
          expenses: [],
          shipmentId,
          orderId,
          referenceId
        };
        lineItems[shipmentId].expenses.push(selected[i].expenseId);
      }
    }
    let previewData = {
      company: formik?.values?.company,
      invoice: {
        number: useInvoiceNumber ? invoiceNumber : formik?.values?.invoiceID,
        type: formik?.values?.invoiceType,
        date: moment(new Date()).format('DD/MM/YYYY'),
        dueDate: moment(formik?.values?.due).format('DD/MM/YYYY'),
        subTotal: calculateTotal(false, invoiceItems) || 0,
        currency: formik.values.currency,
        tax: calculateTotalTax().toFixed(2),
        terms: formik.values.company?.paymentTerms,
        balanceDue:
          calculateTotal(true, invoiceItems) -
          Number(formik.values.discount) +
          Number(Number(formik.values.adjustment).toFixed(2)),
        notes: formik.values.notes,
        billing: true,
        adjustment: formik.values.adjustment
      },
      organization,
      invoiceItems,
      lineItems: Object.keys(lineItems).map((key) => lineItems[key]),
      sourceId: formik.values?.company?.id,
      sourceType:
        formik.values.invoiceType == 'Logistics' ? 'Company' : 'Vendor'
    };

    return previewData;
  };

  const calculateTotalTax = () =>
    invoiceItems.length > 0
      ? invoiceItems.reduce(
          (a, b) =>
            b.rate && b.unit && b.tax?.rate
              ? a + Number(((b.tax.rate / 100) * (b.rate * b.unit)).toFixed(2))
              : a + 0,
          0
        )
      : 0;
  const getInvoiceData = (useInvoiceNumber) => {
    let invoice = {
      previewData: generatePreviewData(useInvoiceNumber),
      shipmentTableData: selected.map((s) => ({
        shipmentId: s.shipmentCustomId,
        origin: s.origin,
        destination: s.destination,
        cost: s.expenseCost,
        name:
          s.expenseName?.indexOf(formik.values.company?.name) == -1
            ? s.expenseName
            : 'Carrier Charges',
        vehicle: s.vehicle,
        referenceId: s.referenceId,
        orderId: s.orderId
      })),
      invoicePayload: {
        warehouseId: formik?.values?.warehouseID?.id,
        companyId:
          formik.values?.invoiceType == 'Logistics'
            ? formik?.values?.company?.id
            : null,
        vendorId:
          formik.values?.invoiceType == 'Other Services'
            ? formik?.values?.company?.id
            : null,
        number: useInvoiceNumber ? invoiceNumber : formik?.values?.invoiceID,
        type: formik?.values?.invoiceType || null,
        startDate: formik?.values?.invoiceStartPeriod || null,
        contractType: formik?.values?.contractType || null,
        endDate: formik?.values?.invoiceEndPeriod || null,
        dueDate: formik?.values?.due || null,
        referenceId: formik?.values?.referenceID,
        notes: formik?.values?.notes,
        adjustment: formik.values.adjustment,
        discount: formik.values.discount,
        total:
          (
            calculateTotal(true, invoiceItems) -
            Number(formik.values.discount) +
            Number(formik.values.adjustment)
          ).toFixed(2) || null,
        subTotal: calculateTotal(false, invoiceItems) || 0,
        paidAmount: 0,
        currency: formik.values.currency,
        status: moment(formik?.values?.due).isAfter(moment(new Date()))
          ? 'PENDING'
          : 'OVERDUE',
        userId: currentUser.id,
        billing: 1
      },
      invoiceItems,
      lineItems: selected,
      sourceId: formik.values.company?.id,
      sourceType:
        formik.values.invoiceType == 'Logistics' ? 'Company' : 'Vendor'
    };
    return invoice;
  };

  const addInvoice = async (isDraft) => {
    let invoice = getInvoiceData(true);
    let endpoint = isDraft ? 'invoice-drafts' : 'invoices';
    let response;
    let fileIds = [];
    if (formik?.values?.images?.length) {
      fileIds = await upload(formik?.values?.images, endpoint);
      invoice.supportingDocument = [
        ...(formik?.values?.supportingDocument || []),
        ...fileIds
      ];
    }
    try {
      setAPILoader(true);
      if (isDraft) {
        if (activeTab !== 'Preview') {
          invoice.invoicePayload.status = 'INCOMPLETE';
        } else {
          invoice.invoicePayload.status = 'READY_TO_SEND';
        }
      } else {
        if (invoice.invoicePayload.total <= 0) {
          invoice.invoicePayload.status = 'PAID';
        }
      }

      response = await API.post(endpoint, invoice);

      if (id) {
        await API.delete(`invoice-drafts/${id}`);
      }
      if (isDraft) {
        toaster('success', 'Draft Created successfully');
      } else {
        toaster('success', 'Expense Created Successfully');
      }

      navigate('/billing-invoice/payables');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
    return response;
  };

  const verify = async () => {
    let success = true;
    try {
      setAPILoader(true);
      await API.get(`invoices/verify/${formik.values.invoiceID}`);
    } catch (err) {
      onError(err);
      success = false;
    } finally {
      setAPILoader(false);
    }
    return success;
  };

  const verifyInvoiceItems = () => {
    let success = true;
    if (invoiceItems?.length > 0) {
      invoiceItems.forEach((item) => {
        if (!item.title.name || !item.unit || !item.rate) {
          success = false;
        }
      });
    } else {
      if (invoiceItems.length === 0) {
        success = false;
      }
    }
    return success;
  };

  const changeTab = async (tab) => {
    setActiveTab(tab);
  };

  const nextTab = async () => {
    if (activeTab === 'Info') {
      const res = await verify();
      if (invoiceBillingFlag) {
        return res ? 'Shipment Selection' : 'Info';
      } else {
        return res ? 'Invoice Form' : 'Info';
      }
    } else if (activeTab === 'Shipment Selection') {
      return 'Invoice Form';
    } else {
      const res = verifyInvoiceItems();
      if (!res) {
        if (invoiceItems.length === 0) {
          toaster('warning', 'Invoice should not be empty');
        } else {
          toaster('warning', 'Please fill out all fields');
        }
        return 'Invoice Form';
      } else return 'Preview';
    }
  };
  const handleChange = (name, val) => {
    formik.setFieldValue(name, val);
  };

  const setAdjustment = async () => {
    try {
      setAPILoader(true);
      const adjustment = await API.get(`invoices/adjustment`, {
        params: { companyId: formik.values.company.id, billing: true }
      });
      formik.setFieldValue(
        'adjustment',
        Number(adjustment?.adjustment?.toFixed(2))
      );
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getInvoiceID = async () => {
    try {
      setAPILoader(true);
      const number = await API.get(`/invoices/invoice-number`, {
        params: { type: 'bill' }
      });
      setInvoiceNumber(number?.data);
      if (!id) {
        handleChange('invoiceID', number?.data);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getOrganization = async () => {
    try {
      setAPILoader(true);
      const res = await API.get(`organizations/${subdomain}/check`);
      setRes(res);
      setOrganization(res.organization);
      formik.setFieldValue(
        'currency',
        res.organization?.defaultCurrency?.toUpperCase() || 'PKR'
      );
      formik.setFieldValue('notes', res.organization?.notes);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getInvoiceTitles = async () => {
    try {
      setAPILoader(true);
      const invoiceTitles = await API.get(`invoice-titles/group-by-type`);
      setInvoiceTitles(invoiceTitles);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getGroupedTypes = async () => {
    try {
      setAPILoader(true);
      const units = await API.get(`unit-types/grouped-types`);
      if (formik.values.invoiceType === 'Warehousing') {
        setUnitTypes(units.warehousing);
      } else if (formik.values.invoiceType === 'Logistics') {
        setUnitTypes(units.logistics);
      } else {
        setUnitTypes(units.technology);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const deleteItem = async () => {
    try {
      setAPILoader(true);
      await API.post(`invoice-draft-items`, { items: deletedItems });
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const fetchDraft = async () => {
    try {
      setAPILoader(true);
      const { invoice } = await API.get(`invoice-drafts/${id}`);
      formik.setValues({
        invoiceType: invoice.type || '',
        contractType: invoice.contractType || '',
        company: invoice.Company || {},
        warehouseID: invoice.Warehouse || {},
        supportingDocument: invoice.supportingDocument || [],
        billingAddress: '',
        customersNTN: '',
        invoiceID: invoice.number,
        due: invoice.dueDate || null,
        invoiceStartPeriod: invoice.startDate,
        invoiceEndPeriod: invoice.endDate,
        referenceID: invoice.referenceId,
        discount: invoice.discount,
        currency: invoice.currency || 'PKR',
        isInvoiceType: invoice.type ? true : false
      });

      if (invoice.type) {
        setDisableType(true);
      }

      const { InvoiceDraftItems } = invoice;
      let newItems =
        InvoiceDraftItems.length > 0
          ? InvoiceDraftItems.map((item) => ({
              title: item.InvoiceTitle,
              tax: item.Tax,
              unitType: item.UnitType,
              rate: item.rate,
              unit: item.unit,
              totalAmount: item.totalAmount,
              company: item.Company,
              id: item.id
            }))
          : [];
      setInvoiceItems(newItems);
      setDraftItemLength(newItems.length);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getPendingShipments = async () => {
    try {
      setAPILoader(true);
      const response = await API.get(`orders/shipments/pending-bills`, {
        params: {
          sourceId: formik.values.company?.id,
          sourceType:
            formik.values.invoiceType == 'Logistics' ? 'Company' : 'Vendor',
          page,
          search: shipmentsearch
        }
      });
      setPendingShipments(response.shipments || []);
      setPages(response.pages);
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const updateDraft = async () => {
    let invoice = getInvoiceData(false);
    let response;
    let fileIds = [];
    invoice.invoiceItems = invoiceItems.slice(draftItemLength);
    invoice.prevInvoiceItems = invoiceItems.slice(0, draftItemLength);
    if (formik?.values?.images?.length) {
      fileIds = await upload(formik?.values?.images, 'invoice-drafts');
      invoice.invoicePayload.supportingDocument = [
        ...(formik?.values?.supportingDocument || []),
        ...fileIds
      ];
    }
    try {
      setAPILoader(true);
      if (activeTab !== 'Preview') {
        invoice.invoicePayload.status = 'INCOMPLETE';
      } else {
        invoice.invoicePayload.status = 'READY_TO_SEND';
      }

      response = await API.put(`invoice-drafts/${id}`, invoice);
      await deleteItem();

      toaster('success', 'Draft updated successfully');
      navigate('/billing-invoice/payables');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
    return response;
  };

  const generateInvoiceItems = (type) => {
    if (invoiceTitles) {
      if (type === 'Warehousing') {
        return invoiceTitles.warehousing?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      } else if (type === 'Logistics') {
        return invoiceTitles.logistics?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      } else if (type === 'Technology') {
        return invoiceTitles.technology?.map((title) => ({
          tax: {},
          unitType: {},
          unit: 1,
          rate: null,
          title
        }));
      }
    }
    return [];
  };

  useEffect(() => {
    if (!id && formik?.values?.invoiceType) {
      if (formik.values.invoiceType === 'Warehousing') {
        setInvoiceItems(generateInvoiceItems('Warehousing'));
      } else if (
        formik.values.invoiceType === 'Logistics' &&
        !invoiceBillingFlag
      ) {
        setInvoiceItems(generateInvoiceItems('Logistics'));
      } else if (formik.values.invoiceType === 'Technology') {
        setInvoiceItems(generateInvoiceItems('Technology'));
      } else setInvoiceItems([]);
    } else if (
      id &&
      !formik.values.isInvoiceType &&
      formik.values.invoiceType
    ) {
      if (formik.values.invoiceType === 'Warehousing') {
        setInvoiceItems(generateInvoiceItems('Warehousing'));
      } else if (
        formik.values.invoiceType === 'Logistics' &&
        !invoiceBillingFlag
      ) {
        setInvoiceItems(generateInvoiceItems('Logistics'));
      } else if (formik.values.invoiceType === 'Technology') {
        setInvoiceItems(generateInvoiceItems('Technology'));
      } else setInvoiceItems([]);
    }
  }, [formik?.values?.invoiceType]);

  useEffect(() => {
    getOrganization();
    getInvoiceID();
    getInvoiceTitles();
    setCurrentPageTitle('Create Expense');
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      processShipmentBill();
    } else {
      setInvoiceItems([]);
    }
  }, [selected]);

  useEffect(() => {
    if (id) {
      fetchDraft();
    }
  }, [id]);

  useEffect(() => {
    if (formik.values.invoiceType) {
      getGroupedTypes();
    }
  }, [formik.values.invoiceType]);

  useEffect(() => {
    if (formik.values.company?.id) {
      setAdjustment();
      getPendingShipments();
    }
  }, [formik.values.company, page, shipmentsearch]);

  return (
    <Grid container className={classes.parentContainer} spacing={3}>
      <Grid item container>
        <Grid item xs={activeTab !== 'Preview' ? 9 : 10}>
          <Grid item container>
            <Grid item xs={12}>
              <CustomTabs
                changeTab={(tab) => {
                  const steps = [
                    'Info',
                    'Shipment Selection',
                    'Invoice Form',
                    'Preview'
                  ];
                  const currentStepIndex = steps.indexOf(activeTab);
                  const targetStepIndex = steps.indexOf(tab);
                  if (targetStepIndex <= currentStepIndex) {
                    setActiveTab(tab);
                  }
                }}
                activeStep={activeTab}
                steps={[
                  'Info',
                  'Shipment Selection',
                  'Invoice Form',
                  'Preview'
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              {activeTab === 'Info' && (
                <BasicInfo
                  formik={formik}
                  handleChange={(name, val) => handleChange(name, val)}
                  billing={true}
                  invoiceStartError={invoiceStartError}
                  disableType={disableType}
                />
              )}
              {activeTab === 'Shipment Selection' && (
                <ShipmentListing
                  rows={pendingShipments?.flatMap(
                    (s) =>
                      s.Expenses?.map((expense) => ({
                        files: s.files,
                        shipmentCustomId: s.customId,
                        shipmentId: s.customId,
                        id: s.id,
                        orderId: s.Order.customId,
                        origin: s.Order.pickupNode?.name,
                        destination: s.Order.dropOffNode?.name,
                        expenseName: expense.name,
                        expenseCost: expense.cost || 0,
                        expenseId: expense.id,
                        referenceId: s.referenceId || '-',
                        podStatus: s.subStatus?.name,
                        isDisabled: !expense.cost,
                        pickupNode: s.Order.pickupNode,
                        dropOffNode: s.Order.dropOffNode,
                        driver:
                          s.Driver?.name ||
                          s.ShipmentLegs?.[0]?.Driver?.name ||
                          s.driverName,
                        vehicle:
                          s.Vehicle?.registrationNumber ||
                          s.ShipmentLegs?.[0]?.Vehicle?.registrationNumber ||
                          s.vehicleNumber
                      })) || []
                  )}
                  search={shipmentsearch}
                  setSearch={setShipmentSearch}
                  setSelected={setSelected}
                  selected={selected}
                  page={page}
                  pages={pages}
                  setPage={setPage}
                  selectedUnit={selectedUnit}
                  setSelectedUnit={setSelectedUnit}
                  formik={formik}
                  company={formik.values.company?.name}
                  isBilling={true}
                />
              )}
              {activeTab === 'Invoice Form' && (
                <InvoiceForm
                  handleChange={(name, val) => handleChange(name, val)}
                  formik={formik}
                  invoiceItems={invoiceItems}
                  setInvoiceItems={setInvoiceItems}
                  type={'bill'}
                  billing={true}
                  isDraft={!!id}
                  setDeletedItems={(item) =>
                    setDeletedItems([...deletedItems, item])
                  }
                  unitTypes={unitTypes}
                />
              )}
              {activeTab === 'Preview' && (
                <Preview
                  data={generatePreviewData()}
                  type={'bill'}
                  setHtml={() => {}}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {activeTab !== 'Preview' && (
          <Grid item xs={3}>
            <InvoiceSummary
              formik={formik}
              invoiceItems={invoiceItems}
              billing={true}
            />
          </Grid>
        )}

        <div className={classes.actionButtons}>
          <div>
            <Button
              onClick={async () => {
                if (!id) {
                  await addInvoice(true);
                } else {
                  await updateDraft();
                }
              }}
              variant="outlined"
              color="primary"
              className={classes.draftBtn}
              size="large"
              style={{ marginLeft: sidebar ? 360 : 200 }}
            >
              Save Draft
            </Button>
          </div>
          <div className={classes.nextCancelButtons}>
            {!res?.id && (
              <Button
                onClick={() => navigate('/billing-invoice/payables')}
                variant="outlined"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            )}
            {activeTab !== 'Preview' ? (
              <Button
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setFirstSubmit(true);
                }}
                variant="contained"
                className={classes.nextBtn}
                color="primary"
              >
                Next
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  addInvoice();
                }}
                variant="contained"
                className={classes.nextBtn}
                color="primary"
              >
                Done
              </Button>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
