/*eslint-disable*/
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles, Paper, Popper, Tooltip } from '@material-ui/core';
import { checkPermission, isOwareSuperAdmin } from '../../../utils/auth';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';
import Button from '../../../core-components/atoms/Button';
import DownloadIconNew from '../../../assets/icons/downloadIconNew.svg';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import OrderDetailStats from './OrderDetailStats';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import { ClickAwayListener } from '@mui/base';
import NewSearchBar from '../../../atomicComponents/NewSearchBar';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpenseEditListing from '../../multiModalShipment/expense/ExpenseEditListing';
import InfiniteScroll from 'react-infinite-scroll-component';
import DownloadIcon from '../../../assets/icons/downloadFileIcon.svg';
import FileDownload from 'js-file-download';
import moment from 'moment-timezone';
import PrintOrder from './OrderPrint/PrintOrder';
import AlertIcon from '../../../assets/icons/error.svg';

const useStyles = makeStyles(() => ({
  popper: {
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    zIndex: 1300
  },
  paper: {
    pointerEvents: 'auto',
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    marginTop: '2px',
    boxShadow:
      '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
    overflowY: 'auto',
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    '& input': {
      fontSize: '12px !important'
    }
  }
}));

const OrderDetail = ({
  order,
  currentUser,
  orderSummary,
  nonNegative,
  exportToExcel,
  getTotalByKey,
  resetExpense,
  setResetExpense
}) => {
  const anchorRef = useRef(null);
  const [poperOpen, setPopperOpen] = React.useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [expense, setExpense] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [openExpense, setOpenExpense] = useState(false);
  const [shipmentExpenseTotal, setShipmentExpenseTotal] = useState(0);
  const [miscellaneousExpenseTotal, setMiscellaneousExpenseTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [firstCall, setFirstCall] = useState(true);
  const { organization, settings } = useContext(SharedContext);

  useEffect(() => {
    if (resetExpense) {
      setExpense([]);
      setResetExpense(false);
      setFirstCall(true);
      setPage(1);
    }
  }, [resetExpense]);
  useEffect(() => {
    if (expense.length <= 0 && order?.id && firstCall) {
      setLoader(true);
      setFirstCall(false);
      getDelayedOrderExpense(1, [], order, search);
    }

    if (expense.length > 0) {
      setLoader(false);
    }
  }, [expense, order, firstCall]);

  const getDelayedOrderExpense = useCallback(
    debounce(
      async (page, oldData, orderDetail, searchData) => {
        getOrderExpense(page, oldData, orderDetail, searchData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getOrderExpense = async (
    page,
    oldData = [],
    orderDetail,
    searchData
  ) => {
    try {
      const res = await API.get(`expenses`, {
        params: {
          page: page,
          limit: 20,
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {}),
          search: searchData,
          orderByShipmentId: true
        }
      });
      const newArr = res?.data;
      setExpense([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setExpenseTotal(res?.totalCost || 0);
      setShipmentExpenseTotal(res?.totalShipmentCost || 0);
      setMiscellaneousExpenseTotal(res?.totalMiscellaneousCost || 0);
      setLoader(false);
    } catch (error) {
      onError(error);
      setLoader(false);
    }
  };

  const exportExpense = async () => {
    const response = await API.get(`/expenses/export`, {
      responseType: 'blob',
      params: { orderId: order?.id }
    });
    FileDownload(response, `Expenses ${moment().format('DD-MM-yyyy')}.xlsx`);
  };

  return (
    <div>
      <ExpenseEditListing
        open={openExpense}
        setOpen={setOpenExpense}
        order={order}
        shipment={null}
        expenses={expense}
        setExpenses={setExpense}
        getDelayedOrderExpense={getDelayedOrderExpense}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        setResetExpense={setResetExpense}
      />
      <OrderDetailStats order={order} getTotalByKey={getTotalByKey} />
      <Title variant={'md'} className={'mb-4 mt-14'}>
        SKU Details
      </Title>
      <TableContainer
        component={Paper}
        className="w-full"
        sx={{
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid #E7E6E6 !important',
          outline: 'none !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
        elevation={0}
      >
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>
                <Label variant={'xs'} className="text-[#67718C] normal-case">
                  Sku Details
                </Label>
              </TableCell>
              <TableCell>
                <Label variant={'xs'} className="text-[#67718C] normal-case">
                  Order Qty
                </Label>
              </TableCell>
              {order.moveType !== 'LOGISTIC' && (
                <>
                  <TableCell>
                    <Label
                      variant={'xs'}
                      className="text-[#67718C] normal-case"
                    >
                      {order.moveType == 'INWARD'
                        ? 'Received Quantity'
                        : 'Dispatched Quantity'}
                    </Label>
                  </TableCell>
                  {order.moveType == 'TRANSFER' && (
                    <>
                      <TableCell sx={{ width: '15%' }}>
                        <Label
                          variant={'xs'}
                          className="text-[#67718C] normal-case"
                        >
                          Received Qty
                        </Label>
                      </TableCell>
                    </>
                  )}
                  <TableCell sx={{ width: '15%' }}>
                    <Label
                      variant={'xs'}
                      className="text-[#67718C] normal-case"
                    >
                      Remaining Qty
                    </Label>
                  </TableCell>
                </>
              )}
              {order.moveSubType === 'PURCHASE' &&
                checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY') && (
                  <>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Purchase Price
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Tax %
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Total Cost
                      </Label>
                    </TableCell>
                  </>
                )}
              {order.moveSubType === 'SALES' &&
                checkPermission(
                  currentUser,
                  'OPS_SELLING_PRICE_VISIBILITY'
                ) && (
                  <>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Selling Price
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Tax %
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant={'xs'}
                        className="text-[#67718C] normal-case"
                      >
                        Total Revenue
                      </Label>
                    </TableCell>
                  </>
                )}
              {order.orderLoss ? (
                <TableCell>
                  <Label
                    variant={'xs'}
                    className="text-[#67718C] normal-case"
                  ></Label>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(orderSummary).length > 0 &&
              Object.keys(orderSummary).map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{ width: '25%' }}
                    className="truncate"
                    component="th"
                    scope="row"
                  >
                    <Paragraph variant={'sm'} className={'text-[#0B1940]'}>
                      {orderSummary[key].productName}
                    </Paragraph>
                    <Label variant={'xs'} className={'text-dark500'}>
                      {orderSummary[key].sku}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Paragraph variant={'sm'} className={'text-dark600'}>
                      {`${orderSummary[key].total} ${orderSummary[key].uom}`}{' '}
                      {orderSummary[key].secondaryUom
                        ? `| ${decimalDisplay(
                            orderSummary[key].secondaryUomQuantity
                          )} ${orderSummary[key].secondaryUom}`
                        : ''}
                    </Paragraph>
                  </TableCell>
                  {order.moveType !== 'LOGISTIC' && (
                    <>
                      <TableCell>
                        <Paragraph variant={'sm'} className={'text-dark600'}>
                          {`${
                            order.moveType == 'OUTBOUND'
                              ? orderSummary[key].dispatched
                              : orderSummary[key].received
                          } ${orderSummary[key].uom}`}
                        </Paragraph>
                      </TableCell>
                      {order.moveType == 'TRANSFER' && (
                        <>
                          <TableCell sx={{ width: '15%' }}>
                            <Label
                              variant={'xs'}
                              className="text-[#67718C] normal-case"
                            >
                              {orderSummary[key].received}
                            </Label>
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        <Paragraph variant={'sm'} className={'text-dark600'}>
                          {`${decimalDisplay(
                            nonNegative(
                              order.moveType == 'OUTBOUND'
                                ? orderSummary[key].total -
                                    orderSummary[key].dispatched
                                : orderSummary[key].total -
                                    orderSummary[key].received
                            )
                          )} ${orderSummary[key].uom}`}
                        </Paragraph>
                      </TableCell>
                    </>
                  )}
                  {order.moveSubType === 'PURCHASE' &&
                    checkPermission(
                      currentUser,
                      'OPS_COST_PRICE_VISIBILITY'
                    ) && (
                      <>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].costPrice)}
                          </Paragraph>
                        </TableCell>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].tax)}
                          </Paragraph>
                        </TableCell>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].totalCost)}
                          </Paragraph>
                        </TableCell>
                      </>
                    )}
                  {order.moveSubType === 'SALES' &&
                    checkPermission(
                      currentUser,
                      'OPS_SELLING_PRICE_VISIBILITY'
                    ) && (
                      <>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].sellingPrice)}
                          </Paragraph>
                        </TableCell>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].tax)}
                          </Paragraph>
                        </TableCell>
                        <TableCell>
                          <Paragraph variant={'sm'} className={'text-dark600'}>
                            {decimalDisplay(orderSummary[key].totalRevenue)}
                          </Paragraph>
                        </TableCell>
                      </>
                    )}
                  {order.moveType === 'TRANSFER' && (
                    <>
                      {
                        <TableCell>
                          {order.orderLoss ? (
                            <div className="flex items-center">
                              <Tooltip
                                placement="left"
                                title={`${order.orderLoss} items lost`}
                              >
                                <img src={AlertIcon} />
                              </Tooltip>
                            </div>
                          ) : null}
                        </TableCell>
                      }
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className="bg-light w-full flex justify-between pl-4 pr-12 py-2.5 items-center">
          <div className={'flex items-center'}>
            <Button
              variant="transparent"
              size="large"
              label="Export"
              onClick={() => {
                exportToExcel();
              }}
              icon={<img src={DownloadIconNew} />}
              className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs "
              iconClass="h-[14px] w-[14px] mr-[6px]"
            />
            <PrintOrder
              order={order}
              orderSummary={orderSummary}
              expenseTotal={shipmentExpenseTotal}
              loading={loader}
            />
          </div>
          <div className="flex items-center">
            <div className="relative">
              <div className="flex items-center border-primaryBlue gap-1">
                <Label variant={'xs'} className="text-dark500">
                  Calculated Weight:
                </Label>
                <Title variant={'sm'} className="ml-1 text-primaryBlue">
                  {decimalDisplay(order?.calculatedWeight)}
                </Title>
                <Label
                  variant={'xs'}
                  className="text-primaryBlue ml-0.5 normal-case"
                >
                  {settings?.weight?.symbol || 'Kg'}
                </Label>
              </div>
            </div>
            <div className="flex ml-4 items-center">
              <Label variant={'xs'} className="text-dark500">
                Primary Total Qty:
              </Label>
              <Title variant={'sm'} className="ml-1 text-primaryBlue">
                {decimalDisplay(getTotalByKey('total'))}
              </Title>
            </div>
            {(checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') ||
              checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')) && (
              <div className="flex ml-4 items-center" onClick={() => {}}>
                <Label variant={'xs'} className="text-dark500">
                  Grand Total:
                </Label>
                <div
                  ref={anchorRef}
                  onClick={() => {
                    if (
                      ['SALES', 'PURCHASE'].includes(order?.moveSubType) ||
                      !!expense?.length
                    ) {
                      setPopperOpen(true);
                    }
                  }}
                >
                  <Title
                    variant={'sm'}
                    className="ml-1 text-oasisPrimary500 underline select-none"
                  >
                    {getCurrencySymbol(organization?.defaultCurrency) + ' '}
                    {Object.keys(orderSummary).length > 0 &&
                    (order?.moveSubType === 'PURCHASE' ||
                      order?.moveSubType === 'SALES_RETURN')
                      ? decimalDisplay(
                          Object.keys(orderSummary).reduce(
                            (total, key) =>
                              total + (orderSummary[key]?.totalCost || 0),
                            0
                          ) + expenseTotal
                        )
                      : Object.keys(orderSummary).length > 0 &&
                        order?.moveSubType === 'SALES'
                      ? decimalDisplay(
                          Object.keys(orderSummary).reduce(
                            (total, key) =>
                              total + (orderSummary[key]?.totalRevenue || 0),
                            0
                          ) + expenseTotal
                        )
                      : expenseTotal}
                  </Title>
                </div>
              </div>
            )}
          </div>
        </div>
      </TableContainer>
      <Popper
        open={poperOpen}
        anchorEl={anchorRef.current}
        placement={'bottom-end'}
        disablePortal
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setPopperOpen(false);
          }}
        >
          <Paper className={classes.paper} id={'scrollableDiv'}>
            <div className={'w-[300px] max-h-[400px] p-4'}>
              {!!expense?.length && (
                <NewSearchBar
                  showFilter={false}
                  filter={false}
                  setFilterView={() => {}}
                  setSearchValue={(e) => {
                    setSearch(e);
                    getDelayedOrderExpense(1, [], order, search);
                  }}
                  className={'border-0'}
                />
              )}
              <InfiniteScroll
                dataLength={expense.length}
                next={() => {
                  getDelayedOrderExpense(page + 1, expense, order);
                  setPage(page + 1);
                }}
                style={{
                  overflow: 'hidden'
                }}
                hasMore={page < pageCount}
                pullDownToRefresh={false}
                loader={
                  <div
                    className={
                      'flex flex-1 overflow-hidden pb-4 justify-center'
                    }
                  >
                    <CircularProgress size={20} />
                  </div>
                }
                scrollThreshold={0.1}
                scrollableTarget="scrollableDiv"
              >
                {expense?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={clsx(
                        'px-1.5 py-3 flex justify-between items-center',
                        index % 2 === 0 ? ' bg-white' : ' bg-gray-200'
                      )}
                    >
                      <div>
                        <Paragraph variant={'sm'}>{value?.name}</Paragraph>
                        <Paragraph variant={'xs'} className={'text-dark500'}>
                          {value?.Shipment?.customId}
                        </Paragraph>
                      </div>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          value?.cost}
                      </Paragraph>
                    </div>
                  );
                })}
              </InfiniteScroll>
              {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
                <div
                  className={clsx(
                    'px-1.5 py-3 flex justify-between items-center mt-1',
                    expense?.length ? ' border-t' : ''
                  )}
                >
                  <Paragraph variant={'sm'} className={'text-dark500'}>
                    Total{' '}
                    {order?.moveSubType === 'SALES' ? 'Sales' : 'Purchase'}
                  </Paragraph>
                  <Paragraph variant={'sm'}>
                    {getCurrencySymbol(organization?.defaultCurrency) +
                      ' ' +
                      Object.values(orderSummary).reduce(
                        (total, val) =>
                          total +
                          (val?.[
                            order?.moveSubType === 'SALES'
                              ? 'sellingPrice'
                              : 'costPrice'
                          ] || 0) *
                            (val?.total ?? 0),
                        0
                      )}
                  </Paragraph>
                </div>
              )}
              {!!expense?.length && !!miscellaneousExpenseTotal && (
                <div
                  className={
                    'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                  }
                >
                  <Paragraph variant={'sm'} className={'text-dark500'}>
                    Total Miscellaneous Expense
                  </Paragraph>
                  <Paragraph variant={'sm'}>
                    {getCurrencySymbol(organization?.defaultCurrency) +
                      ' ' +
                      miscellaneousExpenseTotal}
                  </Paragraph>
                </div>
              )}
              {!!expense?.length && !!shipmentExpenseTotal && (
                <div
                  className={
                    'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                  }
                >
                  <Paragraph variant={'sm'} className={'text-dark500'}>
                    Total Shipment Expense
                  </Paragraph>
                  <Paragraph variant={'sm'}>
                    {getCurrencySymbol(organization?.defaultCurrency) +
                      ' ' +
                      shipmentExpenseTotal}
                  </Paragraph>
                </div>
              )}
              {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
                <div
                  className={
                    'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                  }
                >
                  <Paragraph variant={'sm'} className={'text-dark500'}>
                    Total Tax
                  </Paragraph>
                  <Paragraph variant={'sm'}>
                    {getCurrencySymbol(organization?.defaultCurrency) +
                      ' ' +
                      Object.values(orderSummary).reduce(
                        (total, val) =>
                          total +
                          (val?.[
                            order?.moveSubType === 'SALES'
                              ? 'totalRevenue'
                              : 'totalCost'
                          ] || 0) -
                          (val?.[
                            order?.moveSubType === 'SALES'
                              ? 'sellingPrice'
                              : 'costPrice'
                          ] || 0) *
                            (val?.total ?? 0),
                        0
                      )}
                  </Paragraph>
                </div>
              )}
              {expense?.length > 0 && (
                <div
                  className={
                    'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                  }
                >
                  <div className={'flex'}>
                    <Paragraph variant={'sm'} className={'text-dark500'}>
                      Total Order Expense
                    </Paragraph>
                    {(isOwareSuperAdmin(currentUser) ||
                      checkPermission(
                        currentUser,
                        'OPS_ORDER_EXPENSE_UPDATE'
                      )) && (
                      <EditIcon
                        key="edit"
                        fontSize={'small'}
                        onClick={() => {
                          setOpenExpense(true);
                          setPopperOpen(false);
                        }}
                        className={'ml-3'}
                      />
                    )}
                    <img
                      src={DownloadIcon}
                      className="h-[20px] w-[20px] ml-[6px]"
                      onClick={() => exportExpense()}
                    />
                  </div>
                  <Paragraph variant={'sm'}>
                    {getCurrencySymbol(organization?.defaultCurrency) +
                      ' ' +
                      expenseTotal}
                  </Paragraph>
                </div>
              )}
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default OrderDetail;
