/*eslint-disable*/
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Collapse, Grid, List, ListItem, TableContainer } from '@mui/material';
import Button from '../../core-components/atoms/Button';
import DockAssignment from './DockAssignment';
import Stepper from '../../core-components/atoms/Steppar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Popup from '../../core-components/atoms/Popup';
import AddShipment from './AddShipment';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { SharedContext } from '../../utils/common';
import OptionsMenu from './Options';
import { useFormik } from 'formik';
import CancelShipment from './CancelShipment';
import { useNavigate } from 'react-router';
import {
  ActiveOrderRow,
  ClosedOrderRow,
  CompleteOrderRow,
  DraftOrderRow,
  CancelledOrderRow,
  AllOrderRow
} from './OrderRows';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import CancelledIcon from '../../assets/icons/cancelled.svg';
import EditIcon from '../../assets/icons/edit-row.svg';
import AssignVehicle from './AssignVehicle';
import { toaster } from '../../utils/toaster';
import LogisticOrderDetails from '../orders/order-forms/LogisticOrderDetails';
import { useEffect } from 'react';
import { ORGANIZATION_TYPES } from '../../constants';
import { Label } from '../../core-components/atoms/Text';
import mixpanel from 'mixpanel-browser';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import LinearProgressBar from '../../core-components/atoms/LinerProgressBar';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../constants/colors';
import PopupButton from '../../core-components/molecules/PopupButton';
import TruckIcon from '../../assets/icons/shipment.svg';
import ShipmentIcon from '../../assets/icons/Leg.svg';
import OrderManagementMobile from './orderManagementMobile';

const generateProgress = (order, shipment, flag) => {
  let steps = [];
  let active = 1;
  if (order.moveType != 'TRANSFER') {
    steps = [
      'Created',
      ...(flag ? [order.moveType == 'INWARD' ? 'Un-Loaded' : 'Loaded'] : [])
    ];
    if (shipment.status.key == 'CANCELLED') {
      steps.push(
        shipment.cancelledBy?.id
          ? 'Cancelled by ' +
              shipment.cancelledBy.firstName +
              ' ' +
              shipment.cancelledBy.lastName
          : shipment.cancelReason
      );
    } else {
      steps.push('Completed');
    }

    if (shipment.status.key === 'COMPLETED') active = 3;
    else if (shipment.dockId) active = 2;
  } else {
    steps = [
      'Created',
      ...(flag ? ['Loaded: Origin', 'Un-Loaded: Dropoff'] : [])
    ];
    if (shipment.status.key == 'CANCELLED') {
      steps.push(
        shipment.cancelledBy?.id
          ? 'Cancelled by ' +
              shipment.cancelledBy.firstName +
              ' ' +
              shipment.cancelledBy.lastName
          : shipment.cancelReason
      );
    } else {
      steps.push('Completed');
    }

    if (shipment.status.key == 'COMPLETED') active = 4;
    else if (shipment?.dockId && !shipment.dropOffDockId) active = 2;
    else if (shipment.dropOffDockId) active = 3;
  }

  return { steps, active };
};

export const OrderRow = ({
  order,
  setShipmentPopup,
  setSelectedOrder,
  setView,
  refetch,
  setActiveShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  currentTab,
  orderIdFilter,
  companyNameFilter,
  logisticPopupcompanyId,
  logisticPopupOrderId,
  shipmentIdFilter,
  setShowCreateShipmentTMS,
  activeShipment,
  extraColumns,
  closeOrder
}) => {
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlags();
  const { setAPILoader, organizationType, organization } =
    React.useContext(SharedContext);
  const [logisticOrderViewPopUp, setLogisticOrderViewPopUp] =
    React.useState(false);
  const orderOptions = [];
  if (order.status == 'IN_PROGRESS') {
    orderOptions.push({
      label: 'Cancel Order',
      onClick: () => {
        setCancelPopUp(true, 'order');
        setActiveShipmentOrder(order);
      }
    });
  }
  if (order.status == 'PENDING') {
    orderOptions.push({
      label: 'Edit Order',
      onClick: () => {
        navigate(`/routing/edit-order/${order.id}`);
      }
    });
    orderOptions.push({
      label: 'Cancel Order',
      onClick: () => {
        setCancelPopUp(true, 'order');
        setActiveShipmentOrder(order);
      }
    });
  } else if (order.status == 'DRAFT')
    orderOptions.push({
      label: 'Edit Order',
      onClick: () => {
        navigate(`/routing/edit-order/${order.id}`);
      }
    });
  else if (
    order.status == 'PARTIALLY_COMPLETE' &&
    isFeatureEnabled(FLAGS.CLOSE_ORDER)
  ) {
    orderOptions.push({ label: 'Close Order', onClick: () => {} });
  }

  const deleteOrder = async (order) => {
    setAPILoader(true);
    try {
      await API.delete(`orders/order/${order.customId}`);
      await refetch();
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <>
      {organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER && (
        <Popup
          open={logisticOrderViewPopUp}
          setOpen={setLogisticOrderViewPopUp}
          content={
            <div className="min-w-[600px]">
              <LogisticOrderDetails
                orderId={order?.id || logisticPopupOrderId}
                companyId={order?.Company?.id || logisticPopupcompanyId}
              />
            </div>
          }
          actions={<></>}
          title={'Order Details'}
        />
      )}
      {currentTab == 0 && (
        <AllOrderRow
          order={order}
          setActiveShipment={setActiveShipment}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
          setActiveShipmentOrder={setActiveShipmentOrder}
          setCancelPopUp={setCancelPopUp}
          setAssignVehiclePopup={setAssignVehiclePopup}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setLogisticOrderViewPopUp={setLogisticOrderViewPopUp}
          setShipmentPopup={setShipmentPopup}
          closeOrder={closeOrder}
          setView={setView}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          setShowCreateShipmentTMS={setShowCreateShipmentTMS}
          organization={organization}
          activeShipment={activeShipment}
          extraColumns={extraColumns}
        />
      )}
      {currentTab == 1 && (
        <ActiveOrderRow
          order={order}
          setActiveShipment={setActiveShipment}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
          setActiveShipmentOrder={setActiveShipmentOrder}
          setCancelPopUp={setCancelPopUp}
          setAssignVehiclePopup={setAssignVehiclePopup}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setLogisticOrderViewPopUp={setLogisticOrderViewPopUp}
          setShipmentPopup={setShipmentPopup}
          closeOrder={closeOrder}
          setView={setView}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          setShowCreateShipmentTMS={setShowCreateShipmentTMS}
          organization={organization}
          activeShipment={activeShipment}
          extraColumns={extraColumns}
        />
      )}
      {currentTab == 2 && (
        <DraftOrderRow
          order={order}
          deleteOrder={deleteOrder}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          extraColumns={extraColumns}
        />
      )}
      {currentTab == 3 && (
        <CompleteOrderRow
          order={order}
          setActiveShipment={setActiveShipment}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
          setActiveShipmentOrder={setActiveShipmentOrder}
          setCancelPopUp={setCancelPopUp}
          setAssignVehiclePopup={setAssignVehiclePopup}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setLogisticOrderViewPopUp={setLogisticOrderViewPopUp}
          setShipmentPopup={setShipmentPopup}
          setView={setView}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          organization={organization}
          activeShipment={activeShipment}
          extraColumns={extraColumns}
        />
      )}
      {currentTab == 4 && (
        <ClosedOrderRow
          order={order}
          setActiveShipment={setActiveShipment}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
          setActiveShipmentOrder={setActiveShipmentOrder}
          setCancelPopUp={setCancelPopUp}
          setAssignVehiclePopup={setAssignVehiclePopup}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setLogisticOrderViewPopUp={setLogisticOrderViewPopUp}
          setShipmentPopup={setShipmentPopup}
          setView={setView}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          organization={organization}
          activeShipment={activeShipment}
          extraColumns={extraColumns}
        />
      )}
      {currentTab == 5 && (
        <CancelledOrderRow
          order={order}
          setActiveShipment={setActiveShipment}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
          setActiveShipmentOrder={setActiveShipmentOrder}
          setCancelPopUp={setCancelPopUp}
          setAssignVehiclePopup={setAssignVehiclePopup}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setLogisticOrderViewPopUp={setLogisticOrderViewPopUp}
          setShipmentPopup={setShipmentPopup}
          setView={setView}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          shipmentIdFilter={shipmentIdFilter}
          organization={organization}
          extraColumns={extraColumns}
        />
      )}
    </>
  );
};

const ShipmentActions = ({ shipment, order, refetch, navigate }) => {
  const [open, setOpen] = React.useState(false);
  const { isFeatureEnabled } = useFeatureFlags();

  const assignDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'IN_TRANSIT'
        }
      });
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      refetch();
    } catch (err) {
      toaster('error', 'Failed to assign dock');
      onError(err);
    }
  };

  const assignDropOffDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'IN_TRANSIT'
        }
      });
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dropOffDockId: dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      refetch();
    } catch (err) {
      toaster('success', 'Failed to assign dock');
      onError(err);
    }
  };

  if (order.moveType == 'INWARD') {
    if (shipment.status.key == 'CANCELLED') return null;
    return isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
      (shipment.status?.key == 'CREATED' ||
        shipment.subStatus?.key == 'VEHICLE_ASSIGNED') ? (
      <div className="w-52">
        <DockAssignment
          dockInitialValues={order}
          shipment={shipment}
          refetch={refetch}
          submitHandler={assignDock}
        />
      </div>
    ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
        isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
        shipment.status?.key == 'IN_TRANSIT') ||
      (shipment.status?.key == 'CREATED' &&
        !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ? (
      isFeatureEnabled(FLAGS.GRN_CREATION) &&
      (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) ? (
        <Button
          label="Receive Inventory"
          variant="transparent"
          size="large"
          onClick={() => {
            navigate(
              `/routing/create-grn/${order.customId}/${shipment.customId}`
            );
          }}
        />
      ) : (
        <PopupButton
          open={open}
          setOpen={setOpen}
          btn={
            <Button
              label="Receive Inventory"
              variant="transparent"
              size="large"
              onClick={() => {
                open ? setOpen(false) : setOpen(true);
              }}
            />
          }
          content={
            <List className="py-4 w-40">
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-grn/${order.customId}/${shipment.customId}`,
                    { state: 'Receive_full' }
                  );
                }}
              >
                Receive Full
              </ListItem>
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-grn/${order.customId}/${shipment.customId}`
                  );
                }}
              >
                Receive Partial
              </ListItem>
            </List>
          }
        />
      ))
    ) : shipment?.status?.key == 'COMPLETED' ? (
      <Button
        label="View GRN"
        variant="transparent"
        size="large"
        onClick={() =>
          navigate(`/routing/create-grn/${order.customId}/${shipment.customId}`)
        }
      />
    ) : null;
  }
  if (order.moveType == 'OUTBOUND') {
    if (shipment.status.key == 'CANCELLED') return null;
    return isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
      (shipment.status.key == 'CREATED' ||
        shipment.subStatus?.key == 'VEHICLE_ASSIGNED') ? (
      <div className="w-52">
        <DockAssignment
          dockInitialValues={order}
          shipment={shipment}
          refetch={refetch}
          submitHandler={assignDock}
        />
      </div>
    ) : ((shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
        shipment.status?.key == 'IN_TRANSIT' &&
        isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ||
        (!isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
          (shipment.status.key == 'CREATED' ||
            shipment.subStatus?.key == 'VEHICLE_ASSIGNED'))) &&
      order.status !== 'PICKING_IN_PROGRESS' ? (
      isFeatureEnabled(FLAGS.GDN_CREATION) &&
      (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) ? (
        <PopupButton
          open={open}
          setOpen={setOpen}
          btn={
            <Button
              label="Dispatch Inventory"
              variant="transparent"
              size="large"
              onClick={() => (open ? setOpen(false) : setOpen(true))}
            />
          }
          content={
            <List className="py-4 w-40">
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-gdn/${order.customId}/${shipment.customId}`,
                    { state: 'Dispatch_full' }
                  );
                }}
              >
                Dispatch Full
              </ListItem>
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-gdn/${order.customId}/${shipment.customId}`
                  );
                }}
              >
                Dispatch Partial
              </ListItem>
            </List>
          }
        />
      ) : (
        <Button
          className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
          onClick={() => {
            navigate(
              `/routing/create-gdn/${order.customId}/${shipment.customId}`
            );
          }}
          label="Dispatch"
        />
      ))
    ) : shipment?.status?.key == 'COMPLETED' &&
      isFeatureEnabled(FLAGS.GDN_CREATION) ? (
      <Button
        label="View GDN"
        variant="transparent"
        size="large"
        onClick={() =>
          navigate(`/routing/create-gdn/${order.customId}/${shipment.customId}`)
        }
      />
    ) : null;
  } else {
    if (shipment.status.key == 'CANCELLED') return null;
    return isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
      (shipment.status.key == 'CREATED' ||
        shipment.subStatus?.key == 'VEHICLE_ASSIGNED') ? (
      <div className="w-52">
        <DockAssignment
          dockInitialValues={order}
          shipment={shipment}
          refetch={refetch}
          submitHandler={assignDock}
        />
      </div>
    ) : ((shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
        !(shipment?.dropOffDockId || shipment?.dropOffDock) &&
        isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ||
        (!isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
          shipment.status?.key == 'CREATED')) &&
      order.status !== 'PICKING_IN_PROGRESS' ? (
      isFeatureEnabled(FLAGS.GDN_CREATION) && (
        <PopupButton
          open={open}
          setOpen={setOpen}
          btn={
            <Button
              label="Dispatch Inventory"
              variant="transparent"
              size="large"
              onClick={() => (open ? setOpen(false) : setOpen(true))}
            />
          }
          content={
            <List className="py-4 w-40">
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-gdn/${order.customId}/${shipment.customId}`,
                    { state: 'Dispatch_full' }
                  );
                }}
              >
                Dispatch Full
              </ListItem>
              <ListItem
                className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/routing/create-gdn/${order.customId}/${shipment.customId}`
                  );
                }}
              >
                Dispatch Partial
              </ListItem>
            </List>
          }
        />
      )
    ) : isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
      shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
      !(shipment?.dropOffDockId || shipment?.dropOffDock) ? (
      <div className="w-52">
        <DockAssignment
          dockInitialValues={order}
          shipment={shipment}
          refetch={refetch}
          submitHandler={assignDropOffDock}
        />
      </div>
    ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
        isFeatureEnabled(FLAGS.GRN_CREATION) &&
        (shipment?.dropOffDockId || shipment?.dropOffDock)) ||
      (shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
        !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ? (
      <Button
        label="Receive Inventory"
        variant="transparent"
        size="large"
        onClick={() =>
          navigate(`/routing/create-grn/${order.customId}/${shipment.customId}`)
        }
      />
    ) : null;
  }
};

const OrderHeader = ({ currentTab, extraColumnns, organizationType }) => {
  if (currentTab == 0) {
    return (
      <TableRow>
        <TableCell sx={{ width: '15%', padding: '16px 6px 16px 10px' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '25%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Creation Date') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Creation Date
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Status
          </Label>
        </TableCell>
        {(extraColumnns.indexOf('Order Items') !== -1 ||
          extraColumnns.indexOf('Order Value & Items') !== -1) && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {organizationType !== ORGANIZATION_TYPES.MANUFACTURER
                ? 'Order Qty'
                : 'Order Value & Qty'}
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '15%' }}></TableCell>
      </TableRow>
    );
  } else if (currentTab == 1) {
    return (
      <TableRow>
        <TableCell sx={{ padding: '16px 6px 16px 10px', width: '10%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Creation Date') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Creation Date
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Status
          </Label>
        </TableCell>

        {(extraColumnns.indexOf('Order Items') !== -1 ||
          extraColumnns.indexOf('Order Value & Items') !== -1) && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {organizationType !== ORGANIZATION_TYPES.MANUFACTURER
                ? 'Order Qty'
                : 'Order Value & Qty'}
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Shipment Status
          </Label>
        </TableCell>
        <TableCell sx={{ width: '5%' }}></TableCell>
      </TableRow>
    );
  } else if (currentTab == 2) {
    return (
      <TableRow>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'sm'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'sm'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'sm'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        {(extraColumnns.indexOf('Order Items') !== -1 ||
          extraColumnns.indexOf('Order Value & Items') !== -1) && (
          <TableCell sx={{ width: '15%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {organizationType !== ORGANIZATION_TYPES.MANUFACTURER
                ? 'Order Qty'
                : 'Order Value & Qty'}
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '25%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Creation Progress
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}></TableCell>
        <TableCell sx={{ width: '5%' }}></TableCell>
      </TableRow>
    );
  } else if (currentTab == 3) {
    return (
      <TableRow>
        <TableCell sx={{ width: '10%', paddingLeft: '6px' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        <TableCell sx={{ width: '10%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Creation Date') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Creation Date
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Status
          </Label>
        </TableCell>
        {(extraColumnns.indexOf('Order Items') !== -1 ||
          extraColumnns.indexOf('Order Value & Items') !== -1) && (
          <TableCell sx={{ width: '15%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {organizationType !== ORGANIZATION_TYPES.MANUFACTURER
                ? 'Order Qty'
                : 'Order Value & Qty'}
            </Label>
          </TableCell>
        )}

        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Shipment Status
          </Label>
        </TableCell>
        <TableCell sx={{ width: '20%' }}></TableCell>
      </TableRow>
    );
  } else if (currentTab == 4) {
    return (
      <TableRow>
        <TableCell sx={{ width: '15%', paddingLeft: '6px' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Creation Date') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Creation Date
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '10%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Closing Date
          </Label>
        </TableCell>
        {(extraColumnns.indexOf('Order Items') !== -1 ||
          extraColumnns.indexOf('Order Value & Items') !== -1) && (
          <TableCell sx={{ width: '15%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              {organizationType !== ORGANIZATION_TYPES.MANUFACTURER
                ? 'Order Qty'
                : 'Order Value & Qty'}
            </Label>
          </TableCell>
        )}

        <TableCell sx={{ width: '20%', paddingLeft: '4px' }}></TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell sx={{ width: '15%', paddingLeft: '8px' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order ID
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Origin & Destination
          </Label>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Type
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Creation Date') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Creation Date
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '15%' }}>
          <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
            Order Status
          </Label>
        </TableCell>
        {extraColumnns.indexOf('Reason Of Cancelling') !== -1 && (
          <TableCell sx={{ width: '10%' }}>
            <Label variant={'xs'} className={'text-[#4B5369] normal-case'}>
              Reason Of Cancelling
            </Label>
          </TableCell>
        )}
        <TableCell sx={{ width: '20%' }}></TableCell>
      </TableRow>
    );
  }
};

export const ExpandedRow = ({
  order,
  expanded,
  setActiveShipment,
  setActiveShipmentOrder,
  setCancelPopUp,
  refetch,
  setActiveShipmentPopup,
  setView,
  shipmentIdFilter
}) => {
  return (
    <TableRow>
      <TableCell colSpan={8} sx={{ paddingLeft: '16px', paddingRight: '32px' }}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div>
            <Table class="border-separate border-spacing-y-4 border-spacing-x-0 w-full">
              <TableBody className="w-full">
                {order.Shipments?.length > 0 &&
                  order.Shipments.map((shipment, index) => {
                    return (
                      <O360TableRow
                        shipment={shipment}
                        order={order}
                        setActiveShipment={setActiveShipment}
                        setActiveShipmentOrder={setActiveShipmentOrder}
                        setActiveShipmentPopup={setActiveShipmentPopup}
                        setCancelPopUp={setCancelPopUp}
                        setView={setView}
                        refetch={refetch}
                        id={shipment.id}
                        expanded={expanded}
                        shipmentIdFilter={shipmentIdFilter}
                        key={index}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const O360TableRow = ({
  order,
  shipment,
  setView,
  setActiveShipment,
  setActiveShipmentPopup,
  setActiveShipmentOrder,
  setCancelPopUp,
  refetch,
  expanded,
  shipmentIdFilter,
  index
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const { organization } = React.useContext(SharedContext);

  const options = [];
  if (
    shipment.status.key === 'CREATED' ||
    shipment.subStatus?.key == 'VEHICLE_ASSIGNED' ||
    (shipment.dockId &&
      !shipment.dropOffDockId &&
      shipment?.subStatus?.key !== 'LOAD_DISPATCHED' &&
      shipment.status.key != 'COMPLETED')
  ) {
    options.push(
      ...[
        {
          label: (
            <div className="flex gap-3">
              <img src={EditIcon} className="w-4" />
              <span>Edit Shipment</span>
            </div>
          ),
          onClick: () => {
            setView(false);
            setActiveShipment(shipment);
            setActiveShipmentOrder(order);
            setActiveShipmentPopup(true);
          }
        },
        ...(isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION)
          ? [
              {
                label: (
                  <div className="flex gap-3">
                    <img src={CancelledIcon} className="w-4" />
                    <span>Cancel Shipment</span>
                  </div>
                ),
                onClick: () => {
                  setCancelPopUp(true, 'shipment');
                  setActiveShipment(shipment);
                  setActiveShipmentOrder(order);
                },
                icon: CancelledIcon
              }
            ]
          : [])
      ]
    );
  }

  let cases = {
    DOCK_ASSIGNED:
      shipment?.status?.key == 'IN_TRANSIT' &&
      shipment?.subStatus?.key == 'DOCK_ASSIGNED',
    COMPLETED: shipment.status.key == 'COMPLETED',
    CANCELLED: shipment.status.key == 'CANCELLED'
  };

  const className = `mt-4 mb-4 pt-2 pb-2 w-full border
      ${
        (!cases.CANCELLED && !cases.COMPLETED) || cases.DOCK_ASSIGNED
          ? 'bg-bgCreated'
          : cases.COMPLETED
          ? 'bg-jade100'
          : 'bg-bgCancelled'
      }`;

  return (
    <TableRow
      key={index}
      sx={expanded && { '& > *': { borderBottom: 'unset' } }}
      className={className}
    >
      <TableCell sx={{ width: '30%' }} className="border-none">
        <p className="font-medium text-xs flex items-center gap-2">
          <img src={TruckIcon} />
          <span>SHIPMENT</span>
          <span
            className={`pl-1 ${
              shipmentIdFilter &&
              shipment.customId == shipmentIdFilter.customId &&
              'bg-highlight'
            }`}
          >
            {shipment.customId}
          </span>
        </p>
      </TableCell>
      <TableCell
        sx={{
          width: '40%',
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'left'
        }}
        className="border-none"
      >
        <div className="flex flex-col">
          <div className="mb-2 text-center">
            <span>
              {shipment.status?.key == 'CREATED'
                ? 'Shipment Created'
                : shipment.subStatus?.key == 'DOCK_ASSIGNED'
                ? 'Dock Assigned'
                : shipment.status?.key == 'COMPLETED'
                ? order.moveType == 'OUTBOUND'
                  ? 'Delivered'
                  : 'Received'
                : shipment.subStatus?.key == 'LOAD_DISPATCHED'
                ? 'Delivered'
                : 'Cancelled'}
            </span>
          </div>
          <div className="flex items-center w-full">
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                shipment.status?.key == 'COMPLETED'
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
            <LinearProgressBar
              variant="determinate"
              value={100}
              sx={{
                backgroundColor: 'white',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: cases.COMPLETED
                    ? JADE_700
                    : cases.CANCELLED
                    ? CRIMSON_500
                    : PRIMARY
                },
                border: `1px ${
                  cases.COMPLETED
                    ? JADE_700
                    : shipment.status.key == 'CANCELLED'
                    ? CRIMSON_500
                    : PRIMARY
                } solid`,
                borderRadius: '1000px',
                height: '2px',
                width: '100%'
              }}
            />
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                cases.COMPLETED
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
          </div>
          <div className="mt-2 text-center">
            <p className="flex gap-2 justify-center">
              <span className="text-sm">{order.pickupNode?.name || '-'}</span>
              {order.dropOffNode?.name && (
                <span className="text-[24px] leading-[50%]">&#8594;</span>
              )}
              <span className="text-sm">{order.dropOffNode?.name}</span>
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className="border-none" sx={{ width: '30%', border: 'none' }}>
        <div className="flex gap-2 items-center ml-8 justify-end">
          {shipment.status.key == 'COMPLETED' &&
          order.moveType == 'TRANSFER' ? (
            <div className="flex gap-3">
              <Button
                label="View GRN"
                variant="transparent"
                size="large"
                onClick={() =>
                  navigate(
                    `/routing/create-grn/${order.customId}/${shipment.customId}`
                  )
                }
                className="bg-completed"
              />
              {isFeatureEnabled(FLAGS.GDN_CREATION) && (
                <Button
                  label="View GDN"
                  variant="transparent"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/routing/create-gdn/${order.customId}/${shipment.customId}`
                    )
                  }
                />
              )}
            </div>
          ) : (
            <ShipmentActions
              shipment={shipment}
              order={order}
              refetch={refetch}
              navigate={navigate}
            />
          )}

          <RemoveRedEyeIcon
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setActiveShipment(shipment);
              setActiveShipmentOrder(order);
              setActiveShipmentPopup(true);
              setView(true);
            }}
          />
          {shipment.status.key !== 'CANCELLED' &&
            shipment.status.key !== 'COMPLETED' && (
              <OptionsMenu options={options} />
            )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default function OrderTable({
  orders,
  setShipmentPopup,
  setSelectedOrder,
  refetch,
  formik,
  currentTab,
  pages,
  page,
  setPage,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  setShowCreateShipmentTMS,
  extraColumns,
  closeOrder,
  onCreateOrder,
  orderCreatable,
  handleTabChange,
  orderStats,
  tabValue
}) {
  const { isFeatureEnabled } = useFeatureFlags();
  const [activeShipmentPopup, setActiveShipmentPopup] = React.useState(false);
  const [activeShipment, setActiveShipment] = React.useState(null);
  const [assignVehiclePopup, setAssignVehiclePopup] = React.useState(false);
  const [activeShipmentOrder, setActiveShipmentOrder] = React.useState(null);
  const [cancelPopup, setCancelPopUp] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [openNotificationPopup, setNotificationPopup] = React.useState(false);
  const [logisticPopupOrderId, setLogisticPopupOrderId] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const [logisticPopupcompanyId, setLogisticPopupcompanyId] =
    React.useState(null);
  const { setAPILoader, currentUser, organization } =
    React.useContext(SharedContext);
  const navigate = useNavigate();
  const updateShipment = async (values) => {
    setAPILoader(true);
    try {
      let payload = {
        ...values,
        palletize: values.pallete == 'Palletized'
      };
      await API.put(
        `orders/${activeShipmentOrder?.customId}/shipments/${activeShipment.customId}`,
        {
          shipmentPayload: {
            ...payload,
            driverName: payload.driver?.name || payload.driverName,
            driverPhone: payload.driver?.phone || payload.driverPhone
          }
        }
      );
      await refetch();
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };
  const cancelShipment = async (values) => {
    setAPILoader(true);
    try {
      await API.put(
        `orders/${activeShipmentOrder?.customId}/shipments/${activeShipment.customId}/cancel`,
        { ...values, hostUrl: window.location.href }
      );
      toaster('success', 'Shipment has been cancelled successfully');
      mixpanel.track('Shipment cancelled', {
        orderId: activeShipmentOrder.customId
      });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const cancelOrder = async (values) => {
    setAPILoader(true);
    try {
      await API.put(`orders/order/${activeShipmentOrder.customId}/cancel`, {
        ...values,
        hostUrl: window.location.href
      });
      toaster('success', 'Order has been cancelled successfully');
      mixpanel.track('Order cancelled', {
        orderId: activeShipmentOrder.customId
      });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const cancelFormik = useFormik({
    initialValues: {
      cancelReason: '',
      cancelReasonComment: '',
      type: ''
    },
    onSubmit: async (values) => {
      if (values.type === 'shipment')
        await cancelShipment({
          ...values,
          cancelledById: currentUser.id
        });
      else {
        await cancelOrder({
          ...values
        });
      }
      await refetch();
    }
  });
  const url = new URL(window.location.href);
  useEffect(() => {
    const orderIdParam = url.searchParams.get('orderId');
    const companyIdParam = url.searchParams.get('companyId');
    if (orderIdParam !== null && url.pathname === '/routing/order-management') {
      setLogisticPopupOrderId(orderIdParam);
      setLogisticPopupcompanyId(companyIdParam);
      setNotificationPopup(true);
    }
  }, url);
  const closePopup = async () => {
    setNotificationPopup(false);
    navigate('/routing/order-management');
  };

  return (
    <>
      <Popup
        open={openNotificationPopup}
        setOpen={closePopup}
        content={<LogisticOrderDetails orderId={logisticPopupOrderId} />}
        actions={<></>}
        title={'Order Details'}
      />
      {isMobile ? (
        <>
          <OrderManagementMobile
            handleTabChange={handleTabChange}
            orderCreatable={orderCreatable}
            orderStats={orderStats}
            tabValue={tabValue}
            orders={orders}
            closeOrder={closeOrder}
            onCreateOrder={onCreateOrder}
            setShipmentPopup={setShipmentPopup}
            setSelectedOrder={setSelectedOrder}
            refetch={refetch}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setActiveShipment={setActiveShipment}
            setAssignVehiclePopup={setAssignVehiclePopup}
            setCancelPopUp={(val, type) => {
              setCancelPopUp(val);
              cancelFormik.setFieldValue('type', type);
            }}
            currentTab={currentTab}
            setView={setView}
            orderIdFilter={orderIdFilter}
            companyNameFilter={companyNameFilter}
            logisticPopupcompanyId={logisticPopupcompanyId}
            logisticPopupOrderId={logisticPopupOrderId}
            shipmentIdFilter={shipmentIdFilter}
            setShowCreateShipmentTMS={setShowCreateShipmentTMS}
            activeShipment={activeShipment}
            organization={organization}
          />
        </>
      ) : (
        <>
          <TableContainer
            // component={Paper}
            elevation={0}
            sx={{
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              overflow: 'hidden',
              border: '1px solid #E7E6E6 !important',
              '& .MuiTable-root': {
                borderCollapse: 'unset'
              }
            }}
          >
            <Grid>
              <Table aria-label="collapsible table">
                <TableHead>
                  <OrderHeader
                    currentTab={currentTab}
                    extraColumnns={extraColumns}
                    organizationType={organization?.type}
                  />
                </TableHead>
                <TableBody>
                  {orders?.map((order, i) => {
                    return (
                      <OrderRow
                        key={i}
                        order={order}
                        closeOrder={closeOrder}
                        setShipmentPopup={setShipmentPopup}
                        setSelectedOrder={setSelectedOrder}
                        refetch={refetch}
                        setActiveShipmentOrder={setActiveShipmentOrder}
                        setActiveShipmentPopup={setActiveShipmentPopup}
                        setActiveShipment={setActiveShipment}
                        setAssignVehiclePopup={setAssignVehiclePopup}
                        setCancelPopUp={(val, type) => {
                          setCancelPopUp(val);
                          cancelFormik.setFieldValue('type', type);
                        }}
                        currentTab={currentTab}
                        setView={setView}
                        orderIdFilter={orderIdFilter}
                        companyNameFilter={companyNameFilter}
                        logisticPopupcompanyId={logisticPopupcompanyId}
                        logisticPopupOrderId={logisticPopupOrderId}
                        shipmentIdFilter={shipmentIdFilter}
                        setShowCreateShipmentTMS={setShowCreateShipmentTMS}
                        activeShipment={activeShipment}
                        extraColumns={extraColumns}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </TableContainer>
          <CustomPagination page={page} pages={pages} setPage={setPage} />
        </>
      )}

      <Popup
        open={activeShipmentPopup}
        setOpen={setActiveShipmentPopup}
        content={
          <div>
            <AddShipment
              order={activeShipmentOrder}
              shipment={activeShipment}
              formik={formik}
              view={view}
            />
          </div>
        }
        actions={
          view ? (
            <div className="flex gap-2">
              <Button
                label="Close"
                variant="primary"
                onClick={async () => {
                  setActiveShipmentOrder(null);
                  setActiveShipment(null);
                  setActiveShipmentPopup(false);
                }}
                size="medium"
              />
            </div>
          ) : (
            <div className="flex gap-2">
              <Button
                label="Cancel"
                variant="text"
                onClick={async () => {
                  setActiveShipmentOrder(null);
                  setActiveShipmentPopup(false);
                  setActiveShipment(null);
                }}
                size="large"
              />
              <Button
                label="Save Changes"
                variant="primary"
                onClick={async () => {
                  await updateShipment(formik.values);
                  await refetch();
                  setActiveShipmentOrder(null);
                  setActiveShipmentOrder(null);
                  setActiveShipmentPopup(false);
                }}
                size="large"
                disabled={
                  isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                    ? !formik.values.carrier?.id ||
                      !formik.values.vehicleType?.id
                    : false
                }
              />
            </div>
          )
        }
        title={
          <div className="flex items-center justify-center gap-2">
            <img src={ShipmentIcon} />
            <span>{activeShipmentOrder?.pickupNode?.name}</span>
            <div className="text-[40px] flex items-center mb-[10px]">
              &#8594;
            </div>
            <span>{activeShipmentOrder?.dropOffNode?.name}</span>
          </div>
        }
        headerClass={'border-b w-full border-b-[#E1E5F0] mb-4'}
        onClose={() => {
          setView(false);
          setActiveShipment(null);
          setActiveShipmentOrder(null);
          // formik.setValues({});
        }}
      />
      <Popup
        open={assignVehiclePopup}
        setOpen={setAssignVehiclePopup}
        content={
          <AssignVehicle
            order={activeShipmentOrder}
            shipment={activeShipment}
            formik={formik}
          />
        }
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              variant="text"
              onClick={async () => {
                setActiveShipmentOrder(null);
                setAssignVehiclePopup(false);
                setSelectedOrder(null);
              }}
              size="large"
            />
            <Button
              label="Assign"
              variant="primary"
              onClick={async () => {
                await updateShipment({
                  shipmentPayload: {
                    ...formik.values,
                    status: 'VEHICLE_ASSIGNED'
                  }
                });
                await refetch();
                setActiveShipmentOrder(null);
                setAssignVehiclePopup(false);
                setSelectedOrder(null);
              }}
              size="large"
              disabled={
                !formik.values.driverName ||
                !formik.values.driverPhone ||
                !formik.values.vehicleTypeId ||
                !formik.values.vehicleNumber
              }
            />
          </div>
        }
        title={`Add Vehicle & Driver details to Shipment ${activeShipment?.customId}`}
        sx={{ height: '400px' }}
        onClose={() => {
          setView(false);
          setActiveShipment(null);
          setActiveShipmentOrder(true);
          formik.setValues({});
        }}
      />

      <Popup
        open={cancelPopup}
        setOpen={setCancelPopUp}
        content={<CancelShipment formik={cancelFormik} />}
        actions={
          <div className="flex gap-2">
            <Button
              label={"Don't Cancel"}
              variant="text"
              onClick={async () => {
                setCancelPopUp(false);
                cancelFormik.setValues({
                  cancelReason: '',
                  cancelReasonComment: ''
                });
              }}
              size="large"
            />
            <Button
              label={
                cancelFormik.values.type == 'order'
                  ? 'Cancel Order'
                  : 'Cancel Shipment'
              }
              variant="primary"
              onClick={async () => {
                await cancelFormik.handleSubmit();
                await refetch();
                cancelFormik.setValues({
                  cancelReason: '',
                  cancelReasonComment: ''
                });
                setCancelPopUp(false);
              }}
              size="large"
              disabled={!cancelFormik.values.cancelReason}
            />
          </div>
        }
        title={
          cancelFormik.values.type == 'order' ? (
            <div>
              <p>Why are you cancelling this order</p>
              {activeShipmentOrder?.Shipments?.length > 0 && (
                <p className="text-sm font-normal">
                  {
                    activeShipmentOrder?.Shipments?.filter(
                      (s) => s.status.key !== 'COMPLETED'
                    ).length
                  }{' '}
                  active shipments against this order will be cancelled
                </p>
              )}
            </div>
          ) : (
            `Why are you cancelling shipment ${activeShipment?.customId} ?`
          )
        }
      />
    </>
  );
}

export const ExpandedRowMobile2 = ({
  order,
  expanded,
  setActiveShipment,
  setActiveShipmentOrder,
  setCancelPopUp,
  refetch,
  setActiveShipmentPopup,
  setView,
  shipmentIdFilter
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const { organization } = React.useContext(SharedContext);

  return (
    <TableRow>
      <TableCell
        colSpan={8}
        sx={{
          paddingLeft: '16px',
          paddingRight: '32px',
          '@media (max-width: 768px)': {
            paddingLeft: '8px',
            paddingRight: '8px'
          }
        }}
      >
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div>
            <Table className="border-separate border-spacing-y-4 border-spacing-x-0 w-full">
              <TableBody className="w-full">
                {order.Shipments?.length > 0 &&
                  order.Shipments.map((shipment, index) => {
                    const { steps, active } = generateProgress(
                      order,
                      shipment,
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)
                    );
                    const options = [];
                    options.push({
                      label: 'View Shipment',
                      onClick: (e) => {
                        e.stopPropagation();
                        setActiveShipment(shipment);
                        setActiveShipmentOrder(order);
                        setActiveShipmentPopup(true);
                        setView(true);
                      }
                    });
                    if (
                      shipment.status.key === 'CREATED' ||
                      shipment.subStatus?.key === 'VEHICLE_ASSIGNED' ||
                      (shipment.dockId &&
                        !shipment.dropOffDockId &&
                        shipment?.subStatus?.key !== 'LOAD_DISPATCHED' &&
                        shipment.status.key !== 'COMPLETED')
                    ) {
                      options.push(
                        {
                          label: 'Edit Shipment',
                          onClick: () => {
                            setView(false);
                            setActiveShipment(shipment);
                            setActiveShipmentOrder(order);
                            setActiveShipmentPopup(true);
                          }
                        },
                        isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION) && {
                          label: 'Cancel Shipment',
                          onClick: () => {
                            setCancelPopUp(true, 'shipment');
                            setActiveShipment(shipment);
                            setActiveShipmentOrder(order);
                          }
                        }
                      );
                    }

                    let cases = {
                      DOCK_ASSIGNED:
                        shipment?.status?.key === 'IN_TRANSIT' &&
                        shipment?.subStatus?.key === 'DOCK_ASSIGNED',
                      COMPLETED: shipment.status.key === 'COMPLETED',
                      CANCELLED: shipment.status.key === 'CANCELLED'
                    };

                    const className = `mt-4 mb-4 pt-2 pb-2 w-full border ${
                      (!cases.CANCELLED && !cases.COMPLETED) ||
                      cases.DOCK_ASSIGNED
                        ? 'bg-bgCreated'
                        : cases.COMPLETED
                        ? 'bg-jade100'
                        : 'bg-bgCancelled'
                    }`;

                    return (
                      <TableRow
                        key={index}
                        sx={expanded && { '& > *': { borderBottom: 'unset' } }}
                        className={className}
                      >
                        <TableCell
                          sx={{
                            width: { xs: '100%', sm: '30%' },
                            display: 'flex',
                            paddingLeft: { xs: '4px', sm: 'inherit' },
                            paddingRight: { xs: '4px', sm: 'inherit' },
                            textAlign: { xs: 'center', sm: 'left' }
                          }}
                          className="border-none"
                        >
                          <p className="font-medium text-xs flex flex-col items-center gap-2 text-black">
                            <img src={TruckIcon} alt="Truck Icon" />
                            <span
                              className={`pl-1 ${
                                shipmentIdFilter &&
                                shipment.customId ===
                                  shipmentIdFilter.customId &&
                                'bg-highlight'
                              }`}
                            >
                              {shipment.customId}
                            </span>
                          </p>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: { xs: '100%', sm: '40%' },
                            paddingLeft: { xs: 0, sm: 'inherit' },
                            paddingRight: { xs: 0, sm: 'inherit' },
                            textAlign: { xs: 'center', sm: 'left' }
                          }}
                          className="border-none"
                        >
                          <div className="flex flex-col">
                            <div className="mb-2 text-center text-black">
                              <span>
                                {shipment.status?.key === 'CREATED'
                                  ? 'Shipment Created'
                                  : shipment.subStatus?.key === 'DOCK_ASSIGNED'
                                  ? 'Dock Assigned'
                                  : shipment.status?.key === 'COMPLETED'
                                  ? order.moveType === 'OUTBOUND'
                                    ? 'Delivered'
                                    : 'Received'
                                  : shipment.subStatus?.key ===
                                    'LOAD_DISPATCHED'
                                  ? 'Delivered'
                                  : 'Cancelled'}
                              </span>
                            </div>
                            <div className="flex items-center w-full">
                              <div
                                className={`rounded-full h-[6px] w-[6px] ${
                                  shipment.status?.key === 'COMPLETED'
                                    ? 'bg-jade700'
                                    : cases.CANCELLED
                                    ? 'bg-crimson500'
                                    : 'bg-primary'
                                }`}
                              ></div>
                              <LinearProgressBar
                                variant="determinate"
                                value={100}
                                sx={{
                                  backgroundColor: 'white',
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: cases.COMPLETED
                                      ? JADE_700
                                      : cases.CANCELLED
                                      ? CRIMSON_500
                                      : PRIMARY
                                  },
                                  border: `1px ${
                                    cases.COMPLETED
                                      ? JADE_700
                                      : shipment.status.key === 'CANCELLED'
                                      ? CRIMSON_500
                                      : PRIMARY
                                  } solid`,
                                  borderRadius: '1000px',
                                  height: '2px',
                                  width: '100%'
                                }}
                              />
                              <div
                                className={`rounded-full h-[6px] w-[6px] ${
                                  cases.COMPLETED
                                    ? 'bg-jade700'
                                    : cases.CANCELLED
                                    ? 'bg-crimson500'
                                    : 'bg-primary'
                                }`}
                              ></div>
                            </div>
                            <div className="mt-2 text-center">
                              <p className="flex gap-2 justify-center text-black">
                                <span className="text-sm">
                                  {order.pickupNode?.name || '-'}
                                </span>
                                {order.dropOffNode?.name && (
                                  <span className="text-[24px] leading-[50%]">
                                    &#8594;
                                  </span>
                                )}
                                <span className="text-sm">
                                  {order.dropOffNode?.name}
                                </span>
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="border-none"
                          sx={{
                            width: { xs: '100%', sm: '35%' },
                            textAlign: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          <div className="flex gap-2 items-center ml-2 justify-end">
                            {shipment.status.key === 'COMPLETED' &&
                            order.moveType === 'TRANSFER' ? (
                              <OptionsMenu options={options}>
                                {isFeatureEnabled(FLAGS.GDN_CREATION) && (
                                  <Button
                                    label="View GDN"
                                    variant="transparent"
                                    size="large"
                                    onClick={() =>
                                      navigate(
                                        `/routing/create-gdn/${order.customId}/${shipment.customId}`
                                      )
                                    }
                                  />
                                )}
                              </OptionsMenu>
                            ) : (
                              ''
                            )}
                            {shipment.status.key !== 'CANCELLED' &&
                              shipment.status.key !== 'COMPLETED' && (
                                <OptionsMenu options={options} />
                              )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const formatOriginAndDropOff = (origin, dropOff) => {
  return (
    <div className="flex gap-2 flex-row">
      <p className="text-xs w-[50%]">
        <span className="font-semibold">Origin: </span> {origin}
      </p>
      <p className="text-xs w-[50%]">
        <span className="font-semibold">Dropoff: </span> {dropOff}
      </p>
    </div>
  );
};
