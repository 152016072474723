import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MdOutlineAdjust } from 'react-icons/md';
import Filter from '../../../components/Filter';
import PaginationGrid from '../../../components/PaginationGrid';
import TableHeader from '../../../components/TableHeader';
import AddOrganizationForm from './AddOrganizationForm';

import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import FilterButton from '../../../components/stats/FilterButton';
import { OASIS_PRIMARY_600 } from '../../../constants/colors';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import { toaster } from '../../../utils/toaster';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  searchInputCountry: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  active: {
    color: theme.palette.success.main
  },
  addOrganizationButton: {
    marginLeft: '0px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  }
}));

export default function OrganizationView() {
  const classes = useStyles();

  const columnsNew = [
    {
      field: 'active',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      width: 50,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Organization name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Organization Name</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.firstName}>
          <span className="table-cell-trucate">{params?.row?.name}</span>
        </Tooltip>
      )
    },
    {
      field: 'subdomain',
      headerName: 'Subdomain',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Subdomain</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.lastName}>
          <span className="table-cell-trucate">{params?.row?.subdomain}</span>
        </Tooltip>
      )
    },
    {
      field: 'address',
      headerName: 'Address',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Address</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.lastName}>
          <span className="table-cell-trucate">{params?.row?.address}</span>
        </Tooltip>
      )
    },
    {
      field: 'industry',
      headerName: 'Industry',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Industry</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.lastName}>
          <span className="table-cell-trucate">{params?.row?.industry}</span>
        </Tooltip>
      )
    },
    {
      field: 'ownerUsername',
      headerName: 'Owner username',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Owner username</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.User?.userName}>
          <span className="table-cell-trucate">
            {params?.row?.User?.userName}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'ownerEmail',
      headerName: 'Owner Email',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Owner Email</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.User?.email}>
          <span className="table-cell-trucate">{params?.row?.User?.email}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <EditIcon
              key={params}
              onClick={() => editOrganizationModal(params.row)}
            />
          </Tooltip>
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [organizations, setOrganizations] = useState([]);
  const [filterView, setFilterView] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const {
    currentPageTitle,
    setCurrentPageTitle,
    fetchOrganization,
    setAPILoader
  } = useContext(SharedContext);
  const [nameVal, setName] = useState('');

  const [addOrganizationViewOpen, setAddOrganizationViewOpen] = useState(false);
  const [clickedAlready, setClickedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setCurrentPageTitle('Organization');
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes().catch((err) => console.log(err));
  }, [nameVal]);

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = async () => {
    let colVal = {};
    if (nameVal) {
      colVal = { name: nameVal };
    }

    let filters = {
      colVal
    };
    let columns = ['name', 'subdomain', 'address', 'industry'];

    await _getOrganizations(page, filters, columns);
  };

  const editOrganizationModal = (org) => {
    setSelectedOrganization(org);
    setEdit(true);
    setAddOrganizationViewOpen(true);
  };

  const _getOrganizations = async (page, filters, columns) => {
    try {
      setMuiLoader(true);
      const organizations = await API.get(`organizations`, {
        params: { offset: page, filters, columns }
      });
      setPageCount(organizations.pages);
      setOrganizations(organizations.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
    }
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const getOrganizations = useCallback(
    debounce(async (page) => {
      let filters = {};
      await _getOrganizations(page, filters);
    }, DEBOUNCE_CONST),
    []
  );

  const getOrganizationBySubdomain = async (subdomain) => {
    let organization;
    try {
      organization = await API.get(`organizations/${subdomain}/check`);
      return organization;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getOrganizationByName = async (name) => {
    let organization;
    try {
      organization = await API.get(`organizations/${name}`);
      return organization;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const addOrganization = async (organizationData, ownerData, integrations) => {
    let success = false;
    try {
      setClickedAlready(true);
      setAPILoader(true);
      if (!selectedOrganization) {
        const data = {
          organizationData,
          ownerData,
          integrations
        };
        await API.post('organizations', data);
      } else {
        delete organizationData.name;
        delete organizationData.subdomain;
        const data = {
          organizationData,
          ownerData,
          integrations
        };
        await API.put(`organizations/${selectedOrganization.subdomain}`, data);
        await fetchOrganization();
      }
      closeAddOrganizationView();
      getOrganizations();
      toaster(
        'success',
        !selectedOrganization
          ? 'New Organization Created'
          : 'Organization updated'
      );
      success = true;
    } catch (err) {
      console.log(err.error);
      onError(err);
      success = false;
    } finally {
      setClickedAlready(false);
      setAPILoader(false);
    }
    return success;
  };

  const closeAddOrganizationView = () => {
    setAddOrganizationViewOpen(false);
    setSelectedOrganization(null);
    setEdit(false);
  };

  const addOrganizationModal = (
    <AddOrganizationForm
      open={addOrganizationViewOpen}
      addOrganization={addOrganization}
      clickedAlready={clickedAlready}
      setClickedAlready={setClickedAlready}
      handleClose={() => closeAddOrganizationView()}
      getOrganizationBySubdomain={getOrganizationBySubdomain}
      getOrganizationByName={getOrganizationByName}
      organization={selectedOrganization}
      edit={edit}
    />
  );

  useEffect(() => {
    getOrganizations(page);
  }, [page]);

  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'Organization Name',
    field: 'name',
    value: nameVal ? nameVal : '',
    setNameVal: (e) => setNameVal(setName, e)
  };

  const filterCols = [nameObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        handleClose={() => closeFilter()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const addOrganizationButton = (
    <Tooltip title="Add Country">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addOrganizationButton}
        size="small"
        onClick={() => setAddOrganizationViewOpen(true)}
      >
        ADD Organization
      </Button>
    </Tooltip>
  );
  const headerButtons = [
    addOrganizationButton,
    addOrganizationModal,
    filterModal
  ];

  // Custom toolbar
  function CustomOrganizationToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }
  // Custom toolbar
  const visibleColumns = { name: true };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={organizations}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                components={{ Toolbar: CustomOrganizationToolbar }}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
        customBtnColor={OASIS_PRIMARY_600}
      />
    </Paper>
  );
}
