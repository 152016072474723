import * as Yup from 'yup';

export const invoiceFormSchema = Yup.object().shape({
  invoiceType: Yup.string().required('Invoice type is required'),
  contractType: Yup.string().required('Contract type is required'),
  warehouseID: Yup.object().when(['invoiceType'], {
    is: (invoiceType) => invoiceType === 'Warehousing',
    then: Yup.object().shape({
      id: Yup.number().required('Warehouse is required')
    }),
    otherwise: Yup.object().shape({
      id: Yup.number()
    })
  }),
  company: Yup.object().shape({
    id: Yup.number().required('Company is required')
  }),
  invoiceStartPeriod: Yup.string().required('Invoice Date is required'),
  due: Yup.date().nullable().required('Due date is required')
});
