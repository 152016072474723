/*eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Country, City } from 'country-state-city';

import SettingsForm from './SettingsForm';
import { SharedContext } from '../../../utils/common';
import FixedLayout from '../../../core-components/molecules/FixedLayout';
import Button from '../../../core-components/atoms/Button';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import timezones from '../../../utils/timezones.json';
import { distances, sizes, volumes, weights } from './config';

const OrganizationSettings = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { organization, setAPILoader, setCurrentPageTitle, fetchOrganization } =
    useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      name: organization?.name,
      subdomain: organization?.subdomain,
      industry: organization?.industry,
      type: organization?.type,
      orgEmail: organization?.email,
      contactNumber: `+${organization?.contactNumber}`,
      address: organization?.address,
      additionalInformation: organization?.additionalInformation,
      dateFormat: organization?.GeneralSetting?.dateFormat,
      timeZone: timezones.find(
        (tz) => tz.tzCode == organization?.GeneralSetting?.timeZone
      ),
      currency: organization?.defaultCurrency,
      weight: weights.find(
        (w) => w.value == organization?.GeneralSetting?.weight
      ),
      volume: volumes.find(
        (v) => v.value == organization?.GeneralSetting?.volume
      ),
      size: sizes.find((s) => s.value == organization?.GeneralSetting?.size),
      distance: distances.find(
        (d) => organization?.GeneralSetting?.distance == d.value
      ),
      taxEnabled: organization?.GeneralSetting?.taxEnabled,
      firstName: organization?.User?.firstName,
      lastName: organization?.User?.lastName,
      email: organization?.User?.email,
      username: organization?.User?.username,
      phone: organization?.User?.phone,
      creditNumber: organization?.GeneralSetting?.creditNumber,
      enableWaseeqaNumber: organization?.enableWaseeqaNumber
    },
    enableReinitialize: true
  });

  const getCountries = async () => {
    const countriesData = Country.getAllCountries();
    if (organization?.country) {
      const country = countriesData?.find(
        (country) =>
          country.isoCode == organization?.country ||
          country.name == organization?.country
      );
      formik.setFieldValue('country', country);
    }
    setCountries(countriesData);
  };

  const getCities = async () => {
    setAPILoader(true);
    const cityData = Array.from(
      new Set(City.getCitiesOfCountry(formik.values.country?.isoCode))
    );
    if (organization?.city) {
      formik.setFieldValue(
        'city',
        cityData?.find((city) => organization?.city == city.name)
      );
    }
    setCities(cityData);
    setAPILoader(false);
  };

  const updateOrganizationSettings = async (values) => {
    setAPILoader(true);
    const payload = {
      organizationData: {
        industry: values?.industry,
        type: values?.type,
        email: values?.orgEmail,
        contactNumber: values?.contactNumber,
        address: values?.address,
        city: values?.city?.name,
        country: values?.country?.isoCode,
        defaultCurrency: values?.currency,
        additionalInformation: values?.additionalInformation,
        enableWaseeqaNumber: values?.enableWaseeqaNumber
      },
      generalSettingsData: {
        dateFormat: values?.dateFormat,
        timeZone: values?.timeZone?.tzCode,
        weight: values?.weight?.value,
        volume: values?.volume?.value,
        size: values?.size?.value,
        distance: values?.distance?.value,
        creditNumber: values.creditNumber,
      },
      ownerData: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phone: values?.phone,
        id: organization?.User?.id
      }
    };
    try {
      await API.put(`organizations/${organization?.subdomain}`, payload);
      await fetchOrganization();
      toaster('success', 'Settings Updated Successfully');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Settings');
  }, []);

  useEffect(() => {
    getCountries();
  }, [organization]);

  useEffect(() => {
    if (formik.values.country) {
      getCities();
    }
  }, [formik.values.country, organization]);

  return (
    organization && (
      <FixedLayout
        showHeader={false}
        content={
          <SettingsForm
            formik={formik}
            countries={countries}
            cities={cities}
            isContact={organization?.contactNumber}
          />
        }
        footer={
          <div className="flex justify-end mr-2 w-full">
            <Button
              variant="primary"
              label="Save Changes"
              onClick={() => updateOrganizationSettings(formik.values)}
            />
          </div>
        }
      />
    )
  );
};

export default OrganizationSettings;
