/*eslint-disable*/
import Popup from '../../../../core-components/atoms/Popup';
import Button from '../../../../core-components/atoms/Button';
import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { SharedContext } from '../../../../utils/common';
import VendorForm from './vendorForm';
import { customerSchema } from '../../../supplyChainNetwork/validationSchemas/customerSchema';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';

const AddVendorPopUp = ({
  open,
  setOpen,
  selectedVendor,
  setSelectedVendor,
  refetch
}) => {
  const { setAPILoader } = useContext(SharedContext);

  const customerFormik = useFormik({
    initialValues: {
      name: '',
      vendorCategory: {},
      companyName: '',
      businessType: {},
      address: '',
      phoneNumber: '',
      email: '',
      bankAccountInfo: '',
      taxIdentificationNumber: '',
      billingAddress: '',
      notes: '',
      sameAsBilling: false,
      isActive: true,
      supportingDocuments: [],
      paymentTermId: ''
    },
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      await submitHandler(values);
    },
    validateOnMount: true
  });

  useEffect(() => {
    if (selectedVendor && selectedVendor?.id) {
      customerFormik.setValues({
        name: selectedVendor.name,
        vendorCategory: {
          value: selectedVendor.vendorCategory?.toUpperCase(),
          label: selectedVendor.vendorCategory
        },
        companyName: selectedVendor.companyName,
        businessType: {
          value: selectedVendor.businessType?.toUpperCase(),
          label: selectedVendor.businessType
        },
        address: selectedVendor.address,
        phoneNumber: selectedVendor.phoneNumber,
        email: selectedVendor.email,
        bankAccountInfo: selectedVendor.bankAccountInfo,
        taxIdentificationNumber: selectedVendor.ntn,
        billingAddress: selectedVendor.billingAddress,
        notes: selectedVendor.notes,
        sameAsBilling: selectedVendor.billingAddress === selectedVendor.address,
        isActive: selectedVendor.isActive,
        // supportingDocuments: [],
        paymentTermId: selectedVendor.paymentTermId || null
      });
    }
  }, [selectedVendor]);

  const submitHandler = async (values) => {
    const payload = {
      name: values.name,
      vendorCategory: values.vendorCategory?.value,
      companyName: values.companyName,
      businessType: values.businessType?.value,
      address: values.address,
      phoneNumber: values.phoneNumber,
      email: values.email,
      bankAccountInfo: values.bankAccountInfo,
      ntn: values.taxIdentificationNumber,
      billingAddress: values.sameAsBilling
        ? values.address
        : values.billingAddress,
      notes: values.notes,
      sameAsBilling: values.sameAsBilling,
      isActive: values.isActive,
      supportingDocuments: values.supportingDocuments,
      paymentTermId: values.paymentTermId || null
    };
    
    const err = await customerFormik.validateForm(values);
    for (let i = 0; i < customerFormik.length; i++) {
      customerFormik.setFieldTouched(customerFormik[i], true);
    }

    if (Object.keys(err).length > 0) {
      return;
    }

    try {
      setAPILoader(true);
      if (!selectedVendor) await API.post(`vendors`, payload);
      else await API.put(`vendors/${selectedVendor.id}`, payload);
      toaster(
        'success',
        !selectedVendor
          ? 'Vendor has been created.'
          : 'Vendor updated successfully.'
      );
      await refetch();
      setOpen(false);
      customerFormik.resetForm();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = async () => {
    customerFormik.resetForm();
    customerFormik.setValues({});
    setSelectedVendor && setSelectedVendor(null);
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={
        <VendorForm
          edit={true}
          formik={customerFormik}
          vendorTypes={[
            {
              label: 'Supplies',
              value: 'SUPPLIES'
            },
            {
              label: 'Services',
              value: 'SERVICES'
            },
            {
              label: 'Contractors',
              value: 'CONTRACTORS'
            },
            {
              label: 'Other',
              value: 'OTHER'
            }
          ]}
          businessTypes={[
            {
              label: 'Individual',
              value: 'INDIVIDUAL'
            },
            {
              label: 'Company',
              value: 'COMPANY'
            },
            {
              label: 'Other',
              value: 'OTHER'
            }
          ]}
        />
      }
      title={selectedVendor ? `Edit Vendor` : `Add Vendor`}
      headerClass="px-8 pt-8 pb-4"
      titleClass="text-primaryBlue text-lg font-semibold"
      noPadding={true}
      closeIconSize={24}
      paperProps={{
        style: {
          borderRadius: '8px',
          minHeight: '793px',
          maxWidth: '980px',
          width: '980px'
        }
      }}
      dialogContentClasses={'dark-scrollbar'}
      onClose={() => {
        customerFormik.resetForm();
        setSelectedVendor && setSelectedVendor(null);
      }}
      actions={
        <div className="flex justify-end gap-2 pt-3 pb-6 px-6">
          <Button label="Cancel" onClick={handleClose} variant="text" />
          <Button
            label={selectedVendor ? 'Update Vendor' : 'Add Vendor'}
            className="rounded"
            onClick={async () => {
              await submitHandler(customerFormik.values);
            }}
            variant="primary"
            size="large"
            disabled={
              Object.keys(customerFormik.errors).length > 0 ||
              !customerFormik.values.name
            }
          />
        </div>
      }
    />
  );
};

export default AddVendorPopUp;
