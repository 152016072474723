/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import { List, ListItem } from '@mui/material';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import Button from '../../core-components/atoms/Button';
import { useNavigate, useParams } from 'react-router';
import Popup from '../../core-components/atoms/Popup';
import AddShipment from './AddShipment';
import { SharedContext, exportToCSV } from '../../utils/common';
import { constants } from './constants';
import ShipmentMap from './ShipmentMap';
import { ChevronLeft } from '@material-ui/icons';
import ShipmentIcon from '../../assets/icons/shipment.svg';
import { BLACK, CRIMSON_500, JADE_700, PRIMARY } from '../../constants/colors';
import DockAssignment from './DockAssignment';
import OptionsMenu from './Options';
import { icons } from './statusIcons';
import PartiallyCompleteIcon from '../../assets/icons/partial_complete.svg';
import OrderActivityLogs from './OrderDetails/OrderActivityLogs';
import CancelShipment from './CancelShipment';
import ViewIcon from '../../assets/icons/view.svg';
import { toaster } from '../../utils/toaster';
import CancelledIcon from '../../assets/icons/cancelled.svg';
import EditIcon from '../../assets/icons/edit-row.svg';
import AssignVehicle from './AssignVehicle';
import clsx from 'clsx';
import { decimalDisplay } from '../../utils/common';
import { Title } from '../../core-components/atoms/Text';
import OrderDetail from './OrderDetails/OrderDetail';
import Conversation from '../multiModalShipment/comments/Conversation';
import { InboundTMSCard } from './components/omsCards/InboundTMSCard';
import AddShipmentTMS from './AddShipmentTMS';
import { OutboundTMSCard } from './components/omsCards/OutboundTMSCard';
import { TransferTMSCard } from './components/omsCards/TransferTMSCard';
import OrderFiles from './OrderDetails/OrderFiles';
import { checkPermission, isOwareSuperAdmin } from '../../utils/auth';
import { LogisticTMSCard } from './components/omsCards/LogisticTMSCard';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import LinearProgressBar from '../../core-components/atoms/LinerProgressBar';
import PopupButton from '../../core-components/molecules/PopupButton';
import mixpanel from 'mixpanel-browser';
import OrderDetailMobile from './OrderDetails/OrderDetailMobile';

export const shipmentInitialValues = {
  pickupDateTime: new Date(moment()),
  dropoffDateTime: new Date(moment().add(2, 'hours')),
  logisticBy: 'CUSTOMER',
  pallete: 'Palletized',
  weight: 0,
  vehicleType: {},
  vehicleNumber: null,
  driverName: '',
  driverNumber: '',
  carrierName: '',
  vehicleTypeName: ''
};

const OrderDetails = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [value, setValue] = useState(0);
  const [tmsOpen, setTmsOpen] = useState(false);
  const [order, setOrder] = useState({});
  const [orderLogs, setOrderLogs] = useState([]);
  const [shipmentPopup, setShipmentPopup] = useState(false);
  const [mapPopup, setMapPopup] = useState(false);
  const {
    setAPILoader,
    setCurrentPageTitle,
    currentUser,
    organization,
    settings
  } = useContext(SharedContext);
  const [activeShipment, setActiveShipment] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { oid } = useParams();
  const [orderSummary, setOrderSummary] = useState({});
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [assignVehiclePopup, setAssignVehiclePopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [resetExpense, setResetExpense] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const resetOrderFiles = () => {
    setFirstLoad(true);
    setFiles([]);
  };

  const tabSelectedClass =
    'border-b-[4px] border-solid border-t-0 border-r-0 border-l-0 border-primary text-primary';
  const tabClass = 'font-semibold text-sm normal-case';

  const cancelShipment = async (values) => {
    setAPILoader(true);
    try {
      await API.put(
        `orders/${order?.customId}/shipments/${activeShipment?.customId}/cancel`,
        { ...values, hostUrl: window.location.href }
      );
      await refetch();

      toaster('success', 'Shipment cancelled successfully');
      mixpanel.track('Shipment cancelled', {
        orderId: order.customId,
        shipmentId: activeShipment?.customId
      });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...shipmentInitialValues
    },
    onSubmit: (values) => {
      addShipment(values);
    }
  });

  const cancelFormik = useFormik({
    initialValues: {
      cancelReason: '',
      cancelReasonComment: ''
    },
    onSubmit: async (values) => {
      await cancelShipment({
        ...values,
        cancelledById: currentUser.id
      });
    }
  });

  const getOrder = async () => {
    const { data } = await API.get(`orders/order/${oid}`);
    setOrder(data);
  };

  const getLogs = async () => {
    const { logs } = await API.get(`order-shipment-logs/${oid}`);
    setOrderLogs(logs);
  };

  const addShipment = async (values) => {
    try {
      const payload = {
        ...values,
        pickupDateTime: new Date(values.pickupDateTime),
        dropoffDateTime: new Date(values.dropoffDateTime)
      };
      setAPILoader(true);
      await API.post(`orders/${order.customId}/shipments`, {
        shipmentPayload: {
          ...payload
        },
        legsPayload: [],
        hostUrl: window.location.href
      });
      if (order?.status == 'PENDING') {
        await API.put(`orders/${order.customId}/status/IN_PROGRESS`);
      }
      toaster('success', 'Shipment created successfully');
      mixpanel.track('Shipment created', { orderId: order.customId });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const updateShipment = async (values) => {
    setAPILoader(true);
    try {
      let payload = {
        ...values,
        palletize: values.pallete == 'Palletized'
      };
      await API.put(
        `orders/${order?.customId}/shipments/${activeShipment?.customId}`,
        {
          shipmentPayload: {
            ...payload,
            driverName: payload.driver?.name || payload.driverName,
            driverPhone: payload.driver?.phone || payload.driverPhone
          }
        }
      );
      mixpanel.track('Shipment updated', {
        orderId: order.customId,
        shipmentId: activeShipment?.customId
      });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const navigate = useNavigate();

  const refetch = async () => {
    await getOrder();
    if (isFeatureEnabled(FLAGS.ORDER_AND_SHIPMENT_STATUS_TRACKING)) {
      await getLogs();
    }
  };

  useEffect(() => {
    getOrder();
    if (isFeatureEnabled(FLAGS.ORDER_AND_SHIPMENT_STATUS_TRACKING)) {
      getLogs();
    }
    mixpanel.track('Order Details', { orderId: oid });
  }, []);

  useEffect(() => {
    if (order?.id)
      setCurrentPageTitle(
        <div className="flex gap-2 items-center">
          <p className="flex text-sm items-center">
            <ChevronLeft
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer"
            />

            {isMobile ? order?.customId : `Order# ${order?.customId}`}
          </p>
          <div className="text-sm font-normal flex gap-2 items-center border-[#AEC1D6] border-0 border-l-2 border-solid px-2">
            <div>
              {order.status == 'PARTIALLY_COMPLETE' ? (
                <img src={PartiallyCompleteIcon} />
              ) : (
                <div className={icons[order.status]} />
              )}
            </div>
            {constants.ORDER_STATUS[order.status]}
          </div>
        </div>
      );

    const processGRNs = (grns) => {
      const summary = {};

      grns.forEach((grn) => {
        grn.GRNGroup.forEach((group) => {
          const productId = group.productId;
          const batches = group.GRNGroupBatches;
          const orderProductIndex = order.OrderProductGroups.findIndex(
            (op) => op.ProductId === productId
          );
          const conversionFactor =
            orderProductIndex !== -1 &&
            order.OrderProductGroups[orderProductIndex]?.secondaryUomId
              ? order.OrderProductGroups[
                  orderProductIndex
                ].Product?.ProductUOMs?.find(
                  (u) =>
                    u.uomId ===
                    order.OrderProductGroups[orderProductIndex].secondaryUomId
                )?.conversionFactor
              : 1;
          const existing = summary[productId] || {
            total: group.orderedQuantity,
            uom: group?.Product?.UOM?.name,
            productName: group.Product.description,
            sku: group.Product.name,
            received: 0,
            costPrice: group.costPrice,
            tax: group.tax,
            totalCost: group.costPrice * group.orderedQuantity,
            recoverable: 0,
            unsellable: 0,
            good: 0,
            secondaryUomQuantity: order.OrderProductGroups[orderProductIndex]
              .SecondaryUOM?.name
              ? order.OrderProductGroups[orderProductIndex].secondaryUomQuantity
              : group.orderedQuantity,
            secondaryUom:
              order.OrderProductGroups[orderProductIndex].SecondaryUOM?.name ||
              order.OrderProductGroups[orderProductIndex].Product
                ?.ProductUOMs?.[0]?.UOM?.name,
            conversionFactor
          };

          existing.received += group.receivedQuantity;
          existing.recoverable += batches.reduce(
            (acc, batch) => acc + batch.recoverableDamageQuantity,
            0
          );
          existing.unsellable += batches.reduce(
            (acc, batch) => acc + batch.unsellableDamageQuantity,
            0
          );
          existing.good += batches.reduce(
            (acc, batch) =>
              acc +
              (batch.receivedQuantity -
                (batch.recoverableDamageQuantity +
                  batch.unsellableDamageQuantity)),
            0
          );

          summary[productId] = existing;
        });
      });

      return summary;
    };

    const processOrderProductGroups = (orderProductGroups) => {
      const summary = {};

      orderProductGroups.forEach((group, orderProductIndex) => {
        const productId = group.ProductId;
        const conversionFactor = group?.secondaryUomId
          ? group.Product?.ProductUOMs?.find(
              (u) => u.uomId === group.secondaryUomId
            )?.conversionFactor
          : 1;
        summary[productId] = {
          total: group.orderedQuantity,
          productName: group.Product.description,
          uom: group.Product.UOM.name,
          sku: group.Product.name,
          costPrice: group.costPrice,
          tax: group.tax,
          totalCost: group.costPrice * group.orderedQuantity,
          received: 0,
          dispatched: 0,
          sellingPrice: group.sellingPrice,
          totalRevenue: group.totalRevenue,
          secondaryUomQuantity: order.OrderProductGroups[orderProductIndex]
            .SecondaryUOM?.name
            ? group.secondaryUomQuantity
            : group.orderedQuantity /
              group.Product?.ProductUOMs?.[0]?.conversionFactor,
          secondaryUom:
            order.OrderProductGroups[orderProductIndex].SecondaryUOM?.name ||
            order.OrderProductGroups[orderProductIndex].Product
              ?.ProductUOMs?.[0]?.UOM?.name,
          conversionFactor
        };
      });

      return summary;
    };

    const mergeSummaries = (summary1, summary2) => {
      return { ...summary1, ...summary2 };
    };

    const handleInward = (order) => {
      const grns = order.Shipments.flatMap((s) => s.GRNs).filter(
        (grn) => !grn.reversedAt
      );
      let summary =
        grns.length > 0
          ? processGRNs(grns)
          : processOrderProductGroups(order.OrderProductGroups);

      const allProducts = processOrderProductGroups(order.OrderProductGroups);
      summary = mergeSummaries(allProducts, summary);

      setOrderSummary(summary);
    };

    const handleTransfer = (order) => {
      const grns = order.Shipments.flatMap((s) => s.GRNs).filter(
        (grn) => !grn.reversedAt
      );
      const gdns = order.Shipments.flatMap((s) => s.GDNs).filter(
        (gdn) => !gdn.reversedAt
      );
      let summary = grns.length > 0 ? processGRNs(grns) : {};

      if (gdns.length > 0) {
        gdns.forEach((gdn) => {
          gdn.GDNGroup.forEach((group) => {
            const productId = group.productId;
            const orderProductIndex = order.OrderProductGroups.findIndex(
              (op) => op.ProductId === productId
            );
            const conversionFactor = order.OrderProductGroups[orderProductIndex]
              ?.secondaryUomId
              ? order.OrderProductGroups[
                  orderProductIndex
                ].Product?.ProductUOMs?.find(
                  (u) =>
                    u.uomId ===
                    order.OrderProductGroups[orderProductIndex].secondaryUomId
                )?.conversionFactor
              : 1;
            const existing = summary[productId] || {
              total: group.orderedQuantity,
              productName: group.Product.description,
              uom: order.OrderProductGroups[orderProductIndex].Product.UOM.name,
              sku: order.OrderProductGroups[orderProductIndex].Product.name,
              dispatched: 0,
              secondaryUomQuantity: order.OrderProductGroups[orderProductIndex]
                .SecondaryUOM?.name
                ? order.OrderProductGroups[orderProductIndex]
                    .secondaryUomQuantity
                : group.orderedQuantity,
              secondaryUom:
                order.OrderProductGroups[orderProductIndex].SecondaryUOM
                  ?.name ||
                order.OrderProductGroups[orderProductIndex].Product
                  ?.ProductUOMs?.[0]?.UOM?.name,
              conversionFactor
            };

            existing.dispatched += group.quantity;
            summary[productId] = existing;
          });
        });
      }

      order.OrderProductGroups.forEach((opg) => {
        if (!summary[opg.ProductId]) {
          const conversionFactor = opg.secondaryUomId
            ? opg.Product?.ProductUOMs?.find(
                (u) => u.uomId === opg.secondaryUomId
              )?.conversionFactor
            : 1;
          summary[opg.ProductId] = {
            total: opg.orderedQuantity,
            productName: opg.Product.description,
            uom: opg.Product.UOM.name,
            sku: opg.Product.name,
            dispatched: 0,
            received: 0,
            conversionFactor,
            secondaryUom:
              opg.SecondaryUOM?.name ||
              opg.Product?.ProductUOMs?.[0]?.UOM?.name,
            secondaryUomQuantity:
              opg.orderedQuantity /
              opg.Product?.ProductUOMs?.[0]?.conversionFactor
          };
        }
      });
      setOrderSummary(summary);
    };

    const handleLogistic = (order) => {
      const summary = processOrderProductGroups(order.OrderProductGroups);
      setOrderSummary(summary);
    };

    const handleOutbound = (order) => {
      const gdns = order.Shipments.flatMap((s) => s.GDNs).filter(
        (gdn) => !gdn.reversedAt
      );
      let summary = {};

      if (gdns.length > 0) {
        gdns.forEach((gdn) => {
          gdn.GDNGroup.forEach((group) => {
            const productId = group.productId;
            const orderProductIndex = order.OrderProductGroups.findIndex(
              (op) => op.ProductId === productId
            );
            const conversionFactor =
              orderProductIndex !== -1 &&
              order.OrderProductGroups[orderProductIndex]?.secondaryUomId
                ? order.OrderProductGroups[
                    orderProductIndex
                  ].Product?.ProductUOMs?.find(
                    (u) =>
                      u.uomId ===
                      order.OrderProductGroups[orderProductIndex].secondaryUomId
                  )?.conversionFactor
                : 1;
            const existing = summary[productId] || {
              total: group.orderedQuantity,
              productName: group.Product.description,
              uom: order.OrderProductGroups[orderProductIndex].Product.UOM.name,
              sku: order.OrderProductGroups[orderProductIndex].Product.name,
              dispatched: 0,
              sellingPrice: group.sellingPrice,
              tax: group.tax,
              totalRevenue: group.sellingPrice * group.orderedQuantity,
              secondaryUomQuantity: order.OrderProductGroups[orderProductIndex]
                .SecondaryUOM?.name
                ? order.OrderProductGroups[orderProductIndex]
                    .secondaryUomQuantity
                : group.orderedQuantity,
              secondaryUom:
                order.OrderProductGroups[orderProductIndex].SecondaryUOM
                  ?.name ||
                order.OrderProductGroups[orderProductIndex].Product
                  ?.ProductUOMs?.[0]?.UOM?.name,
              conversionFactor
            };

            existing.dispatched += group.quantity;
            summary[productId] = existing;
          });
        });
      }

      order.OrderProductGroups.forEach((opg) => {
        if (!summary[opg.ProductId]) {
          const conversionFactor = opg.secondaryUomId
            ? opg.Product?.ProductUOMs?.find(
                (u) => u.uomId === opg.secondaryUomId
              )?.conversionFactor
            : 1;
          summary[opg.ProductId] = {
            total: opg.orderedQuantity,
            productName: opg.Product.description,
            uom: opg.Product.UOM.name,
            sku: opg.Product.name,
            secondaryUom:
              opg.SecondaryUOM?.name ||
              opg.Product?.ProductUOMs?.[0]?.UOM?.name,
            secondaryUomQuantity:
              opg.orderedQuantity /
              opg.Product?.ProductUOMs?.[0]?.conversionFactor,
            dispatched: 0,
            sellingPrice: opg.sellingPrice,
            tax: opg.tax,
            totalRevenue: opg.sellingPrice * opg.orderedQuantity,
            conversionFactor
          };
        }
      });

      setOrderSummary(summary);
    };

    const handleOrderSummary = (order) => {
      if (!order?.id) return;
      switch (order.moveType) {
        case 'INWARD':
          handleInward(order);
          break;
        case 'TRANSFER':
          handleTransfer(order);
          break;
        case 'LOGISTIC':
          handleLogistic(order);
          break;
        case 'OUTBOUND':
          handleOutbound(order);
          break;
        default:
          break;
      }
    };
    handleOrderSummary(order);
  }, [order]);

  const getTotalByKey = (key) => {
    const total = Object.keys(orderSummary).reduce(
      (acc, curr) => acc + orderSummary[curr][key],
      0
    );
    return total.toFixed(3);
  };

  const assignDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'IN_TRANSIT'
        }
      });
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      await refetch();
    } catch (err) {
      toaster('error', 'Failed to assign dock');
      onError(err);
    } finally {
    }
  };

  const assignDropOffDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'IN_TRANSIT'
        }
      });
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dropOffDockId: dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      await refetch();
    } catch (err) {
      toaster('error', 'Failed to assign dock');
      onError(err);
    } finally {
    }
  };

  const updateDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, { dockId });
      await refetch();
    } catch (err) {
      onError(err);
    } finally {
    }
  };

  const updateDropOffDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        dropOffDockId: dockId
      });
      await refetch();
    } catch (err) {
      onError(err);
    } finally {
    }
  };

  const createQuickbookInvoice = async () => {
    setAPILoader(true);
    await API.post(
      `organizations/${organization.subdomain}/quickbooks/invoice`,
      {
        products: order?.OrderProductGroups?.map((op) => ({
          id: op.ProductId,
          qty: op.originalOrderedQuantity,
          amount: op.originalOrderedQuantity * op.sellingPrice,
          tax: op.tax,
          unitPrice: op.sellingPrice,
          name: op?.Product?.name,
          description: op?.Product?.description,
          qbItemId: op.Product?.integrations?.quickbook?.qbItemId
        })),
        customerId: order?.companyId,
        orderId: order?.id,
        expenses: order?.Expenses?.map((expense) => ({
          cost: expense?.cost,
          description:
            expense?.name +
            (!!expense?.shipmentId
              ? ` (Shipment ${expense.Shipment.customId})`
              : ' (Miscellaneous)'),
          isShipmentExpense: !!expense?.shipmentId
        }))
      }
    );
    await refetch();
    toaster('success', 'Invoice created successfully');
    setAPILoader(false);
  };

  const createQuickbookExpense = async () => {
    setAPILoader(true);
    await API.post(
      `organizations/${organization.subdomain}/quickbooks/expense`,
      {
        products: order?.OrderProductGroups?.map((op) => ({
          id: op.ProductId,
          qty: op.originalOrderedQuantity,
          amount: op.originalOrderedQuantity * op.costPrice || 0,
          tax: op.tax,
          unitPrice: op.costPrice || 0,
          name: op?.Product?.name,
          description: op?.Product?.description,
          qbItemId: op.Product?.integrations?.quickbook?.qbItemId
        })),
        customerId: order?.companyId,
        orderId: order?.id
      }
    );
    await refetch();
    toaster('success', 'Expense created successfully');
    setAPILoader(false);
  };

  const fetchOrderInvoice = async () => {
    var link = document.createElement('a');
    link.classList = 'd-none';
    link.style.width = 0;
    link.style.height = 0;
    link.id = 'a-';
    link.href = `https://app.sandbox.qbo.intuit.com/app/invoice?&txnId=${order?.integrations?.quickbook?.invoiceId}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const fetchOrderExpense = async () => {
    var link = document.createElement('a');
    link.classList = 'd-none';
    link.style.width = 0;
    link.style.height = 0;
    link.id = 'a-';
    link.href = `https://app.sandbox.qbo.intuit.com/app/bill?txnId=${order?.integrations?.quickbook?.expenseId}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const orderClosedOrCOmplete =
    order?.OrderProductGroups?.reduce(
      (acc, curr) => acc.quantity == 0 && curr,
      true
    ) || order.isClosed;

  const exportToExcel = () => {
    const rows = Object.keys(orderSummary).map((key) => {
      if (order.moveType == 'OUTBOUND') {
        return {
          sku: orderSummary[key].productName + '-' + orderSummary[key].sku,
          uom: orderSummary[key].uom ? orderSummary[key].uom : '0',
          total: orderSummary[key].total ? orderSummary[key].total : '0',
          dispatched: orderSummary[key].dispatched
            ? orderSummary[key].dispatched
            : '0',
          remaining: decimalDisplay(
            orderSummary[key].total - orderSummary[key].dispatched
          )
        };
      } else {
        return {
          sku: orderSummary[key].productName + '-' + orderSummary[key].sku,
          uom: orderSummary[key].uom ? orderSummary[key].uom : '0',
          total: orderSummary[key].total ? orderSummary[key].total : '0',
          dispatched: orderSummary[key].dispatched
            ? orderSummary[key].dispatched
            : '0',
          remaining: decimalDisplay(
            orderSummary[key].total - orderSummary[key].received
          )
        };
      }
    });
    let title = `Order ${order.customId} Details`;
    const header = [
      'SKU CODE & NAME',
      'UoM',
      'ORDERED QTY',
      order.moveType !== 'LOGISTIC'
        ? [
            order.moveType == 'INWARD' ? 'RECEVIED QTY' : 'DISPATCHED QTY',
            'REMAINING QTY'
          ]
        : []
    ];
    exportToCSV(header, rows, title);
  };

  const nonNegative = (number) => {
    return number < 0 ? 0 : number;
  };

  const renderActionButtons = () => (
    <div className="flex gap-3">
      {(isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) &&
        Object.keys(order).length > 0 &&
        order.status !== 'CANCELLED' &&
        (order.moveType === 'LOGISTIC' ? true : order.status !== 'COMPLETED') &&
        order.status !== 'PARTIALLY_COMPLETED_CLOSED' && (
          <Button
            label="Create Shipment"
            className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
            overrideSize={true}
            icon={<span className="text-xl mr-2">+</span>}
            labelClass="font-medium text-xs "
            variant="primary"
            onClick={() =>
              !isFeatureEnabled(FLAGS.TMS) &&
              isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) &&
              !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                ? setShipmentPopup(true)
                : setTmsOpen(true)
            }
          />
        )}
      {order.moveSubType?.toLowerCase() == 'sales' &&
        organization?.Integrations?.[0]?.accessToken &&
        order.status == 'COMPLETED' &&
        !order.integrations?.quickbook?.invoiceId && (
          <button
            onClick={createQuickbookInvoice}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            Generate Invoice
          </button>
        )}

      {order.moveSubType?.toLowerCase() == 'sales' &&
        organization?.Integrations?.[0]?.accessToken &&
        order.status == 'COMPLETED' &&
        order.integrations?.quickbook?.invoiceId && (
          <button
            variant="primary"
            onClick={fetchOrderInvoice}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            View Invoice
          </button>
        )}

      {order.moveSubType?.toLowerCase() == 'purchase' &&
        organization?.Integrations?.[0]?.accessToken &&
        order.status == 'COMPLETED' &&
        !order.integrations?.quickbook?.expenseId && (
          <button
            onClick={createQuickbookExpense}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            Generate Expense
          </button>
        )}
      {order.moveSubType?.toLowerCase() == 'purchase' &&
        organization?.Integrations?.[0]?.accessToken &&
        order.status == 'COMPLETED' &&
        order.integrations?.quickbook?.expenseId && (
          <button
            onClick={fetchOrderExpense}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            View Expense
          </button>
        )}
    </div>
  );

  return (
    <div className="flex">
      <div className={`ml-8 mt-6 w-${isMobile ? '[80%]' : '2/3'}`}>
        <div className="flex items-center justify-between border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#C1D6EC]">
          <div>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={<Title variant={'sm'}>Order Detail</Title>}
                className={`${tabClass} && ${
                  value == 0 && tabSelectedClass
                } min-w-fit p-4`}
                active={value == 0}
                value={0}
              />
              {(isOwareSuperAdmin(currentUser) ||
                checkPermission(currentUser, 'OPS_ORDER_DOCUMENT_UPLOAD')) &&
                isFeatureEnabled(FLAGS.DOCUMENT_MANAGEMENT_MODULE) && (
                  <Tab
                    label={<Title variant={'sm'}>Files</Title>}
                    className={`${tabClass} && ${
                      value == 2 && tabSelectedClass
                    } min-w-fit p-4`}
                    active={value == 2}
                    value={2}
                  />
                )}
              {isFeatureEnabled(FLAGS.ORDER_AND_SHIPMENT_STATUS_TRACKING) && (
                <Tab
                  label={<Title variant={'sm'}>Activity Log</Title>}
                  className={`${tabClass} && ${
                    value == 3 && tabSelectedClass
                  } min-w-fit p-4`}
                  active={value == 3}
                  value={3}
                />
              )}
              {!isMobile &&
                (order.moveType !== 'LOGISTIC' ||
                  isFeatureEnabled(FLAGS.TMS) ||
                  isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) && (
                  <Tab
                    label={<Title variant={'sm'}>Shipments</Title>}
                    className={`${tabClass} ${
                      value == 1 && tabSelectedClass
                    } min-w-fit p-4`}
                    active={value == 1}
                    value={1}
                  />
                )}
            </Tabs>
          </div>
          {!isMobile && renderActionButtons()}
        </div>
        <div className="mt-10">
          {value === 0 && (
            <>
              {isMobile ? (
                <OrderDetailMobile
                  order={order}
                  currentUser={currentUser}
                  orderSummary={orderSummary}
                  nonNegative={nonNegative}
                  exportToExcel={exportToExcel}
                  getTotalByKey={getTotalByKey}
                  resetExpense={resetExpense}
                  setResetExpense={setResetExpense}
                  actionButtons={renderActionButtons}
                />
              ) : (
                <OrderDetail
                  order={order}
                  currentUser={currentUser}
                  orderSummary={orderSummary}
                  nonNegative={nonNegative}
                  exportToExcel={exportToExcel}
                  getTotalByKey={getTotalByKey}
                  resetExpense={resetExpense}
                  setResetExpense={setResetExpense}
                />
              )}
            </>
          )}
          {value === 1 && (
            <div className="flex flex-col gap-6 rounded-xl">
              {order?.Shipments?.map((shipment) => {
                let cases = {
                  DOCK_ASSIGNED:
                    shipment?.status?.key == 'IN_TRANSIT' &&
                    shipment?.subStatus?.key == 'DOCK_ASSIGNED',
                  COMPLETED: shipment.status.key == 'COMPLETED',
                  CANCELLED: shipment.status.key == 'CANCELLED'
                };

                if (
                  isFeatureEnabled(FLAGS.TMS) ||
                  isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                ) {
                  if (order.moveType == 'INWARD')
                    return (
                      <InboundTMSCard
                        shipment={shipment}
                        order={order}
                        refetch={refetch}
                        setCancelPopUp={() => {
                          setCancelPopUp(true);
                          setActiveShipment(shipment);
                        }}
                      />
                    );
                  else if (order.moveType == 'OUTBOUND') {
                    return (
                      <OutboundTMSCard
                        shipment={shipment}
                        order={order}
                        refetch={refetch}
                        setCancelPopUp={() => {
                          setCancelPopUp(true);
                          setActiveShipment(shipment);
                        }}
                      />
                    );
                  } else if (order.moveType == 'TRANSFER') {
                    return (
                      <TransferTMSCard
                        shipment={shipment}
                        order={order}
                        refetch={refetch}
                        setCancelPopUp={() => {
                          setCancelPopUp(true);
                          setActiveShipment(shipment);
                        }}
                      />
                    );
                  } else {
                    return (
                      <LogisticTMSCard
                        shipment={shipment}
                        order={order}
                        refetch={refetch}
                        setCancelPopUp={() => {
                          setCancelPopUp(true);
                          setActiveShipment(shipment);
                        }}
                      />
                    );
                  }
                } else
                  return (
                    <div
                      className={clsx('my-2', {
                        'bg-bgCancelled': shipment.status.key === 'CANCELLED',
                        'bg-jade100': shipment.status.key === 'COMPLETED',
                        'bg-bgCreated border border-solid border-[#AEC1D6]':
                          shipment.status.key !== 'CANCELLED' &&
                          shipment.status.key !== 'COMPLETED'
                      })}
                    >
                      <div className={`flex justify-between p-6`}>
                        <div className="flex gap-4">
                          {/* <div className="w-[150px] h-[100px] border border-solid border-[#0B1940] relative group">
                            <ShipmentMap order={order} zoom={false} />
                            <div className="absolute inset-0 bg-[#0B1940CC] bg-opacity-40 opacity-0 group-hover:opacity-100 flex justify-center items-center">
                              <RemoveRedEye
                                style={{ color: 'white', cursor: 'pointer' }}
                                onClick={() => {
                                  setMapPopup(true);
                                  setActiveShipment(shipment);
                                }}
                              />
                            </div>
                          </div> */}
                          <div>
                            <div className="flex gap-2">
                              <img src={ShipmentIcon} />

                              <p className="text-normal font-bold">
                                {shipment.customId}
                              </p>
                            </div>
                            <p className="text-sm font-normal mt-2">
                              {moment(shipment.createdAt).format('DD/MM/YY')}{' '}
                              &#183;{' '}
                              {' BY ' +
                                shipment.createdBy.firstName +
                                ' ' +
                                shipment.createdBy.lastName}{' '}
                              {shipment.weight ? (
                                <span>
                                  {' '}
                                  &#183; {shipment.weight}{' '}
                                  {settings?.weight?.symbol}
                                </span>
                              ) : null}
                            </p>
                            {/* {!shipment.Vehicle?.id &&
                            !shipment.vehicleNumber &&
                            shipment.status.key != 'COMPLETED' &&
                            shipment.status.key != 'CANCELLED' ? (
                              <p
                                className="text-primary font-medium text-sm mt-5 cursor-pointer"
                                onClick={() => {
                                  setActiveShipment(shipment);
                                  setEdit(true);
                                }}
                              >
                                ASSIGN VEHICLE
                              </p>
                            ) : (
                              <div className="flex gap-4 items-center mt-5 text-sm">
                                {(shipment.Vehicle?.id ||
                                  shipment?.vehicleNumber) && (
                                  <div className="flex gap-2 items-center">
                                    <img src={ShipmentIcon} />{' '}
                                    {isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                                      ? shipment.Car?.id
                                        ? `${shipment.Car?.CarMake?.name}-
                                    ${shipment.Car?.CarModel?.name} (
                                    ${shipment.Car?.Category?.name})`
                                        : ''
                                      : shipment.vehicleTypeName}{' '}
                                    &#183;{' '}
                                    {shipment.Vehicle?.registrationNumber ||
                                      shipment.vehicleNumber}
                                  </div>
                                )}
                                <div>
                                  {shipment.driverName && (
                                    <div className="flex gap-2">
                                      <img src={DriverIcon} />{' '}
                                      {shipment.driverName} &#183;{' '}
                                      {shipment.driverPhone}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div>
                          <Actions
                            order={order}
                            shipment={shipment}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            refetch={refetch}
                            setCancelPopUp={setCancelPopUp}
                            setActiveShipment={setActiveShipment}
                            assignDock={assignDock}
                            updateDock={updateDock}
                            assignDropOffDock={assignDropOffDock}
                            updateDropOffDock={updateDropOffDock}
                            setEdit={setEdit}
                          />
                        </div>
                      </div>
                      <div className="flex m-4 gap-2 w-full">
                        <div className="flex flex-col w-full">
                          <div className="mb-2 text-center">
                            <span>
                              {shipment.status?.key == 'CREATED'
                                ? 'Shipment Created'
                                : shipment.subStatus?.key == 'DOCK_ASSIGNED'
                                ? 'Dock Assigned'
                                : shipment.status?.key == 'COMPLETED'
                                ? order.moveType == 'OUTBOUND'
                                  ? 'Delivered'
                                  : 'Received'
                                : shipment.subStatus?.key == 'LOAD_DISPATCHED'
                                ? 'Delivered'
                                : 'Cancelled'}
                            </span>
                          </div>
                          <div className="flex items-center w-full">
                            <div
                              className={`rounded-full h-[6px] w-[6px] ${
                                shipment.status?.key == 'COMPLETED'
                                  ? 'bg-jade700'
                                  : cases.CANCELLED
                                  ? 'bg-crimson500'
                                  : 'bg-primary'
                              }`}
                            ></div>
                            <LinearProgressBar
                              variant="determinate"
                              value={100}
                              sx={{
                                backgroundColor: 'white',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: cases.COMPLETED
                                    ? JADE_700
                                    : cases.CANCELLED
                                    ? CRIMSON_500
                                    : PRIMARY
                                },
                                border: `1px ${
                                  cases.COMPLETED
                                    ? JADE_700
                                    : shipment.status.key == 'CANCELLED'
                                    ? CRIMSON_500
                                    : PRIMARY
                                } solid`,
                                borderRadius: '1000px',
                                height: '2px',
                                width: '95%'
                              }}
                            />
                            <div
                              className={`rounded-full h-[6px] w-[6px] ${
                                cases.COMPLETED
                                  ? 'bg-jade700'
                                  : cases.CANCELLED
                                  ? 'bg-crimson500'
                                  : 'bg-primary'
                              }`}
                            ></div>
                          </div>
                          <div className="mt-2 text-center">
                            <p className="flex gap-2 justify-center">
                              <span className="text-sm">
                                {order.pickupNode?.name || '-'}
                              </span>
                              {order.dropOffNode?.name && (
                                <span className="text-[24px] leading-[50%]">
                                  &#8594;
                                </span>
                              )}
                              <span className="text-sm">
                                {order.dropOffNode?.name}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          )}
          {value === 2 && (
            <OrderFiles
              order={order}
              files={files}
              setFiles={setFiles}
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
            />
          )}
          {value === 3 &&
            isFeatureEnabled(FLAGS.ORDER_AND_SHIPMENT_STATUS_TRACKING) && (
              <OrderActivityLogs
                logs={orderLogs || []}
                moveType={order.moveType}
              />
            )}
        </div>
      </div>
      {isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && !isMobile && (
        <div className="h-screen w-[23%] fixed right-0 border border-y-0 border-solid border-[#E1E5F0]">
          <div className="px-4">
            <Conversation
              order={order}
              resetOrderFileListing={resetOrderFiles}
              setResetExpense={setResetExpense}
              refetch={async () => {
                await refetch();
                setFirstLoad(true);
              }}
              customClassEmpty={'w-[374px]'}
              setOpenExpense={setOpenExpense}
              openExpense={openExpense}
            />
          </div>
        </div>
      )}

      <AddShipmentTMS
        open={tmsOpen}
        setOpen={setTmsOpen}
        selectedOrder={order}
        refetch={refetch}
      />

      <Popup
        open={shipmentPopup}
        setOpen={setShipmentPopup}
        content={<AddShipment order={order} formik={formik} />}
        onClose={() => {
          setShipmentPopup(false);
          formik.setValues({ ...shipmentInitialValues });
        }}
        actions={
          <div className="flex gap-2">
            <Button
              label="cancel"
              variant="text"
              onClick={() => {
                setShipmentPopup(false);
                formik.setValues({ ...shipmentInitialValues });
              }}
              size="medium"
            />
            {!isMobile && isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) && (
              <Button
                label="Create Shipment"
                variant="primary"
                onClick={async () => {
                  await addShipment(formik.values);
                  await refetch();
                  formik.setValues({ ...shipmentInitialValues });
                  setShipmentPopup(false);
                }}
                size="large"
                disabled={
                  isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                    ? !formik.values.carrierName ||
                      !formik.values.vehicleTypeName
                    : false
                }
              />
            )}
          </div>
        }
        title={'Create a Shipment for this order'}
      />

      <Popup
        open={edit}
        setOpen={setEdit}
        content={
          <AddShipment
            order={order}
            shipment={activeShipment}
            formik={formik}
            view={false}
          />
        }
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              variant="text"
              onClick={async () => {
                setEdit(false);
                setActiveShipment(null);
              }}
              size="large"
            />
            <Button
              label="Save Changes"
              variant="primary"
              onClick={async () => {
                await updateShipment(formik.values);
                await refetch();
                setEdit(false);
              }}
              size="large"
              disabled={
                isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                  ? !formik.values.carrierName || !formik.values.vehicleTypeName
                  : false
              }
            />
          </div>
        }
        title={`Edit Shipment ${activeShipment?.customId}`}
      />
      <Popup
        open={mapPopup}
        setOpen={setMapPopup}
        content={
          <div className="w-[800px] h-[350px]">
            <ShipmentMap order={order} zoom={true} />
          </div>
        }
        onClose={() => {
          setMapPopup(false);
          setActiveShipment(null);
        }}
        actions={
          <div className="flex gap-2">
            <Button
              label="Close"
              variant="primary"
              onClick={async () => {
                setMapPopup(false);
              }}
              size="large"
            />
          </div>
        }
        title={`Route of Shipment ${activeShipment?.customId}`}
      />

      <Popup
        open={assignVehiclePopup}
        setOpen={setAssignVehiclePopup}
        content={
          <AssignVehicle
            order={order}
            shipment={activeShipment}
            formik={formik}
          />
        }
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              variant="text"
              size="large"
              onClick={async () => {
                setAssignVehiclePopup(false);
                setActiveShipment(null);
              }}
            />
            <Button
              label="Assign"
              variant="primary"
              onClick={async () => {
                await updateShipment({
                  shipmentPayload: {
                    ...formik.values,
                    status: 'VEHICLE_ASSIGNED'
                  }
                });
                await refetch();
                setAssignVehiclePopup(false);
                setActiveShipment(null);
              }}
              size="large"
              disabled={
                !formik.values.driverName ||
                !formik.values.driverPhone ||
                !formik.values.vehicleTypeId ||
                !formik.values.vehicleNumber
              }
            />
          </div>
        }
        title={`Add Vehicle & Driver details to Shipment ${activeShipment?.customId}`}
        sx={{ height: '400px' }}
      />

      <Popup
        open={cancelPopup}
        setOpen={setCancelPopUp}
        content={<CancelShipment formik={cancelFormik} />}
        actions={
          <div className="flex gap-2">
            <Button
              label={"Don't Cancel"}
              variant="text"
              onClick={async () => {
                setCancelPopUp(false);
              }}
              size="large"
            />
            <Button
              label={'Cancel Shipment'}
              variant="primary"
              onClick={async () => {
                await cancelFormik.handleSubmit();
                setCancelPopUp(false);
              }}
              size="large"
              disabled={!cancelFormik.values.cancelReason}
            />
          </div>
        }
        title={`Why are you cancelling shipment ${activeShipment?.customId} ?`}
      />
    </div>
  );
};

const Actions = ({
  order,
  shipment,
  anchorEl,
  setAnchorEl,
  refetch,
  setCancelPopUp,
  setActiveShipment,
  assignDock,
  updateDock,
  assignDropOffDock,
  updateDropOffDock,
  setEdit
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const options = [];

  if (order.moveType == 'TRANSFER') {
    if (
      !shipment.dropOffDockId &&
      shipment.subStatus?.key !== 'LOAD_DISPATCHED'
    ) {
      options.push({
        label: (
          <div className="flex gap-3 items-center">
            <img src={EditIcon} className="w-4 h-4 inline-block" />{' '}
            <span>Edit Shipment</span>
          </div>
        ),
        onClick: () => {
          setEdit(true);
          setActiveShipment(shipment);
        }
      });
      options.push({
        label: (
          <div className="flex gap-2 items-center">
            {' '}
            <img src={CancelledIcon} className="w-4 h-4 inline-block" />{' '}
            <span>Cancel Shipment</span>
          </div>
        ),
        onClick: () => {
          setCancelPopUp(true);
          setActiveShipment(shipment);
        }
      });
    }
  } else {
    if (shipment.status.key != 'COMPLETED') {
      options.push({
        label: (
          <div className="flex gap-3 items-center">
            <img src={EditIcon} className="w-4 h-4 inline-block" />{' '}
            <span>Edit Shipment</span>
          </div>
        ),
        onClick: () => {
          setEdit(true);
          setActiveShipment(shipment);
        }
      });
      if (isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION)) {
        options.push({
          label: (
            <div className="flex gap-2 items-center">
              {' '}
              <img src={CancelledIcon} className="w-4 h-4 inline-block" />{' '}
              <span>Cancel Shipment</span>
            </div>
          ),
          onClick: () => {
            setCancelPopUp(true);
            setActiveShipment(shipment);
          }
        });
      }
    }
  }

  if (order.moveType == 'INWARD') {
    let shipmentHasReversedGRNs =
      shipment.GRNs?.length > 0
        ? shipment.GRNs.filter((el) => el.reversedAt)
        : false;

    if (
      order.status != 'CANCELLED' ||
      order.status !== 'COMPLETED' ||
      order.status != 'PARTIALLY_COMPLETED_CLOSED'
    ) {
      return (
        <div className="flex gap-4">
          {isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
          (shipment.status?.key == 'CREATED' ||
            shipment?.subStatus?.key == 'VEHICLE_ASSIGNED') ? (
            <div className="w-52">
              <DockAssignment
                dockInitialValues={order}
                shipment={shipment}
                refetch={refetch}
                submitHandler={assignDock}
              />
            </div>
          ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
              shipment.status?.key == 'IN_TRANSIT' &&
              isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ||
            (shipment.status?.key == 'CREATED' &&
              !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ? (
            isFeatureEnabled(FLAGS.GRN_CREATION) &&
            (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) ? (
              <Button
                label="Receive Inventory"
                variant="transparent"
                size="large"
                onClick={() => {
                  navigate(
                    `/routing/create-grn/${order.customId}/${shipment.customId}`
                  );
                }}
              />
            ) : (
              <PopupButton
                open={open}
                setOpen={setOpen}
                btn={
                  <Button
                    label="Receive Inventory"
                    variant="transparent"
                    size="large"
                    onClick={() => {
                      open ? setOpen(false) : setOpen(true);
                    }}
                  />
                }
                content={
                  <List className="py-4 w-40">
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-grn/${order.customId}/${shipment.customId}`,
                          { state: 'Receive_full' }
                        );
                      }}
                    >
                      Receive Full
                    </ListItem>
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-grn/${order.customId}/${shipment.customId}`
                        );
                      }}
                    >
                      Receive Partial
                    </ListItem>
                  </List>
                }
              />
            ))
          ) : shipment?.status?.key == 'COMPLETED' &&
            isFeatureEnabled(FLAGS.GRN_CREATION) ? (
            <Button
              label="View GRN"
              variant="transparent"
              size="large"
              icon={<img src={ViewIcon} className="mr-1.5" />}
              onClick={() =>
                navigate(
                  `/routing/create-grn/${order.customId}/${shipment.customId}`
                )
              }
            />
          ) : null}
          {shipmentHasReversedGRNs && shipmentHasReversedGRNs.length > 0 ? (
            <Button
              icon={<img src={ViewIcon} className="mr-1.5" />}
              label={
                shipmentHasReversedGRNs?.length == 1
                  ? 'View Reversed GRN'
                  : `View ${shipmentHasReversedGRNs.length} Reversed GRNs`
              }
              variant="transparent"
              size="large"
              onClick={() =>
                navigate(
                  `/routing/view-reversed-entries/${order.customId}/${shipment.customId}`
                )
              }
            />
          ) : (
            shipment.status?.key != 'COMPLETED' &&
            shipment.status.key != 'CANCELLED' &&
            isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
            shipment.dockId && (
              <div className="w-52">
                <DockAssignment
                  dockInitialValues={order}
                  shipment={shipment}
                  refetch={refetch}
                  dockValue={shipment?.WarehouseDock}
                  submitHandler={updateDock}
                  edit={true}
                />
              </div>
            )
          )}

          {shipment?.status?.key != 'COMPLETED' &&
            shipment?.status?.key != 'CANCELLED' && (
              <OptionsMenu options={options} />
            )}
        </div>
      );
    } else if (order.status == 'COMPLETED') {
      return (
        <div className="flex gap-2">
          {shipment?.status?.key == 'COMPLETED' &&
          isFeatureEnabled(FLAGS.GRN_CREATION) ? (
            <Button
              label="View GRN"
              variant="transparent"
              icon={<img src={ViewIcon} className="mr-1.5" />}
              size="large"
              onClick={() =>
                navigate(
                  `/routing/create-grn/${order.customId}/${shipment.customId}`
                )
              }
            />
          ) : null}
        </div>
      );
    } else if (order.status == 'CANCELLED') {
    }
  } else if (order.moveType == 'OUTBOUND') {
    let shipmentHasReversedGDNs =
      shipment.GDNs?.length > 0
        ? shipment.GDNs.filter((el) => el.reversedAt)
        : false;

    if (
      order.status != 'CANCELLED' ||
      order.status !== 'COMPLETED' ||
      order.status != 'PARTIALLY_COMPLETED_CLOSED'
    ) {
      return (
        <div className="flex gap-2">
          {isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
          (shipment.status?.key == 'CREATED' ||
            shipment?.subStatus?.key == 'VEHICLE_ASSIGNED') ? (
            <div className="w-52">
              <DockAssignment
                dockInitialValues={order}
                shipment={shipment}
                refetch={refetch}
                submitHandler={assignDock}
              />
            </div>
          ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
              shipment.status?.key == 'IN_TRANSIT' &&
              order.status !== 'PICKING_IN_PROGRESS') ||
            (!isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
              shipment.status?.key !== 'COMPLETED') ? (
            isFeatureEnabled(FLAGS.GDN_CREATION) && (
              <PopupButton
                open={open}
                setOpen={setOpen}
                btn={
                  <Button
                    label="Dispatch Inventory"
                    variant="transparent"
                    size="large"
                    onClick={() => (open ? setOpen(false) : setOpen(true))}
                  />
                }
                content={
                  <List className="py-4 w-40">
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-gdn/${order.customId}/${shipment.customId}`,
                          { state: 'Dispatch_full' }
                        );
                      }}
                    >
                      Dispatch Full
                    </ListItem>
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-gdn/${order.customId}/${shipment.customId}`
                        );
                      }}
                    >
                      Dispatch Partial
                    </ListItem>
                  </List>
                }
              />
            )
          ) : shipment?.status?.key == 'COMPLETED' &&
            isFeatureEnabled(FLAGS.GDN_CREATION) ? (
            <Button
              label="View GDN"
              variant="transparent"
              icon={<img src={ViewIcon} className="mr-1.5" />}
              size="large"
              onClick={() =>
                navigate(
                  `/routing/create-gdn/${order.customId}/${shipment.customId}`
                )
              }
            />
          ) : null}

          {shipmentHasReversedGDNs && shipmentHasReversedGDNs.length > 0 ? (
            <Button
              icon={<img src={ViewIcon} className="mr-1.5" />}
              label={
                shipmentHasReversedGDNs?.length == 1
                  ? 'View Reversed GDN'
                  : `View ${shipmentHasReversedGDNs.length} Reversed GDNs`
              }
              variant="transparent"
              size="large"
              onClick={() =>
                navigate(
                  `/routing/view-reversed-entries/${order.customId}/${shipment.customId}`
                )
              }
            />
          ) : (
            shipment.status?.key != 'COMPLETED' &&
            shipment.status.key != 'CANCELLED' &&
            isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
            shipment.dockId && (
              <div className="w-52">
                <DockAssignment
                  dockInitialValues={order}
                  shipment={shipment}
                  refetch={refetch}
                  dockValue={shipment?.WarehouseDock}
                  submitHandler={updateDock}
                  edit={true}
                />
              </div>
            )
          )}

          {shipment?.status?.key != 'COMPLETED' &&
            shipment?.status?.key != 'CANCELLED' && (
              <OptionsMenu
                options={options}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            )}
        </div>
      );
    } else if (order.status == 'COMPLETED') {
      <div className="flex gap-2">
        {shipment?.status?.key == 'COMPLETED' &&
        isFeatureEnabled(FLAGS.GDN_CREATION) ? (
          <Button
            label="View GDN"
            icon={<img src={ViewIcon} className="mr-1.5" />}
            variant="transparent"
            size="large"
            onClick={() =>
              navigate(
                `/routing/create-gdn/${order.customId}/${shipment.customId}`
              )
            }
          />
        ) : null}
      </div>;
    } else if (order.status == 'CANCELLED') {
      <div className="flex gap-2">
        {shipment.dockId &&
        isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
          <DockAssignment
            dockInitialValues={order}
            shipment={shipment}
            refetch={refetch}
            disabled
          />
        ) : null}
      </div>;
    }
  } else if (order.moveType == 'TRANSFER') {
    let shipmentHasReversedGRNs =
      shipment.GRNs?.length > 0
        ? shipment.GRNs.filter((el) => el.reversedAt)
        : false;

    let shipmentHasReversedGDNs =
      shipment.GDNs?.length > 0
        ? shipment.GDNs.filter((el) => el.reversedAt)
        : false;
    if (
      order.status != 'CANCELLED' ||
      order.status !== 'COMPLETED' ||
      order.status != 'PARTIALLY_COMPLETED_CLOSED'
    ) {
      return (
        <div className="flex gap-2">
          {shipment.status.key == 'COMPLETED' && (
            <div className="flex gap-2">
              {isFeatureEnabled(FLAGS.GRN_CREATION) && (
                <Button
                  label="View GRN"
                  variant="transparent"
                  icon={<img src={ViewIcon} className="mr-1.5" />}
                  size="large"
                  onClick={() =>
                    navigate(
                      `/routing/create-grn/${order.customId}/${shipment.customId}`
                    )
                  }
                  className="bg-completed"
                />
              )}
              {isFeatureEnabled(FLAGS.GDN_CREATION) && (
                <Button
                  label="View GDN"
                  icon={<img src={ViewIcon} className="mr-1.5" />}
                  variant="transparent"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/routing/create-gdn/${order.customId}/${shipment.customId}`
                    )
                  }
                />
              )}
            </div>
          )}

          {isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
            shipment.status.key !== 'CANCELLED' &&
            (shipment.status.key == 'CREATED' ||
            shipment?.subStatus?.key == 'VEHICLE_ASSIGNED' ? (
              <div className="w-52">
                <DockAssignment
                  dockInitialValues={order}
                  shipment={shipment}
                  refetch={refetch}
                  submitHandler={assignDock}
                />
              </div>
            ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
                !(shipment?.dropOffDockId || shipment?.dropOffDock) &&
                order.status !== 'PICKING_IN_PROGRESS') ||
              !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
              <PopupButton
                open={open}
                setOpen={setOpen}
                btn={
                  <Button
                    label="Dispatch Inventory"
                    variant="transparent"
                    size="large"
                    onClick={() => (open ? setOpen(false) : setOpen(true))}
                  />
                }
                content={
                  <List className="py-4 w-40">
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-gdn/${order.customId}/${shipment.customId}`,
                          { state: 'Dispatch_full' }
                        );
                      }}
                    >
                      Dispatch Full
                    </ListItem>
                    <ListItem
                      className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/routing/create-gdn/${order.customId}/${shipment.customId}`
                        );
                      }}
                    >
                      Dispatch Partial
                    </ListItem>
                  </List>
                }
              />
            ) : isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
              shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
              !(shipment?.dropOffDockId || shipment?.dropOffDock) ? (
              <div className="w-52">
                <DockAssignment
                  dockInitialValues={order}
                  shipment={shipment}
                  refetch={refetch}
                  edit={false}
                  submitHandler={assignDropOffDock}
                />
              </div>
            ) : (shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
                (shipment?.dropOffDockId || shipment?.dropOffDock) &&
                isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ||
              (shipment.status?.key == 'CREATED' &&
                !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ? (
              isFeatureEnabled(FLAGS.GRN_CREATION) && (
                <Button
                  label="Receive Inventory"
                  variant="transparent"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/routing/create-grn/${order.customId}/${shipment.customId}`
                    )
                  }
                />
              )
            ) : null)}
          {!shipment.dockId ? null : shipment.status.key == 'IN_TRANSIT' &&
            !shipment.dropOffDockId &&
            shipment.subStatus?.key !== 'LOAD_DISPATCHED' &&
            isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
            <div className="w-52">
              <DockAssignment
                dockInitialValues={order}
                shipment={shipment}
                refetch={refetch}
                dockValue={shipment?.WarehouseDock}
                submitHandler={updateDock}
                edit={true}
              />
            </div>
          ) : shipment.status.key == 'IN_TRANSIT' &&
            !shipment.dropOffDockId &&
            shipment.subStatus?.key == 'LOAD_DISPATCHED' ? (
            isFeatureEnabled(FLAGS.GDN_CREATION) && (
              <Button
                label="View GDN"
                icon={<img src={ViewIcon} className="mr-1.5" />}
                variant="transparent"
                size="large"
                onClick={() =>
                  navigate(
                    `/routing/create-gdn/${order.customId}/${shipment.customId}`
                  )
                }
              />
            )
          ) : shipment.status.key == 'IN_TRANSIT' &&
            shipment.dropOffDockId &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
            isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
            <div className="w-52">
              <DockAssignment
                dockInitialValues={order}
                shipment={shipment}
                refetch={refetch}
                dockValue={shipment.dropOffDockk}
                submitHandler={updateDropOffDock}
                edit={true}
              />
            </div>
          ) : null}

          {((shipmentHasReversedGDNs && shipmentHasReversedGDNs.length > 0) ||
            (shipmentHasReversedGRNs &&
              shipmentHasReversedGRNs.length > 0)) && (
            <Button
              label={
                shipmentHasReversedGDNs?.length +
                shipmentHasReversedGRNs?.length
                  ? 'View Reversed Entry'
                  : `View ${shipmentHasReversedGDNs.length} Reversed Entries`
              }
              icon={<img src={ViewIcon} className="mr-1.5" />}
              variant="transparent"
              size="large"
              onClick={() =>
                navigate(
                  `/routing/view-reversed-entries/${order.customId}/${shipment.customId}`
                )
              }
            />
          )}

          {!shipment.dropOffDockId &&
            shipment.status.key != 'COMPLETED' &&
            shipment.status.key != 'CANCELLED' &&
            shipment.subStatus?.key !== 'LOAD_DISPATCHED' && (
              <OptionsMenu options={options} />
            )}
        </div>
      );
    } else if (order.status == 'COMPLETED') {
      <div className="flex gap-2">
        {shipment?.status?.key == 'COMPLETED' &&
        isFeatureEnabled(FLAGS.GRN_CREATION) ? (
          <Button
            label="View GRN"
            icon={<img src={ViewIcon} className="mr-1.5" />}
            variant="transparent"
            size="large"
            onClick={() =>
              navigate(
                `/routing/create-grn/${order.customId}/${shipment.customId}`
              )
            }
          />
        ) : null}
      </div>;
    } else if (order.status == 'CANCELLED') {
    }
  }

  return null;
};

export default OrderDetails;
