/*eslint-disable*/
import { useState, useContext, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import classNames from 'classnames/bind';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete } from '@mui/material';
import useStyles from '../invoiceStyles';

export default function BasicInfo(props) {
  const [constants, setConstants] = useState([]);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companySearch, setCompanySearch] = useState('');
  const [warehouseSearch, setWarehouseSearch] = useState('');
  const { setAPILoader } = useContext(SharedContext);

  const contract = classNames({
    'display-none': !props?.formik?.values?.invoiceType
  });

  const classes = useStyles();

  const getInvOrContType = async () => {
    try {
      setAPILoader(true);
      if (!props.billing) {
        const types = await API.get(`/invoices/constants`);
        setConstants(types?.data);
        const invoices = Object.values(types?.data?.TYPES);
        setInvoiceTypes(invoices);
      } else {
        setInvoiceTypes(['Logistics', 'Other Services']);
      }
    } catch (err) {
      // onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getWarehouses = async () => {
    try {
      setAPILoader(true);
      if (warehouseSearch) {
        const warehouseData = await API.get(`warehouses`, {
          params: { search: warehouseSearch }
        });
        setWarehouses(warehouseData?.data);
      } else {
        const warehouseData = await API.get(`warehouses`);
        setWarehouses(warehouseData?.data);
      }
    } catch (err) {
      // onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCompanies = async () => {
    try {
      setAPILoader(true);
      if (!props.billing) {
        if (companySearch) {
          const companyData = await API.get(`companies`, {
            params: { search: companySearch }
          });
          setCompanies(companyData?.data?.rows);
        } else {
          const companyData = await API.get(`companies`);
          setCompanies(companyData?.data?.rows);
        }
      } else {
        if (props.formik.values.invoiceType == 'Logistics') {
          if (companySearch) {
            const companyData = await API.get(`companies`, {
              params: { search: companySearch, type: 'VENDOR' }
            });
            setCompanies(companyData?.data?.rows);
          } else {
            const companyData = await API.get(`companies`, {
              params: { type: 'VENDOR' }
            });
            setCompanies(companyData?.data?.rows);
          }
        } else {
          if (companySearch) {
            const companyData = await API.get(`vendors`, {
              params: { search: companySearch }
            });
            setCompanies(companyData?.data);
          } else {
            const companyData = await API.get(`vendors`);
            setCompanies(companyData?.data);
          }
        }
      }
    } catch (err) {
      // onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const onChangeDate = (input) => {
    props?.formik.setFieldValue(
      'invoiceStartPeriod',
      input.selection.startDate
    );
    props?.formik.setFieldValue('invoiceEndPeriod', input.selection.endDate);
  };

  const getContractTypes = () => {
    if (props.billing) {
      setContractTypes(['Spot', 'Dedicated']);
    } else {
      const contracts = Object.values(
        constants[props?.formik?.values?.invoiceType?.toUpperCase()]
      );
      setContractTypes(contracts);
    }
  };

  useEffect(() => {
    if (
      props?.formik?.values?.invoiceType &&
      Object.keys(constants).length > 0 &&
      !props.billing
    ) {
      getContractTypes();
    }

    if (props.billing) {
      getContractTypes();
    }
  }, [props?.formik?.values?.invoiceType, constants]);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getInvOrContType();
  }, []);

  useEffect(() => {
    getCompanies();
  }, [companySearch, props.formik.values.invoiceType]);

  useEffect(() => {
    if (warehouseSearch) {
      getWarehouses();
    }
  }, [warehouseSearch]);

  const getError = (key) => {
    return (
      props.formik.errors &&
      props.formik.errors[key] && (
        <Typography color="error">{props.formik.errors[key]}</Typography>
      )
    );
  };

  function getDateRangeFromToday(daysRange) {
    // Get today's date
    const today = new Date();

    // Calculate the end date
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + daysRange);

    // Format the dates to ISO strings or another format you prefer
    const formatDate = (date) => date.toISOString().split('T')[0];

    return {
      startDate: formatDate(today),
      endDate: formatDate(endDate)
    };
  }

  return (
    <Grid item container className="mt-20">
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600, fontSize: '24px' }}>
          Basic Information
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <Grid item container className="mt-10" alignItems="center">
          <Grid item xs={4}>
            <Typography className={classes.fieldHeading}>
              {props.billing ? 'Bill Type' : 'Invoice Type'}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              id="invoiceType"
              className="customRadio"
              value={props?.formik?.values?.invoiceType}
              name="invoiceType"
              onChange={(e) => {
                props?.handleChange('invoiceType', e.target.value);
              }}
            >
              <Grid container>
                {invoiceTypes?.map((option) => (
                  <Grid item xs={4}>
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                      disabled={props.disableType}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
          {getError('invoiceType')}
        </Grid>

        <div className={contract}>
          <hr className="dashedLineBreak" />
          <Grid item container alignItems="center">
            <Grid item xs={4} className="margin-auto">
              <Typography className={classes.fieldHeading}>
                Contract Type
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <RadioGroup
                row
                id="contractType"
                className="customRadio"
                value={props?.formik?.values?.contractType}
                name="contractType"
                onChange={(e) =>
                  props?.handleChange('contractType', e.target.value)
                }
              >
                {contractTypes?.map((option) => (
                  <FormControlLabel
                    className="mr-10"
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </Grid>
            {getError('contractType')}
          </Grid>
        </div>

        {props.formik.values.invoiceType === 'Warehousing' && (
          <Grid item container>
            <Grid item xs={12}>
              <hr className="dashedLineBreak" />
            </Grid>
            <div></div>
            <Grid item xs={4} className="margin-auto">
              <Typography className={classes.fieldHeading}>
                Select Warehouse
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="warehouseID"
                  key="warehouseID"
                  name="warehouseID"
                  options={warehouses || []}
                  value={props?.formik?.values?.warehouseID || null}
                  getOptionLabel={(warehouse) =>
                    warehouse.businessWarehouseCode || warehouse.name
                      ? `${warehouse.name} ${
                          warehouse?.businessWarehouseCode
                            ? `- ${warehouse.businessWarehouseCode}`
                            : ''
                        }`
                      : ''
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      props?.handleChange('warehouseID', newValue);
                      setWarehouseSearch('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Warehouse"
                      variant="outlined"
                      value={warehouseSearch}
                      onChange={(e) => setWarehouseSearch(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {props.formik.errors &&
              props.formik.errors['warehouseID'] &&
              props.formik.touched['warehouseID'] && (
                <Typography color="error">
                  {props.formik.errors['warehouseID'].id}
                </Typography>
              )}
          </Grid>
        )}

        <Grid item xs={12}>
          <hr className="dashedLineBreak" />
        </Grid>
        {props?.formik.values.invoiceType && (
          <Grid item container>
            <Grid item xs={4} className="margin-auto">
              <Typography className={classes.fieldHeading}>
                {!props.billing
                  ? 'Select Customer'
                  : props.formik.values.invoiceType == 'Logistics'
                  ? 'Select Carrier'
                  : 'Select Vendor'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="company"
                  key="company"
                  name="company"
                  options={companies || []}
                  value={props?.formik?.values?.company || null}
                  getOptionLabel={(company) => company?.name || ''}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      props?.handleChange('company', newValue);
                      setCompanySearch('');
                      if (newValue.paymentTermInDays) {
                        const { startDate, endDate } = getDateRangeFromToday(
                          newValue.paymentTermInDays
                        );
                        props?.formik.setFieldValue(
                          'invoiceStartPeriod',
                          startDate
                        );
                        props?.formik.setFieldValue(
                          'invoiceEndPeriod',
                          endDate
                        );
                        props?.handleChange('due', endDate);
                      } else {
                        props?.formik.setFieldValue('invoiceStartPeriod', null);
                        props?.formik.setFieldValue('invoiceEndPeriod', null);
                        props?.handleChange('due', null);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        props.billing
                          ? props.formik.values.invoiceType == 'Logistics'
                            ? 'Carrier'
                            : 'Vendor'
                          : 'Company'
                      }
                      variant="outlined"
                      value={companySearch}
                      onChange={(e) => {
                        setCompanySearch(e.target.value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {props.formik.errors &&
              props.formik.errors['company'] &&
              props.formik.touched['company'] && (
                <Typography color="error">
                  {props.formik.errors['company'].id}
                </Typography>
              )}
          </Grid>
        )}
      </Grid>
      {props.formik.values.company?.id && (
        <Grid
          item
          xs={10}
          className="mt-10"
          style={{
            background: '#F5F7FA',
            border: '1px solid #BEBEBE',
            borderRadius: '0.5rem'
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '80px',
              padding: '1.5rem 2rem 1.5rem 2rem'
            }}
          >
            {props.billing ? (
              <div>
                <div>
                  <Typography className={classes.fieldHeading}>
                    Vendor ntn
                  </Typography>
                  <Typography>
                    {props.formik.values.company.ntn || '-'}
                  </Typography>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <div>
                    <Typography className={classes.fieldHeading}>
                      Company ntn
                    </Typography>
                    <Typography>
                      {props.formik.values.company.ntn || '-'}
                    </Typography>
                  </div>
                  <div className="mt-10">
                    <Typography className={classes.fieldHeading}>
                      Company email
                    </Typography>
                    <Typography>
                      {props.formik.values.company.email || '-'}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography className={classes.fieldHeading}>
                      Shipping Address
                    </Typography>
                    <Typography>
                      {props.formik.values.company.shippingAddress || '-'}
                    </Typography>
                  </div>
                  <div className="mt-10">
                    <Typography className={classes.fieldHeading}>
                      Billing Address
                    </Typography>
                    <Typography>
                      {props.formik.values.company.billingAddress || '-'}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </Grid>
      )}

      <Grid item xs={10} className="mt-20">
        <DetailPageTitle
          text={props.billing ? 'Billing Detail' : 'Invoice Detail'}
        />
        <Grid item container className="mt-10">
          <Grid item xs={4} className="margin-auto">
            <Typography className={classes.fieldHeading}>
              {props.billing ? 'Bill ID' : 'Invoice ID'}{' '}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth={true}
              name="invoiceID"
              id={props.billing ? 'Bill ID' : 'Invoice ID'}
              key={props.billing ? 'Bill ID' : 'Invoice ID'}
              label={props.billing ? 'Bill ID' : 'Invoice ID'}
              type="text"
              variant="outlined"
              value={props?.formik?.values?.invoiceID}
              onChange={(e) => {
                props?.handleChange('invoiceID', e.target.value);
              }}
              inputProps={{
                style: {
                  height: '100%'
                }
              }}
            />
          </Grid>
        </Grid>
        <hr className="dashedLineBreak" />
        <Grid item container className="mt-3">
          <Grid item xs={4} className="margin-auto">
            <Typography className={classes.fieldHeading}>
              {props.billing ? 'Billing Date' : 'Invoicing Date'}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth={true} variant="outlined">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={props.formik?.values?.invoiceStartPeriod}
                    onChange={(newValue) => {
                      props?.formik.setFieldValue(
                          'invoiceStartPeriod',
                          newValue
                      );
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} id="dueDate" />
                    )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {props.invoiceStartError && (
            <Typography color="error">{props.invoiceStartError}</Typography>
          )}
        </Grid>
        <hr className="dashedLineBreak" />
        <Grid item container className="mt-3">
          <Grid item xs={4} className="margin-auto">
            <Typography className={classes.fieldHeading}>
              Payment Due Date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth={true}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={props?.formik?.values?.due}
                  onChange={(newValue) => {
                    props?.handleChange('due', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} id="dueDate" />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {getError('due')}
        </Grid>
        <hr className="dashedLineBreak" />
        <Grid item container className="mt-3">
          <Grid item xs={4} className="margin-auto">
            <Typography className={classes.fieldHeading}>
              Reference ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth={true} variant="outlined">
              <TextField
                fullWidth={true}
                id="referenceID"
                label="Reference ID"
                type="text"
                variant="outlined"
                value={props?.formik?.values?.referenceID}
                onChange={(e) => {
                  props?.handleChange('referenceID', e.target.value);
                }}
                inputProps={{
                  style: {
                    padding: '1rem !important'
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
