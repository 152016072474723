/*eslint-disable*/
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  favoriteShipment,
  fetchShipment,
  fetchShipments,
  fetchTrackingDetails
} from '../../apis/dashboard/index';
import Vector from '../../assets/icons/unifiedDashboard/Vector.svg';
import ImagePreview from '../../atomicComponents/ImagePreview';
import { shipmentsTabsMapping } from '../../constants';
import Dropdown from '../../core-components/atoms/Dropdown';
import Popup from '../../core-components/atoms/Popup';
import {
  calculateETA,
  calculateEuclideanDistance,
  getDateAndTime,
  isArabCountry,
  SharedContext
} from '../../utils/common';
import {
  favouriteSort,
  getNodeCategories,
  getStatusFilters
} from './SupplyChain/utils';
import { NodeType as NodeCategory, ShipmentFilterType } from './constants';
import Button from '../../core-components/atoms/Button';
import WOTMSShipment from './shipment/WOTMSShipment';
import { Paragraph } from '../../core-components/atoms/Text';
import { makeStyles } from '@material-ui/core';
import Conversation from '../multiModalShipment/comments/Conversation';
import SearchBar from '../../atomicComponents/SearchBar';
import TMSShipment from './shipment/TMSShipment';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import { toaster } from '../../utils/toaster';
import { decimalDisplay } from '../../utils/common';

const updateFavoriteStatus = async (id, newStatus) => {
  const response = await favoriteShipment(id, newStatus);
  return response.data;
};

const useStyles = makeStyles(() => ({
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .Mui-selected': {
      borderBottom: '4px solid #0C71D7 !important',
      color: '#0C6BD7 !important',
      paddingBottom: '12px !important'
    },
    '& .MuiTab-textColorPrimary': {
      height: '48px',
      minWidth: '0px',
      borderBottom: 'none',
      color: '#4B5369',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      textTransform: 'none',
      // paddingBottom: '20px',
      padding: '16px'
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left'
    }
  },
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const NodeShipments = ({
  open,
  setOpen,
  title,
  shipments,
  filterType,
  setNodeShipmentData = () => { },
  startDate,
  endDate,
  currentNodeId,
  dayShipments,
  nodes,
  selectedNode,
  setSelectedNode,
  status,
  initialNodeType,
  isFetchingInitial,
  shipmentsCount = 0,
  setShipmentsCount,
  page,
  setPage,
  pageCount,
  setPageCount,
  searchKeyword,
  setSearchKeyword
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [shipmentDetailPopup, setShipmentDetailPopup] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [trackingData, setTrackingData] = useState([]);
  const { mutate } = useMutation(updateFavoriteStatus);
  const [nodeType, setNodeType] = useState(initialNodeType || 'ALL_NODES');
  const [statusFilter, setStatusFilter] = useState('SHOW_ALL');
  const [trackingLoading, setTrackingLoading] = useState(true);
  const [distance, setDistance] = useState(0)
  const { organization } = useContext(SharedContext);

  const [tabValue, setTabValue] = useState(shipmentsTabsMapping.TRACKING);
  const [openExpense, setOpenExpense] = useState(false);
  const [background, setBackground] = useState('');

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  const classes = useStyles();

  useEffect(() => {
    setShipmentDetailPopup(false);
    setShipmentData([]);
    setTrackingData([]);
    setNodeType(initialNodeType || 'ALL_NODES');
    setStatusFilter('SHOW_ALL');
    setTrackingLoading(true);
    setTabValue(shipmentsTabsMapping.TRACKING);
    setPage(1);
    setPageCount(1);
  }, [open]);

  const valueClass =
    'text-xs font-medium font-roboto text-primaryBlue truncate';
  const labelClass =
    'w-100 flex flex-row h-[24px] text-[10px] font-roboto font-medium text-stormGray flex items-center';

  function calculateTimeDifference(dropoffDateTime) {
    const dateTime1 = new Date(dropoffDateTime);
    const currentDateTime = new Date();
    const timeDifferenceMillis = Math.abs(dateTime1 - currentDateTime);
    const hours = Math.floor(timeDifferenceMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceMillis % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { hours, minutes };
  }

  const ShipmentDetails = ({ shipmentData }) => {
    let cases =
      isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
        ? {
          IN_TRANSIT: shipmentData?.status?.key === 'IN_TRANSIT',
          AWAITING_CARRIER:
            shipmentData?.ShipmentLegs?.[0]?.status === 'AWAITING_CARRIER',
          CARRIER_ASSIGNED:
            shipmentData?.ShipmentLegs?.[0]?.status === 'CARRIER_ASSIGNED',
          RECEIVED:
            shipmentData.status?.key === 'COMPLETED' &&
            (shipmentData.Order?.moveType === 'INWARD' ||
              shipmentData.Order?.moveType === 'TRANSFER'),
          DELIVERED:
            shipmentData.status?.key === 'COMPLETED' &&
            shipmentData.Order?.moveType === 'OUTBOUND',
          CANCELLED: shipmentData.status?.key === 'CANCELLED'
        }
        : {
          CREATED: shipmentData.status?.key === 'CREATED',
          DOCK_ASSIGNED:
            shipmentData.status?.key === 'IN_TRANSIT' &&
            shipmentData.subStatus?.key === 'DOCK_ASSIGNED',
          RECEIVED:
            shipmentData.status?.key === 'COMPLETED' &&
            (shipmentData.Order?.moveType === 'INWARD' ||
              shipmentData.Order?.moveType === 'TRANSFER'),
          DELIVERED:
            shipmentData.status?.key === 'COMPLETED' &&
            shipmentData.Order?.moveType === 'OUTBOUND',
          CANCELLED: shipmentData.status?.key === 'CANCELLED'
        };

    const classNameX = `
                        ${!cases.COMPLETED &&
        !cases.CANCELLED &&
        !cases.ARRIVED_AT_DESTINATION
        ? 'bg-bgCreated'
        : cases.COMPLETED || cases.ARRIVED_AT_DESTINATION
          ? 'bg-jade100'
          : 'bg-bgCancelled'
      }`;
    setBackground(classNameX);

    const calculateDistance = async () => {
      if (pickupLatLng && order?.dropOffNode?.locationLatLng) {
        const distance = await calculateEuclideanDistance(
          shipmentData?.Order?.pickupNode?.locationLatLng,
          shipmentData?.Order?.dropOffNode?.locationLatLng
        )
        setDistance(distance)
      }
      else {
        setDistance(0)
      }
    }

    useEffect(() => { calculateDistance() }, [shipmentData])

    return (
      <TabContext value={tabValue} className="h-[590px] p-0">
        {trackingLoading ? (
          <div className="w-100 h-full flex items-center justify-center transition-all">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              className={clsx('pt-2', classNameX, classes.tabContainer)}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <TabList onChange={handleChange}>
                <Tab
                  className="capitalize p-4"
                  label={<span>{`Tracking`}</span>}
                  value={shipmentsTabsMapping.TRACKING}
                />
                {isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && (
                  <Tab
                    className="capitalize p-4"
                    label={<span>{`Conversation`}</span>}
                    value={shipmentsTabsMapping.COMMENTS}
                  />
                )}
                <Tab
                  className="capitalize p-4"
                  label={<span>{`Details`}</span>}
                  value={shipmentsTabsMapping.DETAILS}
                />
              </TabList>
            </Box>
            <TabPanel
              className="w-full pt-8 h-[490px] overflow-x-scroll dark-scrollbar"
              value="1"
            >
              <div>
                {trackingData.map((log) => {
                  return (
                    <>
                      <div className="flex flex-row items-center font-roboto text-xs font-normal">
                        <div
                          className={clsx(
                            'w-[6px] h-[6px] rounded-full',
                            log.id && 'bg-primaryBlue',
                            !log.id && 'bg-graySlate'
                          )}
                        ></div>
                        <div className="text-xs font-normal text-graySlate pl-4">
                          {!log.id &&
                            (log.action === 'GDN_CREATE' ||
                              log.action === 'GRN_CREATE') ? (
                            <>
                              ETA{' '}
                              {`${getDateAndTime(shipmentData?.dropoffDateTime)
                                .date
                                }`}
                              <span className="mx-1 text-stormGray">•</span>
                              {`${getDateAndTime(shipmentData?.dropoffDateTime)
                                .time
                                }`}
                            </>
                          ) : log.id ? (
                            <>
                              {`${getDateAndTime(log?.createdAt).date}`}
                              <span className="mx-1 text-stormGray">•</span>
                              {`${getDateAndTime(log?.createdAt).time}`}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="mt-[3px]">
                        <div
                          className={clsx(
                            log.action !== 'ORDER_CREATED' && !log.id
                              ? 'border-l border-b-0 border-t-0 border-r-0 border-dashed border-graySlate'
                              : log.id && log.action !== 'ORDER_CREATED'
                                ? 'border-solid border-l border-b-0 border-t-0 border-r-0 border-primaryBlue'
                                : 'border-0',
                            'mt-[-4px] ml-[2.5px] pl-5 min-h-[45px] pb-[15px]'
                          )}
                        >
                          {!log.id ? (
                            <p className="text-sm text-graySlate">
                              {log.message}
                            </p>
                          ) : (
                            <p className="text-sm text-primaryBlue">
                              {<span className="font-medium">{log.title}</span>}{' '}
                              {log.message}
                              {log.subMessage && (
                                <span className="text-xs font-normal text-stormGray">
                                  <br></br>
                                  {log.subMessage}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </TabPanel>
            {isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && (
              <TabPanel className="w-full h-[490px] p-0 m-0" value="2">
                <Conversation
                  order={shipmentData?.Order}
                  shipment={shipmentData?.id}
                  className={'h-[490px] max-h-[490px]'}
                  inputClassName={'mb-2 mx-1'}
                  customClassText={'w-[380px]'}
                  customClassEmpty={'w-[445px]'}
                  selectedSearch={''}
                  resetOrderFileListing={() => { }}
                  openExpense={openExpense}
                  setOpenExpense={setOpenExpense}
                />
              </TabPanel>
            )}
            <TabPanel
              className="w-full h-[490px] pt-8 pr-[38px] overflow-x-scroll dark-scrollbar"
              value="3"
            >
              <div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>ORDER TYPE</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.Order?.moveSubType
                        ? shipmentData?.Order?.moveSubType?.replace('_', ' ')
                        : shipmentData?.Order?.moveType}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CUSTOMER</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.Order?.Company?.name}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CREATED BY</p>
                    <p
                      className={`${valueClass}`}
                    >{`${shipmentData?.createdBy?.firstName} ${shipmentData?.createdBy?.lastName}`}</p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CREATED ON</p>
                    <p className={`${valueClass}`}>{`${getDateAndTime(shipmentData?.createdAt).date
                      }`}</p>
                  </div>
                </div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>DISTANCE</p>
                    <p className={`${valueClass}`}>
                      {decimalDisplay(
                        distance
                      )}{' '}
                      km
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>ETA</p>
                    <p className={`${valueClass}`}>
                      {isFeatureEnabled(FLAGS.TMS) ||
                        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ? `${calculateETA(
                          distance,
                          shipmentData?.ShipmentLegs?.[0]?.Vehicle?.avgSpeed,
                          shipmentData?.Order?.expectedDelivery,
                          shipmentData?.status?.key
                        )}`
                        : `${calculateTimeDifference(
                          shipmentData?.dropoffDateTime,
                          shipmentData?.status?.key
                        ).hours
                        }h ${calculateTimeDifference(
                          shipmentData?.dropoffDateTime,
                          shipmentData?.status?.key
                        ).minutes
                        }min`}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>ORIGIN & DESTINATION</p>
                    <p className={`${valueClass}`}>{`${shipmentData?.Order?.pickupNode?.name ||
                      shipmentData?.Order?.warehousePickUp?.name ||
                      '-'
                      } -> ${shipmentData?.Order?.warehouseDropOff?.name ||
                      shipmentData?.Order?.dropOffNode?.name ||
                      '-'
                      }`}</p>
                  </div>
                </div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CARRIER</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.carrierName
                        ? shipmentData?.carrierName
                        : shipmentData?.ShipmentLegs?.[0]?.Company?.name || '-'}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>VEHICLE TYPE</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.vehicleType
                        ? shipmentData?.vehicleType
                        : shipmentData?.ShipmentLegs?.[0]?.Car.Category?.name ||
                        '-'}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>VEHICLE NO</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.vehicleNumber
                        ? shipmentData?.vehicleNumber
                        : shipmentData?.ShipmentLegs?.[0]?.Vehicle
                          ?.registrationNumber || '-'}
                    </p>
                  </div>
                </div>
                <div className="flex mb-12 border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>DRIVER NAME</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.driverName
                        ? shipmentData?.driverName
                        : shipmentData?.ShipmentLegs?.[0]?.Driver?.name || '-'}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>
                      {isArabCountry(organization) ? 'IQAMA NUMBER' : 'TIN'}
                    </p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.driverCnic
                        ? shipmentData?.driverCnic
                        : shipmentData?.ShipmentLegs?.[0]?.Driver?.cnicNumber ||
                        '-'}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>CONTACT NUMBER</p>
                    <p className={`${valueClass}`}>
                      {shipmentData?.driverPhone
                        ? shipmentData?.driverPhone
                        : shipmentData?.ShipmentLegs?.[0]?.Driver?.phone || '-'}
                    </p>
                  </div>
                </div>
                {shipmentData?.Order?.files?.length > 0 && (
                  <div className="w-100">
                    <p className="w-100 text-deepCove font-roboto font-medium">
                      Proof of document
                    </p>
                    <div className="w-100 flex flex-row">
                      {shipmentData?.Order?.files?.map((file) => (
                        <div className="w-[88px] h-[88px] mr-2" key={file.id}>
                          <ImagePreview file={file} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {shipmentData?.Order?.memo && (
                  <div className="w-[400px] h-[27px] mt-12">
                    <p className="w-[400px] text-deepCove font-roboto font-medium">
                      Additional Information
                    </p>
                    <div className="w-100">
                      {shipmentData?.Order?.memo || '-'}
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          </>
        )}
      </TabContext>
    );
  };

  function Title({ shipmentData }) {
    let cases =
      isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
        ? {
          IN_TRANSIT: shipmentData?.status?.key === 'IN_TRANSIT',
          CANCELLED: shipmentData.status?.key === 'CANCELLED',
          AWAITING_CARRIER:
            shipmentData?.ShipmentLegs?.[0]?.status === 'AWAITING_CARRIER',
          CARRIER_ASSIGNED:
            shipmentData?.ShipmentLegs?.[0]?.status === 'CARRIER_ASSIGNED',
          RECEIVED:
            shipmentData.status?.key === 'COMPLETED' &&
            (shipmentData.Order?.moveType === 'INWARD' ||
              shipmentData.Order?.moveType === 'TRANSFER'),
          DELIVERED:
            shipmentData.status?.key === 'COMPLETED' &&
            shipmentData.Order?.moveType === 'OUTBOUND'
        }
        : {
          CREATED: shipmentData.status?.key === 'CREATED',
          DOCK_ASSIGNED:
            shipmentData.status?.key === 'IN_TRANSIT' &&
            shipmentData.subStatus?.key === 'DOCK_ASSIGNED',
          RECEIVED:
            shipmentData.status?.key === 'COMPLETED' &&
            (shipmentData.Order?.moveType === 'INWARD' ||
              shipmentData.Order?.moveType === 'TRANSFER'),
          DELIVERED:
            shipmentData.status?.key === 'COMPLETED' &&
            shipmentData.Order?.moveType === 'OUTBOUND',
          CANCELLED: shipmentData.status?.key === 'CANCELLED'
        };

    return (
      <div className="flex flex-row items-center">
        <img
          className="w-[7px] h-[14px] mr-3 cursor-pointer"
          src={Vector}
          alt="Vector Sign"
          onClick={() => {
            setShipmentDetailPopup(false);
          }}
        />
        <p className="text-deepCove text-xl font-semibold">{`${shipmentData?.customId}`}</p>
        {isFeatureEnabled(FLAGS.TMS) ||
          isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          <div
            className={clsx(
              `flex items-center justify-center border rounded-[5px] ml-4 px-2 py-1 w-auto h-[25px]`,
              {
                'bg-blueEyes ':
                  cases.AWAITING_CARRIER ||
                  cases.CARRIER_ASSIGNED ||
                  cases.IN_TRANSIT,
                'bg-crimson500': cases.CANCELLED,
                'bg-jade500 ': cases.RECEIVED || cases.DELIVERED
              }
            )}
          >
            <span className={`text-[10px] text-white font-medium`}>
              {cases.CANCELLED
                ? 'Cancelled'
                : cases.IN_TRANSIT
                  ? 'In Transit'
                  : cases.AWAITING_CARRIER
                    ? 'Awaiting Carrier'
                    : cases.CARRIER_ASSIGNED
                      ? 'Carrier Assigned'
                      : cases.RECEIVED
                        ? 'Received'
                        : cases.DELIVERED && 'Delivered'}
            </span>
          </div>
        ) : (
          <div
            className={clsx(
              `flex items-center justify-center border rounded-[5px] ml-4 px-2 py-1 w-auto h-[25px]`,
              {
                'bg-blueEyes ': cases.CREATED,
                'bg-oasisPrimary500 ': cases.DOCK_ASSIGNED,
                'bg-crimson500': cases.CANCELLED,
                'bg-jade500 ': cases.RECEIVED || cases.DELIVERED
              }
            )}
          >
            <span className={`text-[10px] text-white font-medium`}>
              {cases.DOCK_ASSIGNED
                ? 'Dock Assigned'
                : cases.CREATED
                  ? 'Created'
                  : cases.RECEIVED
                    ? 'Received'
                    : cases.DELIVERED
                      ? 'Delivered'
                      : 'Cancelled'}
            </span>
          </div>
        )}
      </div>
    );
  }

  const handleShipmentClick = async (record) => {
    setTrackingLoading(true);
    try {
      setShipmentDetailPopup(true);
      const response = await fetchTrackingDetails(record?.customId);
      const shipmentResponse = await fetchShipment(record?.customId);
      setShipmentData(shipmentResponse);
      setTrackingData(response);
      setTrackingLoading(false);
    } catch (error) {
      setTrackingLoading(false);
    }
  };

  const handleFavourite = (id, currentStatus, index) => {
    mutate(
      { shipmentId: id, isFavorite: !currentStatus },
      {
        onSuccess: () => {
          shipments[index].ShipmentFavorites = [
            {
              isFavorite: !currentStatus
            }
          ];
          setNodeShipmentData(shipments.sort(favouriteSort));
          if (!currentStatus) {
            toaster('success', `Your Shipment ${id} marked as favourite`);
          } else {
            toaster('success', `Your Shipment ${id} unmarked as favourite`);
          }
        }
      }
    );
  };

  const { refetch, isFetching } = useQuery(
    ['nodeTypeFilter', nodeType, statusFilter, selectedNode, searchKeyword],
    () => {
      let payload = {
        from: startDate,
        to: endDate,
        page,
        search: searchKeyword
      };
      if (filterType === ShipmentFilterType.WAREHOUSE) {
        payload.nodeType = NodeCategory[nodeType];
        payload.nodeId = selectedNode === '*' ? null : selectedNode;
      }
      if (filterType === ShipmentFilterType.STATUS) {
        payload.nodeType = NodeCategory[nodeType];
        payload.nodeId = currentNodeId;
        payload.status = statusFilter !== 'SHOW_ALL' ? statusFilter : null;
      }
      if (filterType === ShipmentFilterType.NODE_TYPE) {
        payload.status = status;
        payload.nodeType = NodeCategory[nodeType];
      }
      return fetchShipments(payload);
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (page === 1) {
          setNodeShipmentData(data.data);
          setShipmentsCount(data.count);
          setPageCount(data.pages);
        } else {
          setNodeShipmentData([...shipments, ...data.data]);
          setShipmentsCount(data.count);
          setPageCount(data.pages);
        }
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [nodeType, statusFilter, selectedNode, page, searchKeyword]);

  useEffect(() => {
    setNodeType(initialNodeType);
  }, [open]);

  useEffect(() => {
    if (page > 1) {
      const shipmentsDiv = document.getElementById('shipmentsList');
      if (shipmentsDiv) {
        shipmentsDiv.scrollTop = shipmentsDiv?.scrollHeight;
      }
    }
  }, [shipments]);

  useEffect(() => {
    setPage(1);
    setPageCount(1);
    setShipmentsCount(0);
  }, [nodeType, statusFilter, selectedNode]);

  function ListView({ data, loading }) {
    return (
      <div
        className={clsx('w-[445px] pt-4 px-4', {
          'h-full': loading || isFetchingInitial
        })}
      >
        <div className="w-100 mb-2">
          <Paragraph variant={'md'}>{`${shipmentsCount} ${dayShipments ? '' : 'Active'
            } Shipment${data?.length > 1 ? 's' : ''} ${filterType === ShipmentFilterType.NODE_TYPE ? title : ''
            }`}</Paragraph>
        </div>
        <div className="flex flex-row w-100 justify-between items-center mb-4">
          <div
            className="flex w-100 items-center gap-2 justify-between"
            id="shipmentsList"
          >
            <div className={'flex'}>
              {searchKeyword !== undefined && (
                <SearchBar
                  className={classes.searchBar}
                  iconClass="h-4 w-4"
                  setSearchValue={(e) => {
                    setPage(1);
                    setSearchKeyword(e);
                  }}
                  placeholder="Search"
                  searchKeyword={searchKeyword}
                />
              )}
            </div>
            {isFeatureEnabled(FLAGS.TMS) ||
              (isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) && (
                <div>{/*<p>dssf</p>*/}</div>
              ))}
            {filterType === ShipmentFilterType.NODE_TYPE && (
              <Dropdown
                variant="outlined"
                size="small"
                value={nodeType}
                items={getNodeCategories()}
                onChange={(selection) => setNodeType(selection)}
                showIcon={true}
                iconClasses="mr-3 w-[20px] flex justify-center"
                selectClasses="h-[32px]"
              />
            )}
            {filterType === ShipmentFilterType.STATUS && (
              <Dropdown
                variant="outlined"
                size="small"
                value={statusFilter}
                items={getStatusFilters()}
                onChange={(selection) => setStatusFilter(selection)}
                showIcon={true}
                iconClasses="mr-3 w-[20px] flex justify-center"
                selectClasses="h-[32px]"
              />
            )}
            {filterType === ShipmentFilterType.WAREHOUSE && (
              <Dropdown
                variant="outlined"
                size="small"
                value={selectedNode}
                items={nodes}
                onChange={(selection) => setSelectedNode(selection)}
                showIcon={true}
                iconClasses="mr-3 w-[20px] flex justify-center"
                selectClasses="h-[32px]"
              />
            )}
          </div>
        </div>
        {(loading || isFetchingInitial) && page === 1 ? (
          <div className="w-100 h-full flex items-center justify-center transition-all">
            <CircularProgress />
          </div>
        ) : (
          <>
            {data.map((record, index) => (
              <div
                key={index}
                onClick={() => {
                  handleShipmentClick(record);
                }}
              >
                {isFeatureEnabled(FLAGS.TMS) ||
                  isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
                  <TMSShipment
                    record={record}
                    index={index}
                    handleChat={() => {
                      setTabValue(shipmentsTabsMapping.COMMENTS);
                      handleShipmentClick(record);
                    }}
                    handleFavourite={handleFavourite}
                  />
                ) : (
                  <WOTMSShipment
                    record={record}
                    index={index}
                    handleChat={() => {
                      setTabValue(shipmentsTabsMapping.COMMENTS);
                      handleShipmentClick(record);
                    }}
                    handleFavourite={handleFavourite}
                  />
                )}
              </div>
            ))}

            <div className="w-full flex justify-center pt-4 pb-8">
              {page < pageCount && !loading && (
                <Button
                  size="small"
                  className="h-8"
                  variant="transparent"
                  label="See More"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                />
              )}
              {loading && <CircularProgress size="24px" />}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <Popup
        open={open}
        setOpen={setOpen}
        content={
          shipmentDetailPopup ? (
            <ShipmentDetails shipmentData={shipmentData} />
          ) : (
            <ListView data={shipments} loading={isFetching} />
          )
        }
        title={
          shipmentDetailPopup ? (
            trackingLoading ? (
              ''
            ) : (
              <Title shipmentData={shipmentData} />
            )
          ) : (
            title
          )
        }
        headerClass={
          shipmentDetailPopup
            ? clsx(
              'pt-[14px] px-4 pb-0 border-platinum border-t-0 border-l-0 border-r-0',
              background
            )
            : 'p-4 border-platinum border-b border-solid border-t-0 border-l-0 border-r-0'
        }
        noLineBreak={true}
        closeIconSize={24}
        titleClass="text-primaryBlue text-lg font-semibold"
        dialogContentClasses={clsx('overflow-x-hidden', {
          'dark-scrollbar': !shipmentDetailPopup
        })}
        paperProps={{
          style: {
            borderRadius: '8px',
            height: '590px',
            width: '445px'
          }
        }}
        noPadding={true}
      />
    </div>
  );
};

export default NodeShipments;
