/*eslint-disable*/
const ShipmentStatsOfDate = ({
  stats: {
    total,
    outbounds,
    inbounds,
    transfers,
    logistics,
    purchase,
    sales,
    salesReturns
  },
  onClick,
  selectedShipmentType
}) => {
  return (
    <>
      {total > 0 && (
        <div
          onClick={onClick}
          className="flex flex-col items-start px-2 py-2 cursor-pointer hover:bg-oasisPrimary200 transition-all"
        >
          <div className="text-[11px] font-medium">
            {total} Shipment{total > 1 ? 's' : ''}
          </div>
          {selectedShipmentType == 'All' ? (
            <>
              {outbounds > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 rounded">
                  {outbounds} Outbound
                </div>
              )}
              {inbounds > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {inbounds} Inbound
                </div>
              )}
              {logistics > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {logistics} Logistic
                </div>
              )}
              {purchase > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {purchase} Purchase
                </div>
              )}
              {sales > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {sales} Sales
                </div>
              )}
              {transfers > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {transfers} Transfer
                </div>
              )}
              {salesReturns > 0 && (
                <div className="text-[9px] font-medium text-dark600 border px-1 mt-0.5 rounded">
                  {salesReturns} Sales Return
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ShipmentStatsOfDate;
