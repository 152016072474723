import LinearProgressBar from '../../../core-components/atoms/LinerProgressBar';
import putwayProgress from '../../../assets/icons/putaway.svg';

export const OutboundShipmentProgress = ({ order }) => {
  if (order.status == 'PENDING') {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-sm">
          <LinearProgressBar value={0} />
        </p>
        <p className="text-xs mt-3.5">Create a shipment for this order.</p>
      </div>
    );
  } else if (order.status !== 'CANCELLED' && order.status !== 'COMPLETED') {
    const activeShipment = order?.Shipments?.filter(
      (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
    );
    if (activeShipment.length == 0) {
      return (
        <div className="flex gap-2 flex-col pt-[26px]">
          <p className="text-xs">Requires new Shipment</p>
        </div>
      );
    }
    if (activeShipment.length > 1) {
      return (
        <p className="text-sm pt-4">
          {activeShipment.length} Active shipments. Expand to see details
        </p>
      );
    } else {
      const shipment = activeShipment[0];
      if (order.status == 'PICKING_IN_PROGRESS') {
        return (
          <div className="flex gap-2 flex-col pt-[26px]">
            <p className="text-sm">
              <img src={putwayProgress} />
            </p>
            <p className="text-xs mt-3.5">Picking in Progress</p>
          </div>
        );
      }
      if (shipment) {
        if (shipment.status.key == 'CREATED') {
          if (shipment.ShipmentLegs[0]?.status == 'AWAITING_CARRIER') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={0} />
                </p>
                <p className="text-xs mt-3.5">Awaiting Carrier Details</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            !shipment.dockId
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={10} />
                </p>
                <p className="text-xs mt-3.5">Carrier Assigned</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED'
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={30} />
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          }
        } else if (shipment.status.key == 'IN_TRANSIT') {
          if (shipment.subStatus?.key == 'LOAD_DISPATCHED') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={60} />
                </p>
                <p className="text-xs mt-3.5">Out for Delivery</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <img src={putwayProgress} />
                </p>
                <p className="text-xs mt-3.5">Arrived at Destination</p>
              </div>
            );
          }
        }

        return null;
      }
    }
  } else {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-xs mt-3.5">Order Completed</p>
      </div>
    );
  }
  return null;
};

export const InBoundShipmentProgress = ({ order }) => {
  if (order.status == 'PENDING') {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-sm">
          <LinearProgressBar value={0} />
        </p>
        <p className="text-xs mt-3.5">Create a shipment for this order.</p>
      </div>
    );
  } else if (order.status !== 'CANCELLED' && order.status !== 'COMPLETED') {
    const activeShipment = order?.Shipments?.filter(
      (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
    );
    if (activeShipment.length == 0) {
      return (
        <div className="flex gap-2 flex-col pt-[26px]">
          <p className="text-xs">Requires new Shipment</p>
        </div>
      );
    }
    if (activeShipment.length > 1) {
      return (
        <p className="text-sm pt-4">
          {activeShipment.length} Active shipments. Expand to see details
        </p>
      );
    } else {
      const shipment = activeShipment[0];
      if (order.status == 'PICKING_IN_PROGRESS') {
        return (
          <div className="flex gap-2 flex-col pt-[26px]">
            <p className="text-sm">
              <img src={putwayProgress} />
            </p>
            <p className="text-xs mt-3.5">Picking in Progress</p>
          </div>
        );
      }
      if (shipment) {
        if (shipment.status.key == 'CREATED') {
          if (shipment.ShipmentLegs[0]?.status == 'AWAITING_CARRIER') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={0} />
                </p>
                <p className="text-xs mt-3.5">Awaiting Carrier Details</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            !shipment.dockId
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={10} />
                </p>
                <p className="text-xs mt-3.5">Carrier Assigned</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED'
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={30} />
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          }
        } else if (shipment.status.key == 'IN_TRANSIT') {
          if (!shipment.subStatus?.key) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={70} />
                </p>
                <p className="text-xs">Out for Delivery</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <img src={putwayProgress} />
                </p>
                <p className="text-xs mt-3.5">Arrived at Destination</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'DOCK_ASSIGNED') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <div>
                  <img src={putwayProgress} />
                </div>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          }
        }

        return null;
      }
    }
  } else {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-xs mt-3.5">Order Completed</p>
      </div>
    );
  }
  return null;
};

export const TransferShipmentProgress = ({ order }) => {
  if (order.status == 'PENDING') {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-sm">
          <LinearProgressBar value={0} />
        </p>
        <p className="text-xs mt-3.5">Create a shipment for this order.</p>
      </div>
    );
  } else if (order.status !== 'CANCELLED' && order.status !== 'COMPLETED') {
    const activeShipment = order?.Shipments?.filter(
      (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
    );
    if (activeShipment.length == 0) {
      return (
        <div className="flex gap-2 flex-col pt-[26px]">
          <p className="text-xs">Requires new Shipment</p>
        </div>
      );
    }
    if (activeShipment.length > 1) {
      return (
        <p className="text-sm pt-4">
          {activeShipment.length} Active shipments. Expand to see details
        </p>
      );
    } else {
      const shipment = activeShipment[0];
      if (order.status == 'PICKING_IN_PROGRESS') {
        return (
          <div className="flex gap-2 flex-col pt-[26px]">
            <p className="text-sm">
              <img src={putwayProgress} />
            </p>
            <p className="text-xs mt-3.5">Picking in Progress</p>
          </div>
        );
      }
      if (shipment) {
        if (shipment.status.key == 'CREATED') {
          if (shipment.ShipmentLegs[0]?.status == 'AWAITING_CARRIER') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={0} />
                </p>
                <p className="text-xs mt-3.5">Awaiting Carrier Details</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            !shipment.dockId
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={10} />
                </p>
                <p className="text-xs mt-3.5">Carrier Assigned</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'DOCK_ASSIGNED') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={30} />
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          }
        } else if (shipment.status.key == 'IN_TRANSIT') {
          if (shipment.subStatus?.key == 'LOAD_DISPATCHED') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={60} />
                </p>
                <p className="text-xs">Out for Delivery</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <img src={putwayProgress} />
                </p>
                <p className="text-xs mt-3.5">Arrived at Destination</p>
              </div>
            );
          } else if (shipment.subStatus?.key == 'DOCK_ASSIGNED') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <div>
                  <img src={putwayProgress} />
                </div>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.dropOffDock?.name}
                </p>
              </div>
            );
          }
        }

        return null;
      }
    }
  } else {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-xs mt-3.5">Order Completed</p>
      </div>
    );
  }
  return null;
};

export const LogisticShipmentProgress = ({ order }) => {
  if (order.status == 'PENDING') {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <p className="text-sm">
          <LinearProgressBar value={0} />
        </p>
        <p className="text-xs mt-3.5">Create a shipment for this order.</p>
      </div>
    );
  } else if (order.status !== 'CANCELLED' && order.status !== 'COMPLETED') {
    const activeShipment = order?.Shipments?.filter(
      (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
    );
    if (activeShipment.length == 0) {
      return (
        <div className="flex gap-2 flex-col pt-[26px]">
          <p className="text-xs">Requires new Shipment</p>
        </div>
      );
    }
    if (activeShipment.length > 1) {
      return (
        <p className="text-sm pt-4">
          {activeShipment.length} Active shipments. Expand to see details
        </p>
      );
    } else {
      const shipment = activeShipment[0];
      console.log(shipment.status);
      if (order.status == 'PICKING_IN_PROGRESS') {
        return (
          <div className="flex gap-2 flex-col pt-[26px]">
            <p className="text-sm">
              <img src={putwayProgress} />
            </p>
            <p className="text-xs mt-3.5">Picking in Progress</p>
          </div>
        );
      }
      if (shipment) {
        if (shipment.status.key == 'CREATED') {
          if (shipment.ShipmentLegs[0]?.status == 'AWAITING_CARRIER') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={0} />
                </p>
                <p className="text-xs mt-3.5">Awaiting Carrier Details</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            shipment.subStatus?.key != 'LOADING_IN_PROGRESS'
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={10} />
                </p>
                <p className="text-xs mt-3.5">Carrier Assigned</p>
              </div>
            );
          } else if (
            shipment.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
            shipment.subStatus?.key == 'LOADING_IN_PROGRESS'
          ) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={30} />
                </p>
                <p className="text-xs mt-3.5">Loading in progress</p>
              </div>
            );
          }
        } else if (shipment.status.key == 'IN_TRANSIT') {
          return (
            <div className="flex gap-2 flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={70} />
              </p>
              <p className="text-xs">Out for Delivery</p>
            </div>
          );
        } else if (shipment.status?.key == 'ARRIVED_AT_DESTINATION') {
          if (!shipment.subStatus) {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <p className="text-sm">
                  <img src={putwayProgress} />
                </p>
                <p className="text-xs mt-3.5">Arrived at Destination</p>
              </div>
            );
          } else if (shipment.subStatus.key == 'UNLOADING_COMPLETE') {
            return (
              <div className="flex gap-2 flex-col pt-[26px]">
                <LinearProgressBar value={90} />
                <p className="text-xs mt-3.5">Unloading Complete</p>
              </div>
            );
          }
        }
        return null;
      }
    }
  } else {
    return (
      <div className="flex gap-2 flex-col pt-[26px]">
        <LinearProgressBar value={100} />
        <p className="text-xs mt-3.5">Order Completed</p>
      </div>
    );
  }
  return null;
};

export const ShipmentProgress = ({ order }) => {
  if (order.moveType == 'LOGISTIC') return <p className="pt-[26px]">-</p>;
  else if (order.moveType != 'TRANSFER') {
    if (order.status == 'PENDING') {
      return (
        <div className="flex gap-2 flex-col pt-[26px]">
          <p className="text-sm">
            <LinearProgressBar value={0} />
          </p>
          <p className="text-xs mt-3.5">Create a shipment for this order.</p>
        </div>
      );
    } else if (
      order.status == 'IN_PROGRESS' ||
      order.status == 'PICKING_IN_PROGRESS'
    ) {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED'
      );
      if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        if (shipment) {
          if (
            shipment.status.key == 'CREATED' ||
            shipment.subStatus?.key == 'VEHICLE_ASSIGNED'
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={10} />
                  )}
                </p>
                <p className="text-xs mt-3.5">
                  {order.status == 'PICKING_IN_PROGRESS'
                    ? 'Shipment Created. Picking in progress'
                    : 'Shipment Created.'}{' '}
                </p>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED'
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={60} />
                  )}
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          }
        }
      }
    } else if (order.status == 'PARTIALLY_COMPLETE') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        return <p className="text-sm pt-4">Requires new shipment</p>;
      } else if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center gap-2 flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={10} />
              </p>
              <p className="text-xs mt-3.5">
                {length > 1 && length + suffix} Shipment Created.
              </p>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={60} />
              <p className="text-xs mt-3.5">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </p>
            </div>
          );
        }
      }
    } else if (order.status == 'PUTAWAY_IN_PROGRESS') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        let lastCompletedShipment = order?.Shipments?.filter(
          (s) => s.status.key == 'COMPLETED'
        );
        lastCompletedShipment.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        let length =
          lastCompletedShipment.length > 0
            ? order?.Shipments.map((s) => s.id).indexOf(
                lastCompletedShipment[0]?.id
              ) + 1
            : 0;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        return (
          <div className="flex justify-center flex-col pt-[26px]">
            <img src={putwayProgress} />
            <p className="text-xs mt-3.5">
              {length > 1 && length + suffix} Shipment Completed, Put Away in
              Progress
            </p>
          </div>
        );
      } else if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={10} />
              <p className="text-xs mt-4">
                {length > 1 && length + suffix} Shipment Created.
              </p>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={60} />
              <p className="text-xs mt-3.5">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </p>
            </div>
          );
        }
      }
    }
  } else {
    if (order.status == 'PENDING') {
      return (
        <div className="flex justify-center flex-col pt-[26px]">
          <LinearProgressBar value={0} />
          <p className="text-xs mt-3.5">Create a Shipment for this order.</p>
        </div>
      );
    } else if (
      order.status == 'IN_PROGRESS' ||
      order.status == 'PICKING_IN_PROGRESS'
    ) {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED'
      );

      if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        if (shipment) {
          if (shipment.status.key == 'CREATED') {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={10} />
                  )}
                </p>
                <p className="text-xs mt-3.5">
                  {order.status == 'PICKING_IN_PROGRESS'
                    ? 'Shipment Created. Picking in progress'
                    : 'Shipment Created.'}{' '}
                </p>
              </div>
            );
          } else if (
            shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
            !shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={40} />
                  )}
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </p>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
            !shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-4">
                <LinearProgressBar value={60} />
                <p className="text-xs mt-3.5">Shipment Loading Complete</p>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
            shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={80} />
                </p>
                <p className="text-xs mt-3.5">
                  Shipment is at {shipment?.dropOffDock?.name}
                </p>
              </div>
            );
          }
        }
      }
    } else if (order.status == 'PUTAWAY_IN_PROGRESS') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        let lastCompletedShipment = order?.Shipments?.filter(
          (s) => s.status.key == 'COMPLETED'
        );
        lastCompletedShipment.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        let length =
          lastCompletedShipment.length > 0
            ? order?.Shipments.map((s) => s.id).indexOf(
                lastCompletedShipment[0]?.id
              ) + 1
            : 0;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        return (
          <div className="flex justify-center flex-col pt-[26px]">
            <img src={putwayProgress} />
            <p className="text-xs mt-3.5">
              {length > 1 && length + suffix} Shipment Completed, Put Away in
              Progress
            </p>
          </div>
        );
      } else if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={10} />
              <p className="text-xs mt-4">
                {length > 1
                  ? `${length + suffix} Shipment Created.`
                  : 'Shipment Created'}
              </p>
            </div>
          );
        } else if (
          shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
          !shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm">
                {order.status == 'PICKING_IN_PROGRESS' ? (
                  <img src={putwayProgress} />
                ) : (
                  <LinearProgressBar value={40} />
                )}
              </p>
              <p className="text-xs mt-3.5">
                Shipment is at {shipment?.WarehouseDock?.name}
              </p>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
          !shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-4">
              <LinearProgressBar value={60} />
              <p className="text-xs mt-3.5">Shipment Loading Complete</p>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
          shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={80} />
              </p>
              <p className="text-xs mt-3.5">
                Shipment is at {shipment?.dropOffDock?.name}
              </p>
            </div>
          );
        }
      }
    } else if (order.status == 'PARTIALLY_COMPLETE') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        return <p className="text-sm pt-4">Requires new shipment</p>;
      } else if (activeShipment.length > 1) {
        return (
          <p className="text-sm pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </p>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm mt-3.5">
                <LinearProgressBar value={10} />
              </p>
              <p className="text-xs mt-2">
                {length > 1 && length + suffix} Shipment Created.
              </p>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center gap-2 flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={60} />
              </p>
              <p className="text-xs mt-3.5">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </p>
            </div>
          );
        }
      }
    }
  }

  return <p className="pt-[26px]">-</p>;
};
