/*eslint-disable*/
import { FormControl } from '@material-ui/core';
import { React, useContext, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CountryList from 'react-select-country-list';

import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { isArabCountry, SharedContext } from '../../../../utils/common';
import TextField from '../../../../core-components/atoms/TextField';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendarIconSmall.svg';
import Autocomplete from '../../../../core-components/atoms/Autocomplete';
import {
  SupportingDocumentUpload,
  formatFileName
} from '../../../revampedOrder/AddOrder';
import CancelIcon from '../../../../assets/icons/cancelIcon.svg';
import UploadDocIcon from '../../../../assets/icons/uploadDocument.svg';
import { FileUploadButton } from '../../components/FileUploadButton';
import { UploadIconType } from '../../components/constants';
import PhoneInput from 'react-phone-input-2';
import { COUNTRIES } from '../../../../constants';

const DriverTypeForm = ({ formik, edit }) => {
  const { setAPILoader, organization } = useContext(SharedContext);

  const [carriers, setCarriers] = useState([]);
  const [carrierSearchKeyword, setCarrierSearchKeyword] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);

  const countryOptions = CountryList().getData();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getCarriers(carrierSearchKeyword);
  }, [carrierSearchKeyword]);

  useEffect(() => {
    const initialCountry =
      countryOptions.find(
        (option) => option.label === formik.values.nationality
      ) || null;
    setSelectedCountry(initialCountry);
    setInputValue(initialCountry ? initialCountry.label : '');
  }, [formik.values.nationality]);

  const getCarriers = async (searchKeyword) => {
    try {
      setAPILoader(true);
      const carriers = await API.get('companies', {
        params: { search: searchKeyword, type: 'VENDOR' }
      });
      setCarriers(carriers.data?.rows || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm h-7',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  return (
    <div className="px-8 pt-4 pb-5">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Driver Name <span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="name"
                  placeholder="Enter Driver Name"
                  value={formik.values?.name || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">Driver Code</p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="code"
                  placeholder="Enter Driver Code"
                  value={formik.values?.code}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) => formik.setFieldValue('code', e.target.value)}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Phone number {<span className="text-error">*</span>}
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <PhoneInput
                  country={
                    organization?.country
                      ? COUNTRIES.find(
                          (c) => c.name == organization?.country
                        )?.code?.toLowerCase() || 'pk'
                      : 'pk'
                  }
                  placeholder="Enter Phone Number"
                  value={formik.values?.phone || ''}
                  onChange={(value) => formik.setFieldValue('phone', value)}
                  containerStyle={{ marginTop: '8px' }}
                  inputStyle={{ width: '100%', height: '48px' }}
                  variant="outlined"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Driving License number{' '}
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="drivingLicenseNumber"
                  placeholder="Enter Driving License Number"
                  value={formik.values?.drivingLicenseNumber || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('drivingLicenseNumber', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Driving License Expiry Date
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    components={{
                      OpenPickerIcon: CalendarIcon
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        size="small"
                        sxProps={{
                          '& input::placeholder': {
                            fontSize: '14px'
                          },
                          '& input': {
                            fontSize: '14px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            height: '20px'
                          },
                          '& label': {
                            fontSize: '14px'
                          },
                          '& .MuiIconButton-root': {
                            padding: 0
                          },
                          '& .MuiIconButton-edgeEnd': {
                            marginRight: '-6px'
                          }
                        }}
                        placeholder="Enter Driving License Expiry Date"
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                    value={formik?.values?.drivingLicenseExpiryDate || null}
                    label="Driving License Expiry Date"
                    onChange={(value) => {
                      formik.setFieldValue('drivingLicenseExpiryDate', value);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Carrier <span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[...carriers]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addSubCategory' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddSubCategoryViewOpen(true);
                        }}
                      >
                        + {'Add New Sub Category'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  value={formik.values?.carrier}
                  inputValue={carrierSearchKeyword}
                  getOptionLabel={(carrier) => carrier?.name || ''}
                  placeholder="Select Carrier"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('carrierId', newValue.id);
                    formik.setFieldValue('carrier', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setCarrierSearchKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Driver Sponsor
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="sponsor"
                  placeholder="Enter Driver Sponsor"
                  value={formik.values?.sponsor || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('sponsor', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              {isArabCountry(organization) ? (
                <>
                  Iqama Number <span className="text-error">*</span>
                </>
              ) : (
                'TIN'
              )}
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full ">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="nic"
                  placeholder={
                    isArabCountry(organization)
                      ? 'Enter Iqama Number'
                      : 'Enter Tax Identification Number (TIN)'
                  }
                  value={formik.values?.cnicNumber || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('cnicNumber', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">Nationality</p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={countryOptions}
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Nationality"
                      placeholder="Select Nationality"
                      variant="outlined"
                    />
                  )}
                  value={selectedCountry || null}
                  inputValue={inputValue || ''}
                  onInputChange={(event, newInputValue) =>
                    setInputValue(newInputValue || '')
                  }
                  onChange={(event, newValue) => {
                    setSelectedCountry(newValue || null);
                    formik.setFieldValue(
                      'nationality',
                      newValue ? newValue.label : ''
                    );
                    setInputValue(newValue ? newValue.label : '');
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value?.label
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between items-center border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div>
            <p className="font-semibold text-sm text-primaryBlue">
              Upload Proof of Document
            </p>
            <p className="text-xs">File must be pdf, jpeg & png</p>
          </div>
          <div className="w-[479px] border border-dashed p-4 border-[#C4C6CB] rounded">
            <div className="flex justify-between items-center mb-2">
              {!formik.values.DrivingLicense ? (
                <p className="flex gap-1 text-sm">
                  Upload a picture or document
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {formik.values.DrivingLicense && (
                    <div
                      className="rounded-full w-[123px] py-[1px] justify-between text-xs px-2 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                      onClick={async (e) => {
                        e.stopPropagation();
                        let fileUrl;
                        if (!formik.values.DrivingLicense?.location)
                          fileUrl = URL.createObjectURL(
                            formik.values.DrivingLicense?.location
                          );
                        else {
                          fileUrl = formik.values.DrivingLicense?.location;
                        }
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.target = '_blank';
                        a.click();
                      }}
                    >
                      <p className="text-primary">
                        {formatFileName(formik.values.DrivingLicense)}
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('DrivingLicense', null);
                        }}
                      >
                        <img src={CancelIcon} className="w-2 h-2" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FileUploadButton
                formik={formik}
                propertyName="DrivingLicense"
                iconType={UploadIconType.UPLOAD}
                label="Driving License"
              />
            </div>
            <div className="flex justify-between items-center">
              {!formik.values?.cnic ? (
                <p className="flex gap-1 text-sm">
                  Upload a picture or document
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {formik.values?.cnic && (
                    <div
                      className="rounded-full w-[123px] py-[1px] justify-between text-xs px-2 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                      onClick={async (e) => {
                        e.stopPropagation();
                        let fileUrl;
                        if (!formik.values.cnic?.location)
                          fileUrl = URL.createObjectURL(
                            formik.values.cnic?.location
                          );
                        else {
                          fileUrl = formik.values.cnic?.location;
                        }
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.target = '_blank';
                        a.click();
                      }}
                    >
                      <p className="text-primary">
                        {formatFileName(formik.values.cnic)}
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('cnic', null);
                        }}
                      >
                        <img src={CancelIcon} className="w-2 h-2" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FileUploadButton
                formik={formik}
                propertyName="cnic"
                iconType={UploadIconType.UPLOAD}
                label="CNIC Image"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DriverTypeForm;
